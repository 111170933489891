export const MILLISECONDS_DAY = 24 * 60 * 60 * 1000;

export const daysInMonth = (date: Date): number => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export const getWeekNumber = (input: Date): [number, number] => {
  const date = new Date(Date.UTC(input.getFullYear(), input.getMonth(), input.getDate()));
  // Set to nearest Thursday,  make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

  const firstDay = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNumber = Math.ceil((((date.getTime() - firstDay.getTime()) / MILLISECONDS_DAY) + 1) / 7);

  return [weekNumber, date.getUTCFullYear()];
};

export const getDateSinceDays = (input: Date, days = 0): Date => new Date(input.getTime() - days * MILLISECONDS_DAY);
