import { Notification } from '../../types/notifications';
import InAppMultipleNotifications from './InAppMultipleNotifications';
import InAppNotificationModal from './InAppNotificationModal';

import './InAppNotifications.css';

type InAppNotificationsProps = {
  notifications: Notification[];
  mode: 'modal' | 'multiple';
  allowActions: boolean;
}

export default function InAppNotifications({ notifications, mode, allowActions }: InAppNotificationsProps): JSX.Element {
  return (
    mode === 'modal'
      ? <InAppNotificationModal notification={notifications[0]} allowActions={allowActions} />
      : <InAppMultipleNotifications notifications={notifications} allowActions={allowActions} />
  );
}
