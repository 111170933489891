import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSkeletonText,
} from '@ionic/react';
import { t } from 'i18next';
import { MilkQualityCounterData } from '../../../../types/milkQuality';
import { formatNumber } from '../../../../utils/formatters';

type QualityReportDataCardProps = {
  loaded: boolean,
  kpi: MilkQualityCounterData;
};

function QualityReportDataCard({
  loaded, kpi,
}: QualityReportDataCardProps): JSX.Element {
  return (
    <IonCard className="qualityReportDataCard">
      <IonCardHeader>
        <IonCardTitle>{t(kpi.label)}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {loaded && (
          <span className="text-xl">
            {`${formatNumber(kpi.max, kpi.truncate, kpi.digits)}`}
            <span className="text-s">{` ${kpi.unit}`}</span>
          </span>
        )}
        {!loaded && <IonSkeletonText animated style={{ width: '40%' }} />}
      </IonCardContent>
    </IonCard>
  );
}

export default QualityReportDataCard;
