import { IonIcon } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateSelector from '../DateSelector/DateSelector';
import './DateRangeSelector.css';

interface DateRangeSelectorOptions {
  name?: string
  minDate?: Date
  maxDate: Date
  initialStartDate: Date
  initialEndDate: Date
  onDatesSelected: (start: Date, end: Date) => void
}

function DateRangeSelector({
  name, initialStartDate, initialEndDate, minDate, maxDate, onDatesSelected,
}: DateRangeSelectorOptions): JSX.Element {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(initialEndDate);

  const onDatesChanged = (start: Date, end: Date): void => {
    setStartDate(start);
    setEndDate(end);
    onDatesSelected(start, end);
  };

  return (
    <div className="dateRangeSelector">
      <DateSelector
        type="badge"
        name={`${name}-start`}
        value={startDate}
        minDate={minDate}
        maxDate={endDate}
        onDateSelected={(newSelectedDate: Date) => onDatesChanged(newSelectedDate, endDate)}
        title={t('dateRangeSelector.SelectStart')}
      />
      <div className="dateRangeSelector__separator">
        <IonIcon icon={arrowForwardOutline} size="small" />
      </div>
      <DateSelector
        type="badge"
        name={`${name}-end`}
        value={endDate}
        minDate={startDate}
        maxDate={maxDate}
        onDateSelected={(newSelectedDate: Date) => onDatesChanged(startDate, newSelectedDate)}
        title={t('dateRangeSelector.SelectEnd')}
      />
    </div>
  );
}

export default DateRangeSelector;
