import {
  IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow,
} from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from '../../components/Button/Button';
import WizardImage, { WizardImages } from '../../components/WizardImage/WizardImage';
import './WizardWithSlidesPage.css';

export interface WizardSlide {
  image: WizardImages
  title: string
  body: string
}

export interface WizardWithSlidesProps {
  slides: WizardSlide[]
  onFinish: () => void
}

function WizardWithSlides({ slides, onFinish }: WizardWithSlidesProps): JSX.Element {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [buttonLabel, setButtonLabel] = useState(t('wizardWithSlides.Next'));

  const next = ():void => {
    if (currentSlide < (slides.length - 1)) {
      setCurrentSlide(currentSlide + 1);
    }
    if (currentSlide === slides.length - 2) {
      setButtonLabel(t('wizardWithSlides.Finish'));
    }
    if (currentSlide === slides.length - 1) {
      onFinish();
    }
  };

  return (
    <IonPage className="wizardPage">
      <IonContent>
        { slides.map((slide, index) => (
          <IonGrid
            className={cn(
              'wizardWithSlides-slide',
              {
                'wizardWithSlides-slide__fadeIn': currentSlide === index,
                'wizardWithSlides-slide__fadeOut': currentSlide === index - 1,
              },
            )}
            key={`slide-${slide.title}`}
          >
            <IonRow className="ion-justify-content-center">
              <IonCol size="12" sizeMd="4" sizeLg="6" className="wizardPage__img">
                <WizardImage img={slide.image} />
              </IonCol>
              <IonCol size="12" sizeMd="8" sizeLg="6" className="wizardPage__content ion-justify-content-center">
                <div>
                  <h1>{slide.title}</h1>
                  <p>{slide.body}</p>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        ))}
      </IonContent>
      <IonFooter mode="md">
        <div className="wizardWithSlides-footer">
          <div className="wizardWithSlides-footer__dots">
            {slides.map((slide, index) => <span className={cn({ current: index === currentSlide })} key={`slides_footer_${slide.title}`} />)}
          </div>
          <Button ghost onClick={next}>{buttonLabel}</Button>
        </div>
      </IonFooter>
    </IonPage>
  );
}

export default WizardWithSlides;
