import { forwardRef, Ref } from 'react';
import InputForReactHookForm, { BaseInputProps } from './InputForReactHookForm';

type InputTextProps = BaseInputProps

function InputText(props: InputTextProps, ref: Ref<HTMLIonInputElement>):JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InputForReactHookForm {...props} type="text" ref={ref} />;
}

export default forwardRef(InputText);
