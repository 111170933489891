import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { IonSkeletonText, useIonViewWillEnter } from '@ionic/react';
import Box from '../../components/Box/Box';
import Button from '../../components/Button/Button';
import { useFarm } from '../../components/FarmContext/FarmContext';
import * as routes from '../../routing/routes';
import { formatDate } from '../../utils/formatters';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import Page from '../../layout/Page/Page';
import Badge from '../../components/Badge/Badge';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import './BotanicalFeed.css';
import { REMOVE_BLEND_ID } from '../../types/MedicalPrescription';

type BotanicalFeedBlend = {
  id: number;
  blendId: number;
  blendName?: string;
  reasons?: { type: string, value: string }[];
  proposedDate?: string;
  activeDate?: string;
  inactiveDate?: string;
}

const formatInputDate = (input: string | undefined, defaultValue = '-'): string => {
  if (!input) {
    return defaultValue;
  }

  return formatDate(input, '2-digit', '2-digit', 'numeric');
};

function ViewBotanicalFeed(): JSX.Element {
  const { t } = useTranslation();
  const { selectedFarmId } = useFarm();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [botanicalFeedData, setBotanicalFeedData] = useState<BotanicalFeedBlend>();
  const { activeMedicinalBlendId } = useParams<{ activeMedicinalBlendId: string }>();
  const pamApi = usePamApi('BotanicalFeed');
  const friendlyErrors = useFriendlyErrors();
  const [ts, setTs] = useState(0);
  const [reasonTypes, setReasonTypes] = useState<string[]>([]);
  const history = useHistory();

  useIonViewWillEnter(() => setTs(+new Date()));

  useEffect(
    () => {
      (async (farmId: number): Promise<void> => {
        if (!activeMedicinalBlendId) {
          setNotFound(true);
          return;
        }

        if (!farmId || loading) {
          return;
        }

        setBotanicalFeedData(undefined);
        setReasonTypes([]);
        setLoading(true);
        setNotFound(false);

        try {
          const response = await pamApi.get(`/farms/${farmId}/botanical-feed/${activeMedicinalBlendId}`);

          if (response.ok) {
            const data: BotanicalFeedBlend = await response.json();

            if (data.blendId === REMOVE_BLEND_ID) {
              data.blendName = t('botanicalFeed.RemoveActualBlend');
            }

            setBotanicalFeedData(data);
            const reasonKeys = data?.reasons?.map((r) => r.type) || [];

            setReasonTypes(reasonKeys);
          } else if (response.status === 404) {
            setNotFound(true);
          } else {
            friendlyErrors.handleGenericFetchError();
          }
        } catch (error) {
          friendlyErrors.handleGenericFetchError();
        }

        setLoading(false);
      })(selectedFarmId);
    },
    [botanicalFeedData, selectedFarmId, ts],
  );

  return (
    <Page title={t('botanicalFeed.Title')} disableFarmSelector showBackButton>
      <div className="botanicalFeed_title">
        <div className="botanicalFeed_title-text">
          <h2>{t('botanicalFeed.Subtitle')}</h2>
        </div>
      </div>

      {!loading && notFound && <ErrorMessage>{t('botanicalFeed.RecommendationNotFound')}</ErrorMessage>}
      {!notFound && (
        <div className="botanicalFeed__container">
          <div className="botanicalFeed__section botanicalFeed__recommendations botanicalFeed__view">
            <Box title={t('botanicalFeed.Proposed')}>
              {!loading && <span>{botanicalFeedData?.blendName || '-'}</span>}
              {loading && <IonSkeletonText animated style={{ width: '100%' }} />}
            </Box>
          </div>

          <div className="botanicalFeed__section botanicalFeed__row">
            <Box className="botanicalFeed__dates">
              {!loading && (
                <table>
                  <thead>
                    <tr>
                      <th>{t('botanicalFeed.dates.Proposed')}</th>
                      <th>{t('botanicalFeed.dates.Active')}</th>
                      <th>{t('botanicalFeed.dates.Inactive')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formatInputDate(botanicalFeedData?.proposedDate)}</td>
                      <td>{formatInputDate(botanicalFeedData?.activeDate)}</td>
                      <td>{formatInputDate(botanicalFeedData?.inactiveDate)}</td>
                    </tr>
                  </tbody>
                </table>
              )}
              {loading && <IonSkeletonText animated style={{ width: '100%' }} />}
            </Box>
          </div>

          <div className="botanicalFeed__section botanicalFeed__row">
            <Box title={t('botanicalFeed.Reasons')} className="botanicalFeed__reasons">
              {!loading && (
                <table>
                  <thead>
                    <tr>
                      <th>{t('botanicalFeed.tableHeaders.Reason')}</th>
                      <th>{t('botanicalFeed.tableHeaders.Proposed')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reasonTypes.map((reason) => {
                      const actual = botanicalFeedData?.reasons?.find((r) => r.type === reason)?.value;

                      return (
                        <tr key={`reasons-${reason}`}>
                          <th>{reason}</th>
                          <td>
                            {(actual && <Badge>{t(`botanicalFeed.values.${actual}`)}</Badge>) || '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {loading && <IonSkeletonText animated style={{ width: '100%' }} />}
            </Box>
          </div>
        </div>
      )}

      <div className="botanicalFeed__actions">
        <Button block ghost onClick={() => history.push(routes.BotanicalFeed)}>
          {t('botanicalFeed.ViewBotanicalFeed')}
        </Button>
      </div>
    </Page>
  );
}

export default ViewBotanicalFeed;
