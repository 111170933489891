import {
  IonSelect, IonSelectOption,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useFarm } from '../FarmContext/FarmContext';
import './FarmSelector.css';

type FarmSelectorProps = {
  disabled?: boolean;
}

function FarmSelector({ disabled = false }: FarmSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const {
    farms, selectedFarmId, selectFarm,
  } = useFarm();

  const onChange = (value: number): void => {
    if (!value) {
      return;
    }

    if (value !== selectedFarmId) {
      selectFarm(value);
    }
  };

  const actionSheetOptions = {
    header: t('farmSelector.Header'),
    subHeader: t('farmSelector.SubHeader'),
  };

  return (
    <IonSelect
      placeholder={t('farmSelector.Placeholder')}
      value={selectedFarmId}
      onIonChange={(event) => onChange(+event.detail.value)}
      interface="action-sheet"
      interfaceOptions={actionSheetOptions}
      className="farmSelector"
      okText={t('actions.Accept')}
      cancelText={t('actions.Cancel')}
      disabled={disabled}
    >
      {farms && farms.map((farm) => (
        <IonSelectOption
          key={farm.id}
          value={farm.id}
        >
          {farm.name}
        </IonSelectOption>
      ))}
    </IonSelect>
  );
}

export default FarmSelector;
