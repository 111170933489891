/* eslint-disable no-console */
import { MedicalPrescriptionStatus } from '../types/MedicalPrescription';
import { FCMNotification, Notification, NotificationStatus } from '../types/notifications';
import usePamApi from './usePamApi';

export interface PamNotificationsApi {
  getNotificationsByFarm: (farmId: number) => Promise<Response>;
  updateRelatedPrescriptionStatus: (notification: Notification, status: MedicalPrescriptionStatus) => Promise<boolean>
  updateNotificationStatus: (notification: Notification, status: NotificationStatus) => Promise<boolean>
  managePrescriptionStatus: (notification: Notification, status: MedicalPrescriptionStatus) => Promise<boolean>
  parseFCMNotification: (message: FCMNotification) => Notification
}

function usePamNotificationsApi(): PamNotificationsApi {
  const pamApi = usePamApi('usePamNotificationsApi');
  const getNotificationsByFarm = async (farmId: number): Promise<Response> => pamApi.get(`/farms/${farmId}/notifications`);

  const updateRelatedPrescriptionStatus = async (notification: Notification, status: MedicalPrescriptionStatus): Promise<boolean> => {
    let updated = false;

    if (!notification.metadata.prescriptionId) {
      return updated;
    }

    try {
      const response = await pamApi.patch(
        `/farms/${notification.farmId}/medical-prescription/${notification.metadata.prescriptionId}`,
        { status },
      );

      updated = response.ok;
    } catch (error) {
      console.log('[updateRelatedPrescriptionStatus]', error);
    }

    return updated;
  };

  const updateNotificationStatus = async (notification: Notification, status: NotificationStatus): Promise<boolean> => {
    let updated = false;

    try {
      const response = await pamApi.patch(
        `/farms/${notification.farmId}/notifications/${notification.id}`,
        { status },
      );

      updated = response.ok;
    } catch (error) {
      console.log('[updateNotificationStatus]', error);
    }

    return updated;
  };

  const managePrescriptionStatus = async (notification: Notification, status: MedicalPrescriptionStatus): Promise<boolean> => {
    const prescriptionUpdated = await updateRelatedPrescriptionStatus(notification, status);

    if (prescriptionUpdated) {
      const notificationUpdated = await updateNotificationStatus(
        notification,
        status === MedicalPrescriptionStatus.Accepted
          ? NotificationStatus.Accepted
          : NotificationStatus.Rejected,
      );

      return notificationUpdated;
    }

    return false;
  };

  const parseFCMNotification = (message: FCMNotification): Notification => {
    const notification: Notification = {
      id: message.id,
      farmId: message.farmId,
      managementAreaId: message.managementAreaId,
      type: message.type,
      boxType: message.type,
      tag: message.tag,
      created: message.created,
      targetRole: message.targetRole,
      status: message.status,
      title: message.title,
      body: message.body,
      metadata: {
        contact: message.contact,
        prescriptionId: message.prescriptionId ? +message.prescriptionId : message.prescriptionId,
        farmName: message.farmName,
        activeMedicinalBlendId: message.activeMedicinalBlendId ? +message.activeMedicinalBlendId : message.activeMedicinalBlendId,
      },
    };

    return notification;
  };

  return {
    getNotificationsByFarm,
    updateRelatedPrescriptionStatus,
    updateNotificationStatus,
    managePrescriptionStatus,
    parseFCMNotification,
  };
}

export default usePamNotificationsApi;
