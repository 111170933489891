import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonFabButton, IonIcon, IonItem, IonModal,
} from '@ionic/react';
import { add, pencilOutline, trashOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useFarm } from '../../components/FarmContext/FarmContext';
import { useIdentity } from '../../components/Identity/IdentityContext';
import Page from '../../layout/Page/Page';
import { Farm } from '../../types/farms';
import RegisterFarmComponent from '../RegisterFarm/RegisterFarmComponent';
import * as routes from '../../routing/routes';
import './FarmConfiguration.css';
import DeleteFarmButton from '../../components/DeleteFarmButton/DeleteFarmButton';

function FarmConfiguration(): JSX.Element {
  const { t } = useTranslation();
  const { user } = useIdentity();
  const [userFarms, setUserFarms] = useState<Farm[]>([]);
  const [modalOpened, setModalOpened] = useState(false);
  const { farms } = useFarm();
  const history = useHistory();

  useEffect(() => {
    setUserFarms(farms);
  }, [user, farms]);

  return (
    <Page title={t('farmConfiguration.Title')} showBackButton hideFarmSelector>
      <div className="farm-configuration__title">
        <h2>{t('farmConfiguration.SubTitle')}</h2>
        <IonFabButton onClick={() => { setModalOpened(true); }}>
          <IonIcon icon={add} />
        </IonFabButton>
      </div>

      <div>
        {userFarms?.map((farm) => (
          <IonCard key={farm.id} className="farm-configuration__card">
            <IonCardHeader>
              <div className="farm-configuration__card--header">
                <IonCardTitle>{farm.name}</IonCardTitle>
                <IonButton
                  fill="clear"
                  color="primary"
                  onClick={() => history.push(routes.buildUpdateFarmUrl(farm))}
                >
                  <IonIcon icon={pencilOutline} />
                </IonButton>
                <DeleteFarmButton farm={farm}>
                  <IonIcon icon={trashOutline} />
                </DeleteFarmButton>
              </div>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <div>{t('farmConfiguration.farms.Laboratory')}</div>
                <div slot="end">{farm.labName}</div>
              </IonItem>
              {!!farm.farmerCode && (
                <IonItem>
                  <div>{t('farmConfiguration.farms.FarmerCode')}</div>
                  <div slot="end">{farm.farmerCode || '-'}</div>
                </IonItem>
              )}
            </IonCardContent>
          </IonCard>
        ))}
      </div>

      <IonModal isOpen={modalOpened} initialBreakpoint={0.7} breakpoints={[0, 0.7, 0.75, 0.85, 1]} onDidDismiss={() => setModalOpened(false)}>
        <IonContent className="ion-padding">
          <RegisterFarmComponent
            onCreate={
              () => {
                setModalOpened(false);
                history.push(routes.AnimalGroupsWizard);
              }
            }
          />
        </IonContent>
      </IonModal>

    </Page>
  );
}

export default FarmConfiguration;
