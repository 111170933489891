import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow, IonSkeletonText,
} from '@ionic/react';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useFarm } from '../../../../components/FarmContext/FarmContext';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import usePamApi from '../../../../hooks/usePamApi';
import {
  MilkQuality, MilkQualityCounters, MilkQualityKPI,
} from '../../../../types/milkQuality';
import { formatNumber, formatYearMonthDay } from '../../../../utils/formatters';
import { milkQualityCounters, parseQualityData } from '../../../../utils/milkQuality';

interface MonthlyFarmReportResponse {
  quality: MilkQuality;
}

type MonthlyFarmReportMilkQualityCountersProps = {
  selectedDate: Date;
};

const qualityKPIs: MilkQualityKPI[] = ['eq', 'urea', 'fat', 'protein'];

function MonthlyFarmReportMilkQualityCounters({ selectedDate }: MonthlyFarmReportMilkQualityCountersProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const pamApi = usePamApi('MonthlyFarmReportMilkQualityCounters');
  const { selectedFarmId } = useFarm();
  const [milkQuality, setMilkQuality] = useState<MilkQualityCounters>(milkQualityCounters);
  const [, setIsErrorState] = useState(false);
  const { handleGenericFetchError } = useFriendlyErrors();

  useEffect(
    () => {
      (async (farmId: number): Promise<void> => {
        setLoaded(false);
        if (!farmId || !selectedDate) {
          return;
        }

        let qualityCounters = milkQualityCounters;

        try {
          const startDate = formatYearMonthDay(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1));
          const endDate = formatYearMonthDay(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0));
          const response = await pamApi.get(
            `/farms/${farmId}/milk-measurement?startDate=${startDate}&endDate=${endDate}`,
          );

          if (response.ok) {
            setIsErrorState(false);
            const { quality }: MonthlyFarmReportResponse = await response.json();

            if (quality) {
              qualityCounters = parseQualityData(quality);
            }

            setLoaded(true);
          } else {
            setIsErrorState(true);
            handleGenericFetchError();
          }
        } catch (error) {
          setIsErrorState(true);
          handleGenericFetchError();
        }

        setMilkQuality(qualityCounters);
      })(selectedFarmId);
    },
    [selectedFarmId, selectedDate],
  );

  return (
    <IonGrid>
      <IonRow>
        {qualityKPIs.map((dataKey) => (
          <IonCol size="6" key={dataKey}>
            <IonCard key={`monthlyFarmReportCard_${dataKey}`}>
              <IonCardHeader>
                <IonCardTitle>{loaded && t(milkQuality[dataKey].label)}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {loaded && (
                  <span className="data-s">
                    {`${formatNumber(milkQuality[dataKey].max, milkQuality[dataKey].truncate, milkQuality[dataKey].digits)}`}
                    <span className="text-m">{` ${milkQuality[dataKey].unit}`}</span>
                  </span>
                )}
                {!loaded && <IonSkeletonText animated style={{ width: '40%' }} />}
              </IonCardContent>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
}

export default MonthlyFarmReportMilkQualityCounters;
