import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { fileTrayOutline } from 'ionicons/icons';
import './NoRecommendations.css';

function NoRecommendations(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="noRecommendations__container">
      <IonIcon icon={fileTrayOutline} />
      <p>{t('botanicalFeed.RecommendationsNotFound')}</p>
    </div>
  );
}

export default NoRecommendations;
