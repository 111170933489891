import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import useLogger from './useLogger';

export interface PamApi {
  get: (path: string) => Promise<Response>;
  post: (path: string, payload: { [key: string]: unknown } | null) => Promise<Response>;
  delete: (path: string) => Promise<Response>;
  patch: (path: string, payload: { [key: string]: unknown }) => Promise<Response>;
  put: (path: string, payload: { [key: string]: unknown }) => Promise<Response>;
}

type AllowedMethod = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

const apiAbsolutePath = (path: string): string => `${process.env.REACT_APP_API_URI}/${path?.replace(/^\//, '')}`;

function usePamApi(origin: string): PamApi {
  const { debug, error } = useLogger();
  debug(`usePamApi ${origin}`);
  const auth = useAuth();
  const getDefaultHeaders = useCallback((method: AllowedMethod): RequestInit => ({
    method,
    headers: {
      'Content-Type': 'application/json',
      'x-csrf': '1',
      Authorization: `Bearer ${auth.user?.access_token}`,
    },
  }), [auth]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logAndThrow = (path: string, errorMsg: any): Promise<Response> => {
    error('PamApi error', path, errorMsg);
    throw error;
  };

  return {
    get: (path) => fetch(apiAbsolutePath(path), getDefaultHeaders('GET')).catch((e) => logAndThrow(path, e)),
    post: (path, payload) => fetch(apiAbsolutePath(path), { ...getDefaultHeaders('POST'), body: payload ? JSON.stringify(payload) : undefined }).catch((e) => logAndThrow(path, e)),
    patch: (path, payload) => fetch(apiAbsolutePath(path), { ...getDefaultHeaders('PATCH'), body: JSON.stringify(payload) }).catch((e) => logAndThrow(path, e)),
    put: (path, payload) => fetch(apiAbsolutePath(path), { ...getDefaultHeaders('PUT'), body: JSON.stringify(payload) }).catch((e) => logAndThrow(path, e)),
    delete: (path) => fetch(apiAbsolutePath(path), getDefaultHeaders('DELETE')).catch((e) => logAndThrow(path, e)),
  };
}

export default usePamApi;
