import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import {
  logOutOutline,
  notificationsOutline,
  closeOutline,
  leafOutline,
  folderOpenOutline,
  cogOutline,
  gridOutline,
} from 'ionicons/icons';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import {
  useCallback, useEffect, useRef,
} from 'react';
import * as routes from '../../routing/routes';
import { useIdentity } from '../../components/Identity/IdentityContext';

interface AppPage {
  url: string;
  icon: string;
  title: string;
  disabled?: boolean;
}

export const appPages: AppPage[] = [
  {
    title: 'mainMenu.Dashboard',
    url: routes.App,
    icon: gridOutline,
  },
  {
    title: 'mainMenu.BotanicalFeed',
    url: routes.BotanicalFeed,
    icon: leafOutline,
  },
  {
    title: 'mainMenu.AnimalGroups',
    url: routes.AnimalGroups,
    icon: folderOpenOutline,
  },
  {
    title: 'mainMenu.Notifications',
    url: routes.Notifications,
    icon: notificationsOutline,
  },
  {
    title: 'mainMenu.Settings',
    url: routes.Settings,
    icon: cogOutline,
  },
];

function Menu(): JSX.Element {
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useAuth();
  const mainMenu = useRef<HTMLIonMenuElement>(null);
  const settingsMenu = useRef<HTMLIonMenuElement>(null);
  const { user } = useIdentity();

  const handleLogout = useCallback(async () => {
    await auth.signoutPopup({ popupWindowTarget: '_top' });
  }, [auth]);

  useEffect(() => {
    settingsMenu.current?.close();
  }, [settingsMenu]);

  return (
    <IonMenu contentId="main" type="overlay" side="start" ref={mainMenu} menuId="mainMenu" id="mainMenu">
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonButton onClick={() => mainMenu.current?.close()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonItem className="menu__name">{user?.fullName}</IonItem>
          <IonItem className="menu__email">{user?.email}</IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonList mode="md">
          {appPages.map((appPage, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IonMenuToggle key={index} autoHide={false}>
              <IonItemDivider>
                <IonItem
                  detail={false}
                  className={
                    location.pathname === appPage.url ? 'menu__selected' : ''
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  disabled={appPage.disabled}

                >
                  <IonIcon slot="start" icon={appPage.icon} />
                  <IonLabel className="ion-text-wrap">{t(appPage.title)}</IonLabel>
                </IonItem>
              </IonItemDivider>
            </IonMenuToggle>
          ))}
        </IonList>
        <IonMenuToggle autoHide={false}>
          <IonItem routerLink={routes.Root} detail={false} lines="none" onClick={handleLogout} className="menu__footer">
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>{t('mainMenu.Logout')}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      </IonContent>
    </IonMenu>

  );
}

export default Menu;
