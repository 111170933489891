import { IonCard } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWidgetProps } from '..';
import MonthSelector from '../../../../components/MonthSelector/MonthSelector';
import MonthlyFarmReportMilkProductionChart from './MonthlyFarmReportMilkProductionChart';
import MonthlyFarmReportMilkQualityCounters from './MonthlyFarmReportMilkQualityCounters';
import './ProductionReportWidget.css';

function ProductionReportWidget({ timestamp }: DashboardWidgetProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  return (
    <div className="dashboard-widget productionReportWidget" key={`productionReportWidget_${timestamp}`}>
      <h2>{t('productionReportWidget.Title')}</h2>
      <MonthSelector onDateSelected={(newSelectedDate: Date) => setSelectedDate(newSelectedDate)} />
      <IonCard>
        <MonthlyFarmReportMilkProductionChart selectedDate={selectedDate} />
        <MonthlyFarmReportMilkQualityCounters selectedDate={selectedDate} />
      </IonCard>
    </div>
  );
}

export default ProductionReportWidget;
