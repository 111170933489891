import { Notification } from '../../types/notifications';
import { useFarm } from '../FarmContext/FarmContext';
import NotificationBase from '../NotificationBase/NotificationBase';
import { InAppNotificationActionType, useInAppNotification } from '../InAppNotificationContext/InAppNotificationContext';

type InAppMultipleNotificationsProps = {
  notifications: Notification[];
  allowActions: boolean;
};

const MAX_NOTIFICATIONS_IN_SCREEN = 2;

function InAppMultipleNotifications({
  notifications,
  allowActions,
}: InAppMultipleNotificationsProps): JSX.Element {
  const { inAppNotificationDispatch } = useInAppNotification();
  const { selectFarm } = useFarm();

  const notificationsToShow = notifications.slice(0, MAX_NOTIFICATIONS_IN_SCREEN);

  return (
    <div className="inapp-notifications">
      {notificationsToShow.map((notification) => (
        <div
          className={`inapp-notifications-item ${notification.type.toLowerCase()}`}
          key={notification.id}
        >
          <NotificationBase
            key={notification.id}
            inAppMode
            allowActions={allowActions}
            notification={notification}
            dismiss={() => inAppNotificationDispatch({ type: InAppNotificationActionType.REMOVE, payload: notification.id })}
            switchTarget={() => selectFarm(notification.farmId)}
          />
        </div>
      ))}
    </div>
  );
}

export default InAppMultipleNotifications;
