import { ReactNode } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useHistory } from 'react-router';
import * as routes from '../../routing/routes';
import { BACKGROUND_NOTIFICATIONS_STORAGE_KEY, StoredNotificationData } from '../../types/notifications';
import { storage } from '../../utils/storage';
import PamAuthEvents from './PamAuthEvents';

type PamAuthProviderProps = {
  children: ReactNode;
};

function PamAuthProvider({ children }: PamAuthProviderProps): JSX.Element {
  const history = useHistory();

  const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY || 'AUTHORITY NOT CONFIGURED',
    client_id: 'pam-token',
    redirect_uri: `${window.location.origin}/login/callback`,
    scope: 'openid profile pam-app role',
  };

  const onSigninCallback = (): void => {
    const data = storage.get<StoredNotificationData>(BACKGROUND_NOTIFICATIONS_STORAGE_KEY);

    if (data && data.boxType) {
      history.replace(`${routes.Notifications}/${data.boxType}`, { type: data.boxType, updated: +new Date() });
    } else {
      history.replace(routes.App);
    }
  };

  return (
    <AuthProvider
      authority={oidcConfig.authority}
      scope={oidcConfig.scope}
      client_id={oidcConfig.client_id}
      redirect_uri={oidcConfig.redirect_uri}
      post_logout_redirect_uri={window.location.origin}
      monitorSession
      revokeTokensOnSignout
      onSigninCallback={onSigninCallback}
      redirectTarget="self"
    >
      <PamAuthEvents>
        {children}
      </PamAuthEvents>
    </AuthProvider>
  );
}

export default PamAuthProvider;
