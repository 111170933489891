import { t } from 'i18next';
import {
  IonCard, IonCardContent, IonCol, IonRow,
} from '@ionic/react';
import * as yup from 'yup';
import { useState } from 'react';
import { FarmTarget } from './FarmTargetCard';
import Button from '../Button/Button';
import FieldSelector from '../Field/FieldSelector';
import Field from '../Field/Field';
import { NumberStringDictionary } from '../../types/common';

import './FarmTargetCardEdit.css';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import Form from '../Form/Form';

const MAX_RANGE_NUMBER = 1_000;
const MAX_TARGET_VALUE_NUMBER = 1_000_000;

type FarmTargetCardEditProps = {
  target: FarmTarget,
  farmId: number,
  onAccepted: () => void,
  onCancelled: () => void,
  measurementTypes: NumberStringDictionary
}

function FarmTargetCardEdit({
  target, farmId, onAccepted, onCancelled, measurementTypes,
}: FarmTargetCardEditProps): JSX.Element {
  const schema = yup.object({
    measurementTypeId: yup.number()
      .moreThan(0, t('forms.errors.Required'))
      .integer(t('forms.errors.Required'))
      .required(t('forms.errors.Required'))
      .default(target.measurementTypeId),
    value: yup.number()
      .positive(t('forms.errors.GreaterThanZero'))
      .min(0.01, t('forms.errors.GreaterThanZero'))
      .lessThan(MAX_TARGET_VALUE_NUMBER, `${t('forms.errors.LessThan')}${MAX_TARGET_VALUE_NUMBER}`)
      .typeError(t('forms.errors.RequireNumber'))
      .default(target.value),
    range: yup.number()
      .positive(t('forms.errors.GreaterThanZero'))
      .integer(t('forms.errors.GreaterThanZero'))
      .lessThan(MAX_RANGE_NUMBER, `${t('forms.errors.LessThan')}${MAX_RANGE_NUMBER}`)
      .typeError(t('forms.errors.RequireNumber'))
      .default(target.range),
  });
  const pamApi = usePamApi('FarmTargetCardEdit');
  const { handleGenericFetchError, handleUnknownError } = useFriendlyErrors();
  const [targetId, setTargetId] = useState(target.measurementTypeId);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accept = (formData: Record<string, any>): void => {
    pamApi.post(`/farms/${farmId}/targets`, formData)
      .then((response) => {
        if (!response.ok) {
          handleGenericFetchError();
        }
        onAccepted();
      })
      .catch(handleUnknownError);
  };

  const options = Object.entries(measurementTypes).map(([id, name]) => ({ id, name }));

  return (
    <IonCard className="farmTargetCardEdit">
      <IonCardContent>
        <Form onSubmit={accept} schema={schema}>
          <FieldSelector
            disabled={!!target.measurementTypeId}
            name="measurementTypeId"
            options={options}
            label={t('farmTargets.inputs.ParamName')}
            placeholder={t('farmTargets.fields.paramNamePlaceholder')}
            defaultOption={targetId?.toString()}
            onChangeCustom={(value: string) => setTargetId(+value)}
          />
          <Field
            type="number"
            step="any"
            name="value"
            label={t('farmTargets.inputs.Value')}
            placeholder={t('farmTargets.inputs.Value')}
            value={target.value}
          />
          <Field
            type="number"
            step="any"
            name="range"
            label={t('farmTargets.inputs.Range')}
            placeholder={t('farmTargets.inputs.Range')}
            value={target.range}
          />
          <IonRow>
            <IonCol size="6">
              <Button ghost block onClick={onCancelled}>
                {t('actions.Cancel')}
              </Button>
            </IonCol>
            <IonCol size="6">
              <Button block submit>
                {t('actions.Accept')}
              </Button>
            </IonCol>
          </IonRow>
        </Form>
      </IonCardContent>
    </IonCard>
  );
}

export default FarmTargetCardEdit;
