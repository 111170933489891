import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import Button from '../../components/Button/Button';
import Field from '../../components/Field/Field';
import Form from '../../components/Form/Form';
import { formatDate } from '../../utils/formatters';
import { MedicalPrescriptionRouteOptions } from './ViewMedicalPrescriptionPage';
import AnimalGroup from './types/AnimalGroup';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import FeedTotal from './FeedTotal';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import { FarmPrescriptions } from '../BotanicalFeed/BotanicalFeed';
import MedicalPrescription from './types/MedicalPrescription';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import FieldSelector from '../../components/Field/FieldSelector';
import { REMOVE_BLEND_ID } from '../../types/MedicalPrescription';

const MAX_DOSE_NUMBER = 1_000_000;
const MAX_DURATION_NUMBER = 1_000_000;

interface CreateMedicalPrescriptionProps {
  animalGroup: AnimalGroup,
  selectedFarmId: number,
  blend: MedicalPrescriptionRouteOptions,
  onPrescriptionCreated: () => void
}

type MedicinalBlend = {
  id: number
  name: string
}

const defaultBlend: MedicalPrescriptionRouteOptions = {
  blendId: 0,
  blendName: '',
  ts: +new Date(),
};

function CreateMedicalPrescription({
  animalGroup, selectedFarmId, blend = defaultBlend, onPrescriptionCreated,
}: CreateMedicalPrescriptionProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [prescriptionNumber, setPrescriptionNumber] = useState('');
  const [dose, setDose] = useState(0);
  const [duration, setDuration] = useState(0);
  const [total, setTotal] = useState(0);
  const pamApi = usePamApi('CreateMedicalPrescription');
  const friendlyErrors = useFriendlyErrors();
  const [prescriptionCreated, setPrescriptionCreated] = useState(false);
  const [prescriptionCancelled, setPrescriptionCancelled] = useState(false);
  const [medicinalBlends, setMedicinalBlends] = useState<MedicinalBlend[]>([]);
  const [selectedBlend, setSelectedBlend] = useState(blend.blendId);
  const [loaded, setLoaded] = useState(false);

  const onPrescriptionCancelled = (): void => {
    setPrescriptionCancelled(false);
    history.goBack();
  };

  const getPendingPrescription = async (farmId:number, batchId: number):Promise<MedicalPrescription|null> => {
    try {
      const response = await pamApi.get(`/farms/${farmId}/medical-prescriptions?batchId=${batchId}`);
      if (response.ok) {
        const currentPrescriptions: FarmPrescriptions = await response.json();
        return currentPrescriptions?.proposed || null;
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  useEffect(() => {
    const getPrescriptionNumber = async (farmId: number, groupId: number): Promise<void> => {
      if (!farmId || !groupId) {
        return;
      }

      const pendingPrescription = await getPendingPrescription(farmId, groupId);
      if (pendingPrescription) {
        return;
      }

      try {
        const response = await pamApi.get(`/farms/${farmId}/medical-prescription-number`);

        if (response.ok) {
          const newPrescriptionNumber: number = await response.json();
          setPrescriptionNumber(newPrescriptionNumber.toString().padStart(6, '0'));
        } else {
          friendlyErrors.handleGetPrescriptionError();
        }
      } catch (error) {
        friendlyErrors.handleGetPrescriptionError();
      } finally {
        setLoaded(true);
      }
    };

    const getMedicinalBlends = async (): Promise<void> => {
      try {
        const response = await pamApi.get('/medicinal-blends');
        const blends = await response.json();
        setMedicinalBlends([{ id: REMOVE_BLEND_ID, name: t('botanicalFeed.RemoveActualBlend') }, ...blends]);
      } catch {
        friendlyErrors.handleGenericFetchError();
      }
    };

    setLoaded(false);
    getMedicinalBlends();
    getPrescriptionNumber(selectedFarmId, animalGroup.id);
  }, [selectedFarmId, animalGroup.id]);

  useEffect(() => {
    const calculateTotal = (): number => animalGroup.count * dose * duration;

    const newTotal = calculateTotal();
    setTotal(newTotal);
  }, [animalGroup.count, dose, duration]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendMedicalPrescription = async (formData: Record<string, any>): Promise<void> => {
    try {
      const response = await pamApi.post(
        `/farms/${selectedFarmId}/medical-prescription`,
        {
          ...formData,
          batch: animalGroup.id,
          total,
          prescriptionId: prescriptionNumber,
        },
      );

      if (!response.ok) {
        friendlyErrors.handlePostPrescriptionError();
      } else {
        setDose(0);
        setDuration(0);
        setPrescriptionNumber('');
        setPrescriptionCreated(true);
      }
    } catch (error) {
      friendlyErrors.handlePostPrescriptionError();
    }
  };

  const checkBlendId = (blendId: string): boolean => blendId !== REMOVE_BLEND_ID.toString();

  const schema = yup.object({
    blendId: yup.string()
      .required(t('forms.errors.Required')),
    dose: yup.number().when(['blendId'], {
      is: checkBlendId,
      then: yup.number()
        .required(t('forms.errors.Required'))
        .positive(t('forms.errors.GreaterThanZero'))
        .lessThan(MAX_DOSE_NUMBER, `${t('forms.errors.LessThan')}${MAX_DOSE_NUMBER}`)
        .typeError(t('forms.errors.Required')),
    }),
    duration: yup.number().when(['blendId'], {
      is: checkBlendId,
      then: yup.number()
        .required(t('forms.errors.Required'))
        .integer(t('forms.errors.GreaterThanZero'))
        .positive(t('forms.errors.GreaterThanZero'))
        .lessThan(MAX_DURATION_NUMBER, `${t('forms.errors.LessThan')}${MAX_DURATION_NUMBER}`)
        .typeError(t('forms.errors.Required')),
    }),
  });

  const defaultValues = {
    blendId: blend?.blendId || '',
  };

  return (
    <div className="medicalPrescription__content">
      {(loaded && !prescriptionNumber && !prescriptionCreated) && (
        <ErrorMessage>{t('medicalPrescription.errors.NoPrescriptionNumber')}</ErrorMessage>
      )}
      { blend && medicinalBlends && prescriptionNumber && (
        <Form
          onSubmit={sendMedicalPrescription}
          schema={schema}
          defaultValues={defaultValues}
        >
          <Field
            disabled
            name="prescription-number"
            className="medicalPrescription__input medicalPrescription__input--readonly"
            label={t('medicalPrescription.PrescriptionNumber')}
            value={prescriptionNumber}
          />
          <Field
            disabled
            name="creation-date"
            className="medicalPrescription__input medicalPrescription__input--readonly"
            label={t('medicalPrescription.CreationDate')}
            value={formatDate(new Date(), '2-digit', '2-digit', 'numeric')}
          />
          <FieldSelector
            name="blendId"
            options={medicinalBlends}
            label={t('medicalPrescription.field.Feed')}
            placeholder={t('medicalPrescription.field.Feed')}
            defaultOption={blend.blendId}
            onChangeCustom={(v: string) => setSelectedBlend(+v || 0)}
          />
          <Field
            name="dose"
            type="decimal"
            className="medicalPrescription__input"
            label={`${t('medicalPrescription.field.Dose')} (${t('medicalPrescription.field.DoseUnit')})`}
            onChangeCustom={(v: string) => { setDose(+v || 0); }}
            disabled={selectedBlend < 0}
          />
          <Field
            name="duration"
            type="number"
            className="medicalPrescription__input"
            label={`${t('medicalPrescription.field.Duration')} (${t('medicalPrescription.field.DurationUnit')})`}
            onChangeCustom={(v: string) => { setDuration(+v || 0); }}
            disabled={selectedBlend < 0}
          />
          <FeedTotal amount={total} />
          <Button
            id="accept-button"
            className="medicalPrescription__next"
            submit
          >
            {t('medicalPrescription.actions.SendPrescription')}
          </Button>
          <Button
            id="reject-button"
            className="medicalPrescription__next"
            ghost
            onClick={() => setPrescriptionCancelled(true)}
          >
            {t('actions.Cancel')}
          </Button>
        </Form>
      )}
      <FeedbackModal
        isOpen={prescriptionCreated}
        feedbackType="positive"
        title={t('medicalPrescription.actions.created.Title')}
        text={t('medicalPrescription.actions.created.Text')}
        onDismiss={onPrescriptionCreated}
      />
      <FeedbackModal
        isOpen={prescriptionCancelled}
        feedbackType="negative"
        title={t('medicalPrescription.actions.cancelled.Title')}
        text={t('medicalPrescription.actions.cancelled.Text')}
        onDismiss={onPrescriptionCancelled}
      />
    </div>
  );
}

export default CreateMedicalPrescription;
