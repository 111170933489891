import {
  IonItem, IonLabel, IonSelect, IonSelectOption, IonIcon,
} from '@ionic/react';
import { t } from 'i18next';
import { clipboardSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { ToastType } from '../../components/Toaster/ToasterContext';
import usePamApi from '../../hooks/usePamApi';
import AnimalGroup from './types/AnimalGroup';

export interface BatchSelectorProps {
  onBatchSelected: (batch: AnimalGroup | null) => void,
  onError: (type: ToastType, content: string) => void,
  selectedFarmId: number,
  className?: string,
  noIcon?: boolean,
}

function BatchSelector({
  onBatchSelected, onError, selectedFarmId, className, noIcon,
}: BatchSelectorProps): JSX.Element {
  const [batches, setBatches] = useState<AnimalGroup[]>([]);
  const [selectedBatch, setSelectedBatch] = useState<AnimalGroup>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const pamApi = usePamApi('BatchSelectorProps');

  useEffect(() => {
    const getAnimalGroup = async (farmId: number): Promise<void> => {
      if (!farmId) {
        return;
      }

      try {
        const response = await pamApi.get(`/farms/${farmId}/animal-group`);

        if (response.ok) {
          const data = await response.json();
          const animalGroups: AnimalGroup[] = data.animalGroups ?? [];
          setBatches(animalGroups);
          setSelectedBatch(animalGroups[0] || undefined);
          setIsDisabled(!animalGroups || animalGroups.length <= 1);
        } else if (response.status === 400 || response.status === 404) {
          setBatches([]);
          setSelectedBatch(undefined);
          setIsDisabled(true);
        } else {
          let errorMessage = t('medicalPrescription.errors.GetAnimalGroups');
          if (response.statusText) errorMessage = `${errorMessage} : ${response.statusText}`;

          onError('error', errorMessage);
        }
      } catch (error) {
        let errorMessage = t('medicalPrescription.errors.GetAnimalGroups');
        if (error) errorMessage = `${errorMessage} : ${error}`;

        onError('error', errorMessage);
      }
    };

    getAnimalGroup(selectedFarmId);
  }, [selectedFarmId]);

  useEffect(() => {
    onBatchSelected(selectedBatch || null);
  }, [selectedBatch]);

  return (
    <IonItem className={`input__item ${className}`}>
      <IonLabel position="floating">{t('medicalPrescription.field.Batch')}</IonLabel>
      <IonSelect
        interfaceOptions={{ cssClass: 'farm-selector' }}
        placeholder={batches.length ? t('medicalPrescription.field.DefaultBatch') : t('medicalPrescription.errors.NoAnimalGroupAvailable')}
        disabled={isDisabled}
        value={selectedBatch?.id}
        onIonChange={(event) => {
          if (event.detail.value === selectedBatch?.id) {
            return;
          }

          const batchId = event.detail.value;
          const selectedOption = batches.find((batch) => batch.id === batchId);

          setSelectedBatch(selectedOption);
        }}
      >
        {batches.map((batch: AnimalGroup) => (
          <IonSelectOption
            key={`batch-${selectedFarmId}-${batch.id}`}
            value={batch.id}
          >
            {batch.groupName}
          </IonSelectOption>
        ))}
      </IonSelect>
      {(!noIcon) && <IonIcon slot="start" icon={clipboardSharp} />}
    </IonItem>
  );
}

export default BatchSelector;
