import {
  IonButton,
  IonFooter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Page from '../../layout/Page/Page';
import AssignManagementAreas from './components/AssignManagementAreas';
import * as routes from '../../routing/routes';
import { useFarm } from '../../components/FarmContext/FarmContext';

type SelectManagementAreaProps = {
  registerMode?: boolean;
}

function SelectManagementArea({ registerMode }: SelectManagementAreaProps): JSX.Element {
  const { t } = useTranslation();
  const { farms } = useFarm();

  return (
    <>
      <Page
        title={t('selectManagementArea.Title')}
        showBackButton={!registerMode}
        hideNotifications={registerMode}
        hideFarmSelector
        hideMenu={registerMode}
        withFooter={registerMode}
      >
        <AssignManagementAreas />
      </Page>
      {registerMode && (
        <IonFooter>
          <IonButton
            expand="block"
            fill="solid"
            className="button--main-cta"
            routerLink={routes.App}
            routerOptions={{ unmount: true }}
            disabled={!farms || !farms?.length}
          >
            {t('selectManagementArea.Continue')}
          </IonButton>
        </IonFooter>
      )}
    </>
  );
}

export default SelectManagementArea;
