import {
  IonItem, IonLabel, IonSelect, IonSelectOption, IonText,
} from '@ionic/react';
import {
  forwardRef, ReactElement, Ref,
} from 'react';
import { BaseInputProps } from './InputForReactHookForm';

export type InputSelectOptionProps = {
  label: string
  value: string|number
}
export function InputSelectOption({ label, value }: InputSelectOptionProps): JSX.Element {
  return <IonSelectOption value={value}>{label}</IonSelectOption>;
}

type InputSelectProps = BaseInputProps & {
  children: ReactElement<InputSelectOptionProps>[]
}
function InputSelect({
  label, error, children, ...props
}: InputSelectProps, ref: Ref<HTMLIonSelectElement>): JSX.Element {
  return (
    <IonItem className="pam-input fieldSelector">
      {label && <IonLabel color="primary" position="stacked">{label}</IonLabel>}
      <IonSelect
        interface="action-sheet"
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        ref={ref}
        onIonChange={(ev) => {
          props.onChange(ev);
        }}
      >
        {children}
      </IonSelect>

      {error && (
        <IonText color="danger" className="text-s">{error.message}</IonText>
      )}
    </IonItem>
  );
}

export default forwardRef(InputSelect);
