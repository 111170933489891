import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { warningOutline } from 'ionicons/icons';
import { DashboardWidgetProps } from '..';
import LineChart from '../../../../components/Charts/Line/LineChart';
import DataTable from '../../../../components/DataTable/DataTable';
import { useFarm } from '../../../../components/FarmContext/FarmContext';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import MonthSelector from '../../../../components/MonthSelector/MonthSelector';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import usePamApi from '../../../../hooks/usePamApi';
import { daysInMonth } from '../../../../utils/dates';
import { formatDate, formatNumber, formatYearMonthDay } from '../../../../utils/formatters';

const productionsColor = '#19468A';
const earningsColor = '#FA7C1B';
const gridColor = '#CED9D4';
const productionsUnit = 'L';
const earningsUnit = '€';

interface EarningsHistoryData {
  earnings: number;
  amount: number;
}

function EarningsHistoryWidget({ timestamp }: DashboardWidgetProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedFarmId } = useFarm();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [earnings, setEarnings] = useState<number[]>([]);
  const [productions, setProductions] = useState<number[]>([]);
  const pamApi = usePamApi('EarningsHistory');
  const friendlyErrors = useFriendlyErrors();
  const [labels, setLabels] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const clearGraph = (): void => {
    const days = daysInMonth(selectedDate);
    setLabels(Array(days)
      .fill(0)
      .map((_x, y) => formatDate(
        new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, -y),
        '2-digit',
        'short',
        undefined,
      ))
      .reverse());
    setEarnings(Array(days).fill(null));
    setProductions(Array(days).fill(null));
  };

  useEffect(
    () => {
      (async (farmId: number): Promise<void> => {
        if (loading || farmId < 1 || !selectedDate) {
          return;
        }
        clearGraph();
        setLoading(true);
        try {
          const startDate = formatYearMonthDay(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1));
          const endDate = formatYearMonthDay(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0));
          const response = await pamApi.get(
            `/farms/${farmId}/earnings-history?startDate=${startDate}&endDate=${endDate}`,
          );
          const responseEarnings: EarningsHistoryData[] = await response.json();

          if (responseEarnings.length) {
            setEarnings(responseEarnings.map((e) => e.earnings));
            setProductions(responseEarnings.map((e) => e.amount));
          }
        } catch (error) {
          friendlyErrors.handleGenericFetchError();
        }
        setLoading(false);
      })(selectedFarmId);
    },
    [selectedFarmId, selectedDate, t],
  );

  const earningsLabel = t('earningsHistoryWidget.labels.Earnings');
  const productionsLabel = t('earningsHistoryWidget.labels.Amount');
  const canDrawChart = productions?.length && productions.some((production) => production !== null);

  return (
    <div className="earningsHistoryWidget dashboard-widget" key={`earnings_history_${timestamp}`}>
      <h2>{t('earningsHistoryWidget.Title')}</h2>
      <MonthSelector onDateSelected={(newSelectedDate: Date) => setSelectedDate(newSelectedDate)} />
      {!loading && canDrawChart && (
        <>
          <LineChart
            labels={labels}
            dataset={[
              {
                yAxisID: 'productions',
                label: productionsLabel,
                data: productions,
                color: productionsColor,
              },
              {
                yAxisID: 'earnings',
                label: earningsLabel,
                data: earnings,
                color: earningsColor,
              },
            ]}
            options={{
              scales: {
                y: { display: false },
                productions: {
                  position: 'left',
                  display: true,
                  beginAtZero: true,
                  grid: {
                    drawOnChartArea: false,
                    drawTicks: false,
                  },
                  ticks: {
                    color: productionsColor,
                    callback: (value) => (`${value} ${productionsUnit}`),
                  },
                },
                earnings: {
                  position: 'right',
                  display: true,
                  beginAtZero: true,
                  grid: {
                    color: gridColor,
                  },
                  ticks: {
                    color: earningsColor,
                    callback: (value) => (`${value} ${earningsUnit}`),
                  },
                  suggestedMax: Math.max(...productions),
                },
              },
            }}
          />

          <DataTable
            headers={[
              t('earningsHistoryWidget.tableLabels.Date'),
              t('earningsHistoryWidget.tableLabels.Earnings'),
              t('earningsHistoryWidget.tableLabels.Amount'),
            ]}
            data={
              labels.map((label, idx) => ([
                label,
                earnings[idx] !== null ? `${formatNumber(earnings[idx], false, 2)} ${earningsUnit}` : '-',
                productions[idx] !== null ? `${formatNumber(productions[idx], false, 2)} ${productionsUnit}` : '-',
              ]))
            }
          />
        </>
      )}

      {!loading && !canDrawChart && (
        <InfoBox color="warning" icon={warningOutline}>{t('earningsHistoryWidget.NoData')}</InfoBox>
      )}
    </div>
  );
}

export default EarningsHistoryWidget;
