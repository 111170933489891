import {
  IonButton,
  IonIcon,
} from '@ionic/react';
import { notifications, notificationsOutline } from 'ionicons/icons';
import { useInAppNotification } from '../InAppNotificationContext/InAppNotificationContext';
import * as routes from '../../routing/routes';
import './NotificationsCounterButton.css';

function NotificationsCounterButton(): JSX.Element {
  const { inAppNotifications } = useInAppNotification();
  return (
    <IonButton routerLink={routes.Notifications} routerDirection="forward" routerOptions={{ unmount: true }} className="notifications-counter-button">
      <IonIcon slot="icon-only" icon={inAppNotifications.length ? notifications : notificationsOutline} />
      {!!inAppNotifications.length && <span>{inAppNotifications.length}</span>}
    </IonButton>
  );
}

export default NotificationsCounterButton;
