import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useFarm } from '../../components/FarmContext/FarmContext';
import { InputText } from '../../components/Input';
import { useToaster } from '../../components/Toaster/ToasterContext';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import usePamApi from '../../hooks/usePamApi';
import { Farm } from '../../types/farms';
import { MAX_MILK_PRICE_NUMBER } from '../RegisterFarm/RegisterFarmComponent';
import { FarmConfiguration } from '../../routing/routes';
import Button from '../../components/Button/Button';
import InputDecimalNumber from '../../components/Input/InputDecimalNumber';

const TOAST_DURATION = 1000;

export interface UpdateFarmInfoFormProps {
  farm: Farm
}

function UpdateFarmInfoForm({ farm }: UpdateFarmInfoFormProps): JSX.Element {
  const { t } = useTranslation();
  const pamApi = usePamApi('UpdateFarmLab');
  const { reload: reloadFarmContext } = useFarm();
  const [isBusy, setIsBusy] = useState(false);
  const friendlyErrors = useFriendlyErrors();
  const { openToaster } = useToaster();
  const history = useHistory();

  const schema = yup.object({
    name: yup.string()
      .required(t('forms.errors.Required')),
    milkPrice: yup.number()
      .positive(t('forms.errors.GreaterThanZero'))
      .min(0.001, t('forms.errors.GreaterThanZero'))
      .lessThan(MAX_MILK_PRICE_NUMBER, `${t('forms.errors.LessThan')}${MAX_MILK_PRICE_NUMBER}`)
      .typeError(t('forms.errors.RequireNumber')),

  });

  const {
    register, formState: { errors, isDirty, isValid }, handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: farm.name, milkPrice: '',
    },
  });

  const onUpdateFarm = handleSubmit((values) => {
    const payload = {
      farmInfo: {
        name: values.name,
        milkPrice: values.milkPrice,
      },
    };

    setIsBusy(true);
    pamApi.put(`/farms/${farm.id}`, payload)
      .then((response) => {
        if (response.ok) {
          openToaster('success', t('forms.messages.UpdatedOk'), TOAST_DURATION);
          reloadFarmContext();
          history.replace(FarmConfiguration);
        } else {
          friendlyErrors.handleUpdateFailedError();
        }
      })
      .catch(() => {
        friendlyErrors.handleUpdateFailedError();
      })
      .finally(() => setIsBusy(false));
  });

  return (
    <div className="ion-padding-top">
      <h2>{t('register.farm.SubtitleGeneric')}</h2>
      <form onSubmit={onUpdateFarm}>
        <InputText
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('name')}
          label={t('register.farm.field.Name')}
          placeholder={t('register.farm.field.Name')}
          error={errors.name}
        />
        <InputDecimalNumber
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('milkPrice')}
          label={t('register.farm.field.MilkPrice')}
          placeholder={t('register.farm.field.MilkPrice')}
          error={errors.milkPrice}
          step="any"
        />
        <Button
          higher
          block
          submit
          disabled={!isDirty || !isValid || isBusy}
          className="mt--2"
        >
          {t('register.farm.actions.Update')}
        </Button>
      </form>
    </div>
  );
}

export default UpdateFarmInfoForm;
