import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import React, { useEffect } from 'react';
import Field from '../Field/Field';
import FieldSelector from '../Field/FieldSelector';

type FormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: OptionalObjectSchema<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: Record<string, any>) => void,
  children: (JSX.Element | false)[] | JSX.Element | false,
  className?: string,
  onFormStatusChanged?: (isValid: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues?: { [x: string]: any }
};

function Form({
  schema, onSubmit, children, className, onFormStatusChanged, defaultValues,
}: FormProps): JSX.Element {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues,
  });
  const { isValid } = useFormState({ control });

  const renderElement = (element: JSX.Element | false): JSX.Element | false => (element !== false && ((element.type === Field) || (element.type === FieldSelector))
    ? React.cloneElement(element, { control, key: element.props.name })
    : element);

  useEffect(() => {
    if (onFormStatusChanged !== undefined) {
      onFormStatusChanged(isValid);
    }
  }, [isValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className ?? ''} noValidate>
      {Array.isArray(children)
        ? children.map((e) => renderElement(e))
        : renderElement(children)}
    </form>
  );
}

export default Form;
