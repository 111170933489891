import {
  IonButton, IonCol, IonGrid, IonIcon, IonRow,
} from '@ionic/react';
import { t } from 'i18next';
import { documentTextOutline } from 'ionicons/icons';
import usePamNotificationsApi from '../../hooks/usePamNotificationsApi';
import { Notification } from '../../types/notifications';

import * as routes from '../../routing/routes';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import { MedicalPrescriptionStatus } from '../../types/MedicalPrescription';

type NotificationPrescriptionActionsProps = {
  linkOnly?: boolean;
  notification: Notification;
  refresh?: () => void;
  dismiss?: () => void;
  switchTarget?: () => void;
}

function NotificationPrescriptionActions({
  linkOnly,
  notification,
  refresh,
  dismiss,
  switchTarget,
}: NotificationPrescriptionActionsProps): JSX.Element {
  const { managePrescriptionStatus } = usePamNotificationsApi();
  const { handleUpdateMedicalPrescriptionError } = useFriendlyErrors();

  const updateStatus = async (status: MedicalPrescriptionStatus): Promise<void> => {
    const updated = await managePrescriptionStatus(notification, status);

    if (updated && refresh) {
      refresh();
      if (dismiss) {
        dismiss();
      }
    } else if (!updated) {
      handleUpdateMedicalPrescriptionError();
    }
  };

  const onClick = (): void => {
    if (switchTarget) {
      switchTarget();
    }

    if (dismiss) {
      dismiss();
    }
  };

  return (
    linkOnly ? (
      <IonButton
        fill="clear"
        size="default"
        className="notification__button ion-text-wrap"
        routerLink={routes.buildMedicalPrescriptionUrl(notification.metadata.prescriptionId)}
        onClick={onClick}
      >
        <IonIcon icon={documentTextOutline} slot="start" />
        {t('notifications.actions.ViewPrescription')}
      </IonButton>
    ) : (
      <IonGrid>
        <IonRow>
          <IonCol size="6">
            <IonButton
              expand="block"
              fill="clear"
              size="default"
              className="notification__button ion-text-wrap"
              color="warning"
              onClick={() => updateStatus(MedicalPrescriptionStatus.Rejected)}
            >
              {t('notifications.actions.RejectPrescription')}
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton
              expand="block"
              fill="clear"
              size="default"
              className="notification__button ion-text-wrap"
              onClick={() => updateStatus(MedicalPrescriptionStatus.Accepted)}
            >
              {t('notifications.actions.AcceptPrescription')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    )
  );
}

export default NotificationPrescriptionActions;
