import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from '../../components/Button/Button';
import { useFarm } from '../../components/FarmContext/FarmContext';
import Field from '../../components/Field/Field';
import FieldSelector from '../../components/Field/FieldSelector';
import Form from '../../components/Form/Form';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import usePamApi from '../../hooks/usePamApi';
import { formatYearMonthDay } from '../../utils/formatters';
import AnimalBreed from './types/AnimalBreed';
import AnimalGroup from './types/AnimalGroup';
import AnimalSpecie from './types/AnimalSpecie';
import './AnimalGroupForm.css';

const MAX_ANIMAL_NUMBER = 1_000_000;

type AnimalGroupFormProps = {
  onSubmitted: () => void;
  editMode?: boolean;
  animalGroup?: AnimalGroup | null | undefined;
}

function AnimalGroupForm({ editMode = false, animalGroup, onSubmitted }: AnimalGroupFormProps): JSX.Element {
  const { t } = useTranslation();
  const { handleGenericFetchError } = useFriendlyErrors();
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [breedsOptions, setBreedsOptions] = useState([]);
  const [specieId, setSpecieId] = useState(-1);
  const { selectedFarmId } = useFarm();
  const pamApi = usePamApi('CreateAnimalGroup');
  const [current] = useState<AnimalGroup | null | undefined>(animalGroup && { ...animalGroup });

  useEffect(() => {
    if (!specieId || specieId < 0) {
      return;
    }

    const getAnimalBreeds = async (): Promise<void> => {
      setBreedsOptions([]);
      try {
        const response = await pamApi.get(
          `/animal-groups/animal-species/${specieId}/animal-breeds`,
        );

        if (response.ok) {
          const objectResponse = await response.json();
          const { animalBreeds } = objectResponse;

          setBreedsOptions(animalBreeds.map((animalBreed: AnimalBreed) => ({
            id: animalBreed.id,
            name: animalBreed.name,
          })));
        } else {
          handleGenericFetchError();
        }
      } catch (error) {
        handleGenericFetchError();
      }
    };

    getAnimalBreeds();
  }, [specieId]);

  useEffect(() => {
    const getAnimalSpecies = async (): Promise<void> => {
      try {
        const response = await pamApi.get(
          '/animal-groups/animal-species',
        );

        if (response.ok) {
          const objectResponse = await response.json();
          const { animalSpecies } = objectResponse;

          setSpeciesOptions(animalSpecies.map((animalSpecie: AnimalSpecie) => ({
            id: animalSpecie.id,
            name: animalSpecie.name,
          })));
        } else {
          handleGenericFetchError();
        }
      } catch (error) {
        handleGenericFetchError();
      }
    };

    getAnimalSpecies();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (formData: Record<string, any>): Promise<void> => {
    try {
      const apiMethod = editMode ? pamApi.put : pamApi.post;
      const response = await apiMethod(
        '/animal-groups',
        {
          ...formData,
          farmId: selectedFarmId,
          averageLactationDate: formatYearMonthDay(formData.averageLactationDate),
        },
      );

      if (response.ok) {
        onSubmitted();
      } else {
        handleGenericFetchError();
      }
    } catch (error) {
      handleGenericFetchError();
    }
  };

  const onChangeSpecie = (value: string): void => setSpecieId(+value);

  const schema = yup.object({
    name: yup.string()
      .required(t('forms.errors.Required')),
    count: yup.number()
      .required(t('forms.errors.Required'))
      .integer(t('forms.errors.GreaterThanZero'))
      .positive(t('forms.errors.GreaterThanZero'))
      .lessThan(MAX_ANIMAL_NUMBER, `${t('forms.errors.LessThan')}${MAX_ANIMAL_NUMBER}`)
      .typeError(t('forms.errors.GreaterThanZero')),
    speciesId: yup.string()
      .required(t('forms.errors.Required')),
    breedId: yup.string()
      .required(t('forms.errors.Required')),
    averageLactationDate: yup.date()
      .required(t('forms.errors.RequireDate'))
      .typeError(t('forms.errors.RequireDate'))
      .test('averageLactationDate', t('forms.errors.RequirePreviousDate'), (value) => !(!!value && +value > +new Date())),
  });

  const averageLactationDate = current?.averageLactationStartDate ? formatYearMonthDay(new Date(current?.averageLactationStartDate)) : '';

  return (
    <div className="animal-group-form-container">
      <h2>{t(editMode ? 'animalGroups.UpdateTitle' : 'animalGroups.CreateTitle')}</h2>
      <Form onSubmit={onSubmit} schema={schema}>
        {editMode && (
          <Field
            name="id"
            type="integer"
            value={current?.id}
            disabled
            hidden
          />
        )}
        <Field
          name="name"
          label={t('animalGroups.field.Name')}
          placeholder={t('animalGroups.field.NamePlaceholder')}
          value={current?.name}
          disabled={editMode}
        />
        <Field
          name="count"
          type="integer"
          label={t('animalGroups.field.Count')}
          placeholder={t('animalGroups.field.CountPlaceholder')}
          value={current?.count}
        />
        <FieldSelector
          name="speciesId"
          label={t('animalGroups.field.AnimalSpecieName')}
          placeholder={t('animalGroups.field.AnimalSpecieName')}
          options={speciesOptions}
          onChangeCustom={onChangeSpecie}
          defaultOption={current?.speciesId}
          disabled={editMode}
        />
        <FieldSelector
          name="breedId"
          label={t('animalGroups.field.AnimalBreedName')}
          placeholder={t('animalGroups.field.AnimalBreedName')}
          options={breedsOptions}
          defaultOption={current?.breedId}
          disabled={editMode}
        />
        <Field
          name="averageLactationDate"
          type="date"
          label={t('animalGroups.field.AverageLactationDate')}
          value={averageLactationDate}
        />
        <Button
          block
          submit
          disabled={false}
        >
          {t(editMode ? 'animalGroups.UpdateGroup' : 'animalGroups.AddGroup')}
        </Button>
      </Form>
    </div>
  );
}

export default AnimalGroupForm;
