import cn from 'classnames';
import { useEffect, useState } from 'react';
import { IonImg } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useIdentity, UserType } from '../../../../components/Identity/IdentityContext';
import LoginRegisterForm from './LoginRegisterForm';
import Page from '../../../../layout/Page/Page';
import './LoginRegisterPage.css';

interface RoleButtonProps {
  type: 'Farmer' | 'Veterinary';
  disabled?: boolean;
  onClick: () => void;
}

const noop = (): unknown => null;

function RoleButton({ type, disabled, onClick }: RoleButtonProps): JSX.Element {
  const { t } = useTranslation();
  const text = t(`login.register.roleButton.${type}`);

  return (
    <div
      className={cn(
        'login-register__roleButton',
        { disabled },
      )}
      role="button"
      tabIndex={0}
      onClick={disabled ? noop : onClick}
      onKeyDown={noop}
    >
      <IonImg src={`/assets/register/${type.toLocaleLowerCase()}.png`} alt={text} />
      <div>{text}</div>
    </div>
  );
}

function LoginRegisterPage(): JSX.Element {
  const { user } = useIdentity();
  const { t } = useTranslation();
  const [userType, setUserType] = useState<UserType>();
  const [title, setTitle] = useState('');

  const allowVeterinaryRegister = user?.roles?.includes(process.env.REACT_APP_VETERINARY_ROLE as string);

  useEffect(() => {
    const enableForm = userType === UserType.Farmer || userType === UserType.Veterinary;
    setTitle(t(enableForm ? 'login.register.FormTitle' : 'login.register.SelectRoleTitle'));
  }, [userType]);

  return (
    <Page title={title} hideFarmSelector hideMenu hideNotifications>
      {!(userType === UserType.Farmer || userType === UserType.Veterinary) && (
        <div className="login-register__roles">
          <RoleButton type="Farmer" onClick={() => setUserType(UserType.Farmer)} disabled={allowVeterinaryRegister} />
          <RoleButton type="Veterinary" onClick={() => setUserType(UserType.Veterinary)} disabled={!allowVeterinaryRegister} />
        </div>
      )}
      {(userType === UserType.Farmer || userType === UserType.Veterinary) && <LoginRegisterForm userType={userType} />}
    </Page>
  );
}

export default LoginRegisterPage;
