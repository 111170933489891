import { ReactNode } from 'react';
import { IonBadge } from '@ionic/react';

export interface BadgeProps {
  children: ReactNode;
}

function Badge({ children }: BadgeProps): JSX.Element {
  return (
    <IonBadge color="primary" mode="ios">{children}</IonBadge>
  );
}

export default Badge;
