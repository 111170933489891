import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { ReactNode } from 'react';
import './ErrorMessage.css';

export interface ErrorMessageProps {
  children: ReactNode;
}

function ErrorMessage({ children }: ErrorMessageProps): JSX.Element {
  return (
    <div className="errorMessage">
      <IonIcon icon={alertCircleOutline} />
      {children}
    </div>
  );
}

export default ErrorMessage;
