export const BACKGROUND_NOTIFICATIONS_STORAGE_KEY = 'pam.notification';

export const notificationType = {
  ALERT: 'Alert',
  RECOMMENDATION: 'Recommendation',
  PRESCRIPTION: 'Prescription',
} as const;

export const notificationBoxType = {
  ALERT: 'Alert',
  RECOMMENDATION: 'Recommendation',
  PRESCRIPTION: 'Prescription',
  PENDING: 'Pending',
} as const;

export const notificationRole = {
  ANY: 'Any',
  FARMER: 'Farmer',
  VETERINARY: 'Veterinary',
} as const;

export const notificationTag = {
  NewSupplementRecommendation: 'New_Supplement_Recommendation',
  NewPrescriptionAvailable: 'New_Prescription_Available',
  PrescriptionAccepted: 'Prescription_Accepted',
  PrescriptionRejected: 'Prescription_Rejected',
  AlertDangerZoneTriggered: 'Alert_Danger_Zone_Triggered',
  AlertInvalidLaboratoryData: 'Alert_Invalid_Laboratory_Data',
  AnomalyDetected: 'Anomaly_Detected',
  DangerZoneThresholdReached: 'DangerZone_Threshold_Reached',
} as const;

export type NotificationType = typeof notificationType[keyof typeof notificationType];

export type NotificationBoxType = typeof notificationBoxType[keyof typeof notificationBoxType];

export type NotificationRole = typeof notificationRole[keyof typeof notificationRole];

export type NotificationTag = typeof notificationTag[keyof typeof notificationTag];

export type NotificationMetadata = {
  farmName?: string;
  contact?: string;
  prescriptionId?: number;
  activeMedicinalBlendId?: number;
};

export type Notification = {
  id: number;
  farmId: number;
  managementAreaId: number
  created: string;
  type: NotificationType;
  boxType: NotificationBoxType;
  tag: NotificationTag;
  targetRole: NotificationRole;
  status: string;
  title: string;
  body: string;
  metadata: NotificationMetadata;
  targetFarmIsNotCurrent?: boolean;
};

export enum NotificationStatus {
  Undefined,
  Created,
  Sent,
  Accepted,
  Rejected
}

export interface FCMNotification {
  id: number;
  managementAreaId: number;
  farmId: number;
  type: NotificationType;
  tag: NotificationTag;
  targetRole: NotificationRole;
  status: string;
  created: string;
  title: string;
  body: string;
  actionTypes?: string;
  farmName?: string;
  contact?: string; // veterinary phone number
  prescriptionId?: number; // related prescriptionId
  activeMedicinalBlendId?: number; // related activeMedicinalBlendId
}

export interface StoredNotificationData {
  notificationId?: number;
  boxType?: NotificationBoxType;
}
