import cn from 'classnames';
import 'chart.js/auto';
import {
  ChartOptions,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import './LineChart.css';
import { mergeDeep } from '../../../utils/objects';

export type LineChartOptions = ChartOptions<'line'>;
export interface LineChartDataset {
  color?: string
  data: number[]
  label?: string
  yAxisID?: string
}

type LineChartProps = {
  className?: string;
  labels: string[];
  dataset: LineChartDataset[];
  options?: LineChartOptions;
};

export const defaultOptions: ChartOptions<'line'> = {
  responsive: true,
  aspectRatio: 4 / 3,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      position: 'left',
      grid: {
        color: '#CED9D4',
      },
    },
    x: {
      grid: {
        color: 'transparent',
      },
      ticks: {
        color: '#919995',
      },
    },
  },
};

function LineChart({
  labels,
  dataset,
  options = {},
  className,
}: LineChartProps): JSX.Element {
  return (
    <div className={cn('line-chart', className)}>
      {dataset?.length && dataset[0]?.data?.length && (
        <Chart
          type="line"
          data={{
            labels,
            datasets: dataset?.map((d) => ({
              yAxisID: d.yAxisID,
              label: d.label,
              data: d.data,
              borderColor: d.color || '#ffffffce',
              backgroundColor: '#ffffffce',
              spanGaps: false,
              fill: false,
              showLine: true,
              cubicInterpolationMode: 'monotone',
              tension: 0.6,
              pointBackgroundColor: d.color || 'transparent',
              pointRadius: 2.5,
            })),
          }}
          options={mergeDeep<ChartOptions<'line'>>(defaultOptions, options)}
        />
      )}
    </div>
  );
}

export default LineChart;
