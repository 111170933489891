import {
  IonModal, IonContent, IonIcon, IonButton,
} from '@ionic/react';
import { t } from 'i18next';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { callOutline } from 'ionicons/icons';
import { formatDate } from '../../utils/formatters';
import './InAppNotificationModal.css';
import {
  InAppNotificationActionType, inAppNotificationType, useInAppNotification,
} from '../InAppNotificationContext/InAppNotificationContext';
import { Notification } from '../../types/notifications';
import * as routes from '../../routing/routes';

type InAppNotificationModalProps = {
  notification: Notification;
  allowActions: boolean;
};

function InAppNotificationModal({ notification, allowActions }: InAppNotificationModalProps): JSX.Element {
  const { inAppNotificationDispatch } = useInAppNotification();
  const history = useHistory();

  const dismiss = (): void => {
    if (notification) {
      inAppNotificationDispatch({ type: InAppNotificationActionType.REMOVE, payload: notification.id });
    }
  };

  const goTo = (url: string): void => {
    dismiss();
    history.push(url);
  };

  const onViewRecommendation = (): void => goTo(routes.BotanicalFeed);

  const onViewPrescription = (): void => goTo(routes.buildMedicalPrescriptionUrl(notification.metadata?.prescriptionId));

  return (
    <IonModal
      className="notification-modal"
      isOpen={!!notification}
      onDidDismiss={dismiss}
    >
      {notification && (
        <IonContent>
          <div
            className={cn('notification-modal__type', {
              'notification-modal__type--warning': notification.type === inAppNotificationType.PRESCRIPTION,
              'notification-modal__type--danger': notification.type === inAppNotificationType.ALERT,
              'notification-modal__type--info': notification.type === inAppNotificationType.RECOMMENDATION,
            })}
          >
            {t(`notifications.${notification.type}`)}
          </div>
          <div className="notification-modal__box">
            <div className="notification-modal__date">
              {formatDate(new Date(notification.created), '2-digit', 'long', 'numeric')}
            </div>
            <div className="notification-modal__reason">
              {notification.title}
            </div>
            <div className="notification-modal__solution">
              {notification.body}
            </div>
            {allowActions && notification.type === inAppNotificationType.ALERT && notification.metadata.contact && (
              <a href={`tel:${notification.metadata.contact}`} onClick={dismiss}>
                <div className="notification-modal__phone">
                  <IonIcon icon={callOutline} size="large" />
                </div>
              </a>
            )}
          </div>
          {allowActions && notification.type === inAppNotificationType.PRESCRIPTION && notification.metadata.prescriptionId && (
            <div className="notification-modal__actions">
              <IonButton onClick={onViewPrescription}>
                {t('notifications.actions.ViewPrescription')}
              </IonButton>
            </div>
          )}
          {notification.type === inAppNotificationType.RECOMMENDATION && (
            <div className="notification-modal__actions">
              <IonButton onClick={onViewRecommendation}>
                {t('notifications.actions.ViewRecommendation')}
              </IonButton>
            </div>
          )}
        </IonContent>
      )}
    </IonModal>
  );
}

export default InAppNotificationModal;
