import { Redirect } from 'react-router-dom';
import {
  NotificationBoxType, BACKGROUND_NOTIFICATIONS_STORAGE_KEY, StoredNotificationData,
} from '../../types/notifications';
import * as routes from '../../routing/routes';
import { storage } from '../../utils/storage';
import { StoredFarmData } from '../../types/farms';

type BackgroundNotificationParams = {
  farmId?: string;
  boxType?: string;
  notificationId?: string;
}

type BackgroundNotificationProps = {
  params: BackgroundNotificationParams;
}

type BackgroundNotification = StoredNotificationData & StoredFarmData;

const REDIRECT_TO_BOX_TYPE = true;

function BackgroundNotifications({ params }: BackgroundNotificationProps): JSX.Element {
  const { farmId, notificationId, boxType } = params;
  const item: BackgroundNotification = {
    farmId: +(farmId || 0),
    notificationId: +(notificationId || 0),
    boxType: boxType as NotificationBoxType,
    ts: +new Date(),
  };

  storage.set(BACKGROUND_NOTIFICATIONS_STORAGE_KEY, item);

  const targetUrl = REDIRECT_TO_BOX_TYPE ? `${routes.Notifications}/${boxType}` : routes.Notifications;

  return (<Redirect to={targetUrl} />);
}

export default BackgroundNotifications;
