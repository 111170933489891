import { IonPage } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import './Welcome.css';
import * as routes from '../../routing/routes';

const WelcomeTime = 1000;

function Welcome(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(routes.App);
    }, WelcomeTime);
    return () => clearTimeout(timer);
  }, [history]);

  return (
    <IonPage className="welcome">
      <img src="/assets/logo.svg" className="welcome__logo" alt="" />
    </IonPage>
  );
}

export default Welcome;
