import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IonContent, IonModal } from '@ionic/react';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import * as routes from '../../routing/routes';
import WizardPage from '../../layout/Page/WizardPage';
import AnimalGroupForm from './AnimalGroupForm';

function AnimalGroupsWizard(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const footer = <Button block higher onClick={() => setIsOpen(true)}>{t('animalGroups.wizard.Action')}</Button>;

  const onDismiss = (): void => setIsOpen(false);

  const onCreate = (): void => {
    setIsOpen(false);
    history.push(routes.App);
  };

  return (
    <WizardPage img="bienvenido-nueva-era" footer={footer}>
      <h2>{t('animalGroups.wizard.Title')}</h2>
      <p>{t('animalGroups.wizard.Body')}</p>
      <IonModal isOpen={isOpen} initialBreakpoint={0.6} breakpoints={[0, 0.5, 0.6, 0.75, 1]} onDidDismiss={onDismiss}>
        <IonContent className="ion-padding">
          <AnimalGroupForm onSubmitted={onCreate} />
        </IonContent>
      </IonModal>
    </WizardPage>
  );
}

export default AnimalGroupsWizard;
