import { Control, Controller } from 'react-hook-form';
import Input, { InputModes, InputType } from '../Input/Input';

type FieldProps = {
  name: string,
  type?: FieldType,
  className?: string,
  label?: string,
  placeholder?: string,
  control?: Control,
  block?: boolean;
  value?: string | number;
  disabled?: boolean;
  step?: string;
  onChangeCustom?: (value: string) => void;
  defaultValue?: string | number;
  hidden?: boolean;
}

type FieldType = 'text' | 'email' | 'phone' | 'password' | 'personal' | 'rega' | 'number' | 'date' | 'integer' | 'decimal';

type TConfig = {
  [key in FieldType]: {
    label: string;
    placeholder: string;
    inputType: InputType;
    step?: string;
    inputMode?: InputModes;
    default: string | number;
  }
};

const getDefaultValue = (
  targetValue: string | number | null | undefined,
  targetDefaultValue: string | number | null | undefined,
  fallback: string | number,
): string | number => {
  if (targetValue !== undefined && targetValue !== null) {
    return targetValue;
  }

  if (targetDefaultValue !== undefined && targetDefaultValue !== null) {
    return targetDefaultValue;
  }

  return fallback;
};

function Field({
  name, type = 'text', className, label, placeholder, control, block, value, disabled, step, onChangeCustom, defaultValue, hidden,
}: FieldProps): JSX.Element {
  const config: TConfig = {
    text: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'text',
      default: '',
    },
    email: {
      label: label ?? '',
      placeholder: placeholder ?? 'user@email.sample',
      inputType: 'email',
      default: '',
    },
    phone: {
      label: label ?? '',
      placeholder: placeholder ?? '123 456 789',
      inputType: 'tel',
      default: '',
    },
    rega: {
      label: label ?? '',
      placeholder: placeholder ?? 'ES010020000003',
      inputType: 'text',
      default: '',
    },
    password: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'password',
      default: '',
    },
    personal: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'text',
      default: '',
    },
    number: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'number',
      step,
      default: 0,
    },
    integer: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'number',
      step,
      inputMode: 'numeric',
      default: 0,
    },
    decimal: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'number',
      inputMode: 'decimal',
      step: step ?? 'any',
      default: 0,
    },
    date: {
      label: label ?? '',
      placeholder: placeholder ?? '',
      inputType: 'date',
      default: '',
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getDefaultValue(value, defaultValue, config[type].default)}
      render={({ field: { onChange, value: currentValue }, fieldState: { error } }) => (
        <Input
          hidden={hidden}
          label={config[type].label}
          placeholder={config[type].placeholder}
          type={config[type].inputType}
          inputMode={config[type].inputMode}
          className={className}
          step={config[type].step}
          onChange={(v: string): void => {
            if (onChangeCustom) {
              onChangeCustom(v);
            }
            if (v !== null) {
              onChange(v);
            }
          }}
          block={block}
          error={error?.message}
          value={currentValue}
          disabled={disabled}
        />
      )}
    />
  );
}

export default Field;
