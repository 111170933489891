import { ReactNode } from 'react';
import cn from 'classnames';
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonIcon, IonItem, IonLabel,
} from '@ionic/react';
import {
  closeOutline, documentTextOutline, warningOutline, checkmarkCircleOutline, readerOutline,
} from 'ionicons/icons';
import {
  Notification, notificationTag, notificationType,
} from '../../types/notifications';
import { formatDateTime } from '../../utils/formatters';

type InAppNotificationProps = {
  notification: Notification;
  dismiss?: () => void;
  hideDate?: boolean;
  children?: ReactNode;
};

function InAppNotification({
  notification, dismiss, hideDate, children,
}: InAppNotificationProps): JSX.Element {
  const renderIcon = (): JSX.Element => {
    let icon = documentTextOutline;
    let color = 'medium';

    if (notification.type === notificationType.PRESCRIPTION) {
      if (notification.tag === notificationTag.PrescriptionAccepted) {
        icon = checkmarkCircleOutline;
        color = 'success';
      } else if (notification.tag === notificationTag.PrescriptionRejected) {
        icon = warningOutline;
        color = 'danger';
      } else {
        icon = documentTextOutline;
      }
    } else if (notification.type === notificationType.ALERT) {
      icon = warningOutline;
      color = 'danger';
    } else if (notification.type === notificationType.RECOMMENDATION) {
      icon = readerOutline;
    }

    return <IonIcon slot="start" icon={icon} color={color} size="large" />;
  };

  return (
    <IonCard className={cn('notification-item', 'ion-no-padding', 'notification-in-app')}>
      <IonCardHeader>
        <IonCardSubtitle>
          <IonItem lines="none" className="text-m">
            {!hideDate && <IonLabel slot="start">{formatDateTime(notification.created)}</IonLabel>}
            <IonButton slot="end" fill="clear" className="notification-close" onClick={dismiss}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonItem>
        </IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        <IonItem lines="none" className="text-m">
          {renderIcon()}
          <IonLabel position="stacked">{notification.metadata.farmName}</IonLabel>
          <IonLabel position="stacked">{notification.title}</IonLabel>
          <IonLabel position="stacked">{notification.body}</IonLabel>
        </IonItem>
      </IonCardContent>
      {children}
    </IonCard>
  );
}

export default InAppNotification;
