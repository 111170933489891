import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useIonLoading } from '@ionic/react';
import Button from '../../../../components/Button/Button';
import '../login-steps-shared.css';
import { useIdentity, UserType } from '../../../../components/Identity/IdentityContext';
import * as routes from '../../../../routing/routes';
import usePamApi from '../../../../hooks/usePamApi';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import FieldSelector, { FieldSelectorOption } from '../../../../components/Field/FieldSelector';
import Form from '../../../../components/Form/Form';
import Field from '../../../../components/Field/Field';

const MAX_VETERINARY_NUMBER = 100_000_000;

const provinces: FieldSelectorOption[] = [
  { name: 'A Coruña', id: 15 },
  { name: 'Álava', id: 1 },
  { name: 'Albacete', id: 2 },
  { name: 'Alicante', id: 3 },
  { name: 'Almería', id: 4 },
  { name: 'Asturias', id: 33 },
  { name: 'Ávila', id: 5 },
  { name: 'Badajoz', id: 6 },
  { name: 'Baleares', id: 7 },
  { name: 'Barcelona', id: 8 },
  { name: 'Burgos', id: 9 },
  { name: 'Cáceres', id: 10 },
  { name: 'Cádiz', id: 11 },
  { name: 'Cantabria', id: 39 },
  { name: 'Castellón', id: 12 },
  { name: 'Ceuta', id: 51 },
  { name: 'Ciudad Real', id: 13 },
  { name: 'Córdoba', id: 14 },
  { name: 'Cuenca', id: 16 },
  { name: 'Gerona', id: 17 },
  { name: 'Granada', id: 18 },
  { name: 'Guadalajara', id: 19 },
  { name: 'Guipúzcoa', id: 20 },
  { name: 'Huelva', id: 21 },
  { name: 'Huesca', id: 22 },
  { name: 'Jaén', id: 23 },
  { name: 'La Rioja', id: 26 },
  { name: 'Las Palmas', id: 35 },
  { name: 'León', id: 24 },
  { name: 'Lleida', id: 25 },
  { name: 'Lugo', id: 27 },
  { name: 'Madrid', id: 28 },
  { name: 'Málaga', id: 29 },
  { name: 'Melilla', id: 52 },
  { name: 'Murcia', id: 30 },
  { name: 'Navarra', id: 31 },
  { name: 'Ourense', id: 32 },
  { name: 'Palencia', id: 34 },
  { name: 'Pontevedra', id: 36 },
  { name: 'Salamanca', id: 37 },
  { name: 'Segovia', id: 40 },
  { name: 'Sevilla', id: 41 },
  { name: 'Soria', id: 42 },
  { name: 'Tarragona', id: 43 },
  { name: 'Tenerife', id: 38 },
  { name: 'Teruel', id: 44 },
  { name: 'Toledo', id: 45 },
  { name: 'Valencia', id: 46 },
  { name: 'Valladolid', id: 47 },
  { name: 'Vizcaya', id: 48 },
  { name: 'Zamora', id: 49 },
  { name: 'Zaragoza', id: 50 },
];

export type LoginRegisterFormProps = {
  userType: UserType;
}

function LoginRegisterForm({ userType }: LoginRegisterFormProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, refresh } = useIdentity();
  const [busy, setBusy] = useState(false);
  const [wasRegistered, setWasRegistered] = useState<boolean>(false);
  const pamApi = usePamApi('LoginRegister');
  const friendlyErrors = useFriendlyErrors();
  const [presentLoading, dismissLoading] = useIonLoading();

  const register = (formData: Record<string, string | number>): void => {
    presentLoading({
      showBackdrop: true,
      backdropDismiss: false,
      message: t('login.register.Registering'),
    });
    setBusy(true);

    pamApi.post(
      '/appusers',
      {
        ...formData,
        email: user?.username ?? '',
        type: Number(userType),
      },
    )
      .then(async (response) => {
        if (!response.ok) {
          const { errorCode } = await response.json();

          if (errorCode) {
            friendlyErrors.handleError(errorCode);
          } else {
            friendlyErrors.handleUserRegistrationFailedError();
          }
        } else {
          setWasRegistered(true);
        }
        refresh();
        setBusy(false);
        dismissLoading();
      })
      .catch(() => {
        friendlyErrors.handleUserRegistrationFailedError();
        setBusy(false);
        dismissLoading();
      });
  };

  useEffect(() => {
    if (wasRegistered && user && 'type' in user) {
      const route = user?.type === UserType.Farmer ? routes.FarmerOnBoarding : routes.VetOnBoarding;

      history.replace(route);
    }
  }, [user, wasRegistered]);

  const valueRequiredForVet = (value: number | undefined): boolean => ((userType === UserType.Farmer) || (userType === UserType.Veterinary && !!value));

  const requiredError = t('forms.errors.Required');

  const schema = yup.object({
    name: yup.string().required(requiredError),
    familyName: yup.string().required(requiredError),
    phoneNumber: yup.string().required(requiredError),
    veterinaryNumber: yup.number()
      .positive(requiredError)
      .integer(requiredError)
      .lessThan(MAX_VETERINARY_NUMBER, `${t('forms.errors.LessThan')}${MAX_VETERINARY_NUMBER}`)
      .typeError(requiredError)
      .test('vetnumber-required', requiredError, valueRequiredForVet),
    provinceCode: yup.number().test('province-required', requiredError, valueRequiredForVet),
  });

  return (
    <div>
      <Form onSubmit={register} schema={schema}>
        <Field
          name="name"
          label={t('login.register.label.Name')}
          placeholder={t('login.register.placeholder.Name')}
        />
        <Field
          name="familyName"
          label={t('login.register.label.FamilyName')}
          placeholder={t('login.register.placeholder.FamilyName')}
        />
        <Field
          name="email"
          label={t('login.register.label.Email')}
          placeholder={t('login.register.placeholder.Email')}
          disabled
          value={user?.username ?? ''}
        />
        <Field
          name="phoneNumber"
          label={t('login.register.label.PhoneNumber')}
          placeholder={t('login.register.placeholder.PhoneNumber')}
        />
        {userType === UserType.Veterinary && (
          <FieldSelector
            name="provinceCode"
            label={t('login.register.label.VeterinaryProvince')}
            placeholder={t('login.register.placeholder.VeterinaryProvince')}
            options={provinces}
          />
        )}
        {userType === UserType.Veterinary && (
          <Field
            name="veterinaryNumber"
            label={t('login.register.label.VeterinaryNumber')}
            placeholder={t('login.register.placeholder.VeterinaryNumber')}
            type="number"
          />
        )}
        {userType === UserType.Farmer && (
          <Field
            name="pamLicense"
            label={t('login.register.label.PamLicense')}
            placeholder={t('login.register.placeholder.PamLicense')}
            hidden
          />
        )}
        <Button
          className="login-step__next"
          disabled={busy}
          submit
          block
        >
          {t('actions.Next')}
        </Button>
      </Form>
    </div>
  );
}

export default LoginRegisterForm;
