import './MonthSelector.css';
import {
  IonButton,
  IonButtons,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { caretDownOutline } from 'ionicons/icons';

interface MonthSelectorOptions {
  onDateSelected: (date: Date) => void
}

function MonthSelector({ onDateSelected }: MonthSelectorOptions): JSX.Element {
  const { t, i18n } = useTranslation();
  const datetimeButtonRef = useRef<HTMLIonDatetimeButtonElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const selector = useRef<HTMLIonDatetimeElement>(null);
  const maxDate = new Date();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const confirmDateHandler = useCallback(async () => {
    modal.current?.dismiss();
    await selector.current?.confirm();
    const selectedDate = new Date(selector.current?.value as string);
    setCurrentDate(selectedDate);
    if (selectedDate) {
      onDateSelected(selectedDate);
    }
  }, [modal, selector, onDateSelected]);

  return (
    <>
      <IonDatetimeButton datetime="datetime" color="default" ref={datetimeButtonRef} mode="ios" className="monthSelectorButton-dateselector">
        <IonItem slot="date-target">
          <div className="monthSelectorButton-dateselector__date">
            {
              currentDate.toLocaleDateString(i18n.language, {
                month: 'long',
                year: 'numeric',
              })
            }
          </div>
          <IonIcon icon={caretDownOutline} slot="end" />
        </IonItem>
      </IonDatetimeButton>

      <IonModal keepContentsMounted ref={modal} className="monthSelector__modal">
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('monthSelector.Title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonDatetime
          id="datetime"
          firstDayOfWeek={1}
          presentation="month-year"
          size="cover"
          ref={selector}
          max={maxDate.toISOString()}
          locale={i18n.language}
        >
          <IonButtons slot="buttons">
            <IonButton
              color="primary"
              onClick={confirmDateHandler}
            >
              {t('monthSelector.Ok')}
            </IonButton>
          </IonButtons>
        </IonDatetime>
      </IonModal>
    </>
  );
}

export default MonthSelector;
