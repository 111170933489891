import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
  Unsubscribe,
} from 'firebase/messaging';
import {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../messaging/firebase-config';
import { useIdentity } from '../Identity/IdentityContext';
import { requestPushNotificationPermissionAsync } from '../../utils/push-notifications';
import { InAppNotificationActionType, useInAppNotification } from '../InAppNotificationContext/InAppNotificationContext';
import { useFarm } from '../FarmContext/FarmContext';
import { FCMNotification, Notification } from '../../types/notifications';
import usePamApi from '../../hooks/usePamApi';
import usePamNotificationsApi from '../../hooks/usePamNotificationsApi';
import useLogger from '../../hooks/useLogger';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';

const ALLOW_CROSS_FARM_MESSAGES = true;

type MessagingState = {
  token: string;
}

const MessagingContext = createContext<MessagingState>({ token: '' });

type MessagingContextProviderProps = {
  children: ReactNode;
}

function MessagingContextProvider({ children }: MessagingContextProviderProps): JSX.Element {
  const { t } = useTranslation();
  const [token, setToken] = useState<string>('');
  const { isLoggedIn } = useIdentity();
  const { selectedFarm, selectedFarmId, farms } = useFarm();
  const { inAppNotificationDispatch } = useInAppNotification();
  const [permission, setPermission] = useState('default');
  const pamApi = usePamApi('MessagingContextProvider');
  const { parseFCMNotification } = usePamNotificationsApi();
  const logger = useLogger();
  const friendlyErrors = useFriendlyErrors();

  const app = initializeApp(config.app);
  const messaging = getMessaging(app);

  const sendInAppNotification = useCallback((payload: MessagePayload): void => {
    if (!token || !selectedFarm?.id) {
      return;
    }

    const { notification: message, data } = payload;

    if (data) {
      const inAppNotification = {
        ...message,
        ...data,
        id: +data.id || +new Date(),
        farmId: +data.farmId,
        managementAreaId: +data.managementAreaId,
        contact: data.contact,
      } as FCMNotification;

      const notification: Notification = parseFCMNotification(inAppNotification);
      const isTargetFarmAvailable = farms.some((farm) => farm.id === notification.farmId);

      if (isTargetFarmAvailable) {
        notification.targetFarmIsNotCurrent = selectedFarm.id !== notification.farmId;

        if (ALLOW_CROSS_FARM_MESSAGES || !notification.targetFarmIsNotCurrent) {
          inAppNotificationDispatch({
            type: InAppNotificationActionType.ADD,
            payload: notification,
          });
        }
      }
    }
  }, [token, selectedFarmId, selectedFarm, farms]);

  const unsubscribe: Unsubscribe = () => null;

  useEffect(() => {
    if (!isLoggedIn) {
      return unsubscribe;
    }

    const checkAppPermission = async (): Promise<void> => {
      const permissionStatus = await requestPushNotificationPermissionAsync();
      setPermission(permissionStatus);
      if (permissionStatus !== 'granted') {
        friendlyErrors.handleMessagingPermissionDeniedError();
      }
    };

    checkAppPermission();
    return unsubscribe;
  }, [isLoggedIn]);

  useEffect(() => {
    const start = async (): Promise<void> => {
      if (permission !== 'granted') {
        return;
      }

      try {
        let currentToken: string = token;

        if (currentToken === '' && isLoggedIn) {
          currentToken = await getToken(messaging, { vapidKey: config.vapidKey });

          if (currentToken) {
            const response = await pamApi.post(`/messaging-sessions?registrationToken=${currentToken}`, null);

            if (response.ok) {
              setToken(currentToken);
            } else {
              friendlyErrors.handleMessagingUnableToRegisterTokenError();
            }
          } else {
            friendlyErrors.handleMessagingTokenUnavailableError();
          }
        }
      } catch (err) {
        logger.error('error', `${t('messaging.init.UnexpectedError')}`);
        friendlyErrors.handleMessagingUnexpectedError();
      }
    };

    start();
  }, [permission]);

  useEffect(() => {
    if (token && selectedFarm?.id) {
      return onMessage(messaging, (payload): void => sendInAppNotification(payload));
    }

    return unsubscribe;
  }, [token, selectedFarm]);

  const messagingState = useMemo<MessagingState>(() => ({
    token,
  }), [token, sendInAppNotification]);

  return (
    <MessagingContext.Provider value={messagingState}>
      {children}
    </MessagingContext.Provider>
  );
}

function useMessaging(): MessagingState {
  return useContext(MessagingContext);
}

export { MessagingContextProvider, useMessaging };
