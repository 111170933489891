import { ReactNode } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import cn from 'classnames';
import { RefresherEventDetail } from '@ionic/core';
import FarmSelector from '../../components/FarmSelector/FarmSelector';
import * as routes from '../../routing/routes';
import NotificationsCounterButton from '../../components/NotificationsCounterButton/NotificationsCounterButton';
import './Page.css';

type PageProps = {
  title: string;
  children: ReactNode;
  showBackButton?: boolean;
  hideFarmSelector?: boolean;
  disableFarmSelector?: boolean;
  hideNotifications?: boolean;
  hideMenu?: boolean;
  withFooter?: boolean;
  refresher?: () => (void | Promise<void>) | undefined;
};

function Page({
  title, children, showBackButton, hideFarmSelector, disableFarmSelector, hideNotifications, hideMenu, withFooter, refresher,
}: PageProps): JSX.Element {
  const refreshPage = (event: CustomEvent<RefresherEventDetail>): void => {
    if (refresher) {
      refresher();
      event.detail.complete();
    }
  };

  return (
    <IonPage className={cn({ 'with-footer': withFooter })}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">{title}</IonTitle>
          {(!hideMenu || showBackButton) && (
            <IonButtons slot="start">
              {(!hideMenu && showBackButton) && <IonBackButton text="" defaultHref={routes.App} />}
              {(!showBackButton && !hideMenu) && <IonMenuButton />}
            </IonButtons>
          )}
          {!hideNotifications && (
            <IonButtons slot="end">
              <NotificationsCounterButton />
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {refresher && (
          <IonRefresher slot="fixed" onIonRefresh={refreshPage}>
            <IonRefresherContent />
          </IonRefresher>
        )}
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="8" sizeLg="6">
              {!hideFarmSelector && <FarmSelector disabled={disableFarmSelector} />}
              {children}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default Page;
