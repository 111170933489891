import {
  IonButton,
  IonButtons,
  IonDatetime, IonDatetimeButton, IonIcon, IonItem, IonLabel, IonModal,
} from '@ionic/react';
import { caretDownOutline, informationCircleOutline } from 'ionicons/icons';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import { useFarm } from '../../../../components/FarmContext/FarmContext';
import Field from '../../../../components/Field/Field';
import FieldSelector, { FieldSelectorOption } from '../../../../components/Field/FieldSelector';
import Form from '../../../../components/Form/Form';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import usePamApi from '../../../../hooks/usePamApi';
import { formatYearMonthDay } from '../../../../utils/formatters';
import './AddDailyProductionForm.css';

const MAX_PRODUCTION_NUMBER = 1_000_000;

export interface AddDailyProductionFormProps {
  onProductionSaved: () => void
}

interface DaysWithoutProductionResponse {
  farmId: number,
  daysWithoutProduction: string[]
}

type SelectedDate = {
  date: string;
  editMode: boolean;
}

type DateRange = {
  start: string;
  end: string;
}

const getDateRange = (): DateRange => {
  const end = new Date();
  const start = new Date();

  start.setMonth(start.getMonth() - 1);

  return {
    start: formatYearMonthDay(start),
    end: formatYearMonthDay(end),
  };
};

function AddDailyProductionForm({ onProductionSaved }: AddDailyProductionFormProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { selectedFarmId } = useFarm();
  const [selectedDate, setSelectedDate] = useState<SelectedDate>();
  const pamApi = usePamApi('AddDailyProductionForm');
  const friendlyErrors = useFriendlyErrors();
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const modal = useRef<HTMLIonModalElement>(null);
  const dateSelector = useRef<HTMLIonDatetimeElement>(null);
  const [dateRange] = useState<DateRange>(getDateRange());

  const units: FieldSelectorOption[] = [
    { id: 'L', name: t('units.L') },
    { id: 'KG', name: t('units.KG') }];

  const getAvailableDays = async (farmId: number): Promise<string[]> => {
    const response = await pamApi.get(
      `/farms/${farmId}/days-without-production?startDate=${dateRange.start}&endDate=${dateRange.end}`,
    );
    if (!response.ok) {
      return [];
    }

    const { daysWithoutProduction }: DaysWithoutProductionResponse = await response.json();

    return daysWithoutProduction;
  };

  const insertDailyProduction = async (formData: Record<string, unknown>): Promise<void> => {
    if (!selectedFarmId || !selectedDate) {
      return;
    }

    try {
      const apiMethod = selectedDate.editMode ? pamApi.put : pamApi.post;
      const response = await apiMethod(
        '/farms/daily-milk-production',
        {
          farmId: selectedFarmId,
          date: selectedDate.date,
          quantity: formData.quantity,
          unit: formData.unit,
        },
      );

      if (response.ok) {
        getAvailableDays(selectedFarmId);
        onProductionSaved();
      } else {
        // eslint-disable-next-line no-throw-literal
        throw response.statusText;
      }
    } catch (error) {
      friendlyErrors.handleSendMilkProductionError();
    }
  };

  const selectDate = (input: string, dates?: string[]): void => {
    const date = formatYearMonthDay(new Date(input));

    setSelectedDate({
      date,
      editMode: !(dates || availableDates).includes(date),
    });
  };

  const confirmDateHandler = useCallback(async () => {
    modal.current?.dismiss();
    await dateSelector.current?.confirm();
    selectDate(dateSelector.current?.value as string);
  }, [modal, dateSelector, availableDates]);

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      const dates = await getAvailableDays(selectedFarmId);
      setAvailableDates(dates);
      selectDate(formatYearMonthDay(new Date()), dates);
    };
    loadData();
  }, [selectedFarmId]);

  const schema = yup.object({
    quantity: yup.number()
      .required(t('forms.errors.Required'))
      .positive(t('forms.errors.GreaterThanZero'))
      .min(0.1, t('forms.errors.GreaterThanZero'))
      .lessThan(MAX_PRODUCTION_NUMBER, `${t('forms.errors.LessThan')}${MAX_PRODUCTION_NUMBER}`)
      .typeError(t('forms.errors.GreaterThanZero')),
    unit: yup.string().required(t('forms.errors.Required')),
  });

  const renderSelectedDate = (): string => (selectedDate
    ? new Date(selectedDate.date)?.toLocaleDateString(i18n.language, {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
    : t('insertDailyMilkProduction.field.DatePlaceholder'));

  const defaultValues = { unit: units[0].id };

  return (
    <Form
      schema={schema}
      onSubmit={insertDailyProduction}
      className="addDailyProduction"
      defaultValues={defaultValues}
    >
      {!!selectedDate?.editMode && (
        <InfoBox icon={informationCircleOutline}>{t('insertDailyMilkProduction.UpdateProductionInfo')}</InfoBox>
      )}

      <IonDatetimeButton datetime="productionDateSelector" className="addDailyProduction-dateselector">
        <IonItem slot="date-target">
          <IonLabel color="primary" position="stacked">{t('insertDailyMilkProduction.field.Date')}</IonLabel>
          <div className="addDailyProduction-dateselector__date">
            {renderSelectedDate()}
          </div>
          <IonIcon icon={caretDownOutline} slot="end" />
        </IonItem>
      </IonDatetimeButton>

      <Field
        name="quantity"
        label={t('insertDailyMilkProduction.field.Quantity')}
        placeholder={t('insertDailyMilkProduction.field.QuantityPlaceholder')}
        type="number"
      />

      <FieldSelector
        name="unit"
        label={t('insertDailyMilkProduction.field.Unit')}
        placeholder={t('insertDailyMilkProduction.field.UnitPlaceholder')}
        options={units}
        defaultOption={defaultValues.unit}
      />

      <Button
        higher
        block
        submit
        className="mt--2"
      >
        {t(selectedDate?.editMode ? 'insertDailyMilkProduction.UpdateProduction' : 'actions.Submit')}
      </Button>

      <IonModal keepContentsMounted ref={modal}>
        <IonDatetime
          id="productionDateSelector"
          presentation="date"
          min={dateRange.start}
          max={dateRange.end}
          showDefaultTitle
          ref={dateSelector}
          locale={i18n.language}
          firstDayOfWeek={1}
        >
          <span slot="title">
            {t('insertDailyMilkProduction.DateModalTitle')}
          </span>
          <IonButtons slot="buttons">
            <IonButton
              color="primary"
              onClick={confirmDateHandler}
              disabled={!selectedDate}
              fill="solid"
            >
              {t('insertDailyMilkProduction.SelectDate')}
            </IonButton>
          </IonButtons>
        </IonDatetime>
      </IonModal>
    </Form>
  );
}

export default AddDailyProductionForm;
