import {
  IonList, IonItem, IonIcon, IonLabel,
} from '@ionic/react';
import { briefcaseOutline, personCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Page from '../../layout/Page/Page';
import InstallPWA from '../../components/InstallPWA/InstallPWA';
import * as routes from '../../routing/routes';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';

function Settings(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useIdentity();

  const navigateToFarms = (): void => {
    const targetRoute = user?.type === UserType.Veterinary
      ? routes.ConfigurationSelectManagementArea
      : routes.FarmConfiguration;

    history.push(targetRoute);
  };

  return (
    <Page title={t('settings.Title')} hideFarmSelector>
      <IonList>
        <IonItem
          lines="full"
          button
          detail
          disabled
        >
          <IonIcon slot="start" icon={personCircleOutline} />
          <IonLabel>{t('settings.items.UserData')}</IonLabel>
        </IonItem>
        <IonItem
          lines="full"
          button
          detail
          onClick={navigateToFarms}
        >
          <IonIcon slot="start" icon={briefcaseOutline} />
          <IonLabel>{t('settings.items.Farms')}</IonLabel>
        </IonItem>
        <InstallPWA />
      </IonList>
    </Page>
  );
}

export default Settings;
