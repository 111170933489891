import { IonIcon } from '@ionic/react';
import { t } from 'i18next';
import { scaleOutline } from 'ionicons/icons';
import { formatNumber } from '../../utils/formatters';
import './FeedTotal.css';

type FeedTotalProps = {
  amount: number;
}

function FeedTotal({ amount }: FeedTotalProps): JSX.Element {
  return (
    <div className="feedTotal">
      <div className="feedTotal__icon"><IonIcon icon={scaleOutline} /></div>
      <div className="feedTotal__content">
        <span className="feedTotal__label">{t('feedTotal.Total')}</span>
        <span className="feedTotal__value">
          {formatNumber(amount >= 0 ? amount : 0, false, 2)}
          {' '}
          <span className="feedTotal__value-unit">{t('feedTotal.TotalUnit')}</span>
        </span>
      </div>
    </div>
  );
}

export default FeedTotal;
