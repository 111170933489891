import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router';
import Menu from './layout/Menu/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './styles/typography.css';
import './styles/utils.css';
import './styles/main.css';
import Login from './pages/Login/Login';
import Welcome from './pages/Welcome/Welcome';
import BotanicalFeed from './pages/BotanicalFeed/BotanicalFeed';
import Notifications from './pages/Notifications/Notifications';
import BackgroundNotifications from './pages/Notifications/BackgroundNotification';
import AnimalGroups from './pages/AnimalGroups/AnimalGroups';
import Settings from './pages/Settings/Settings';
import RegisterFarm from './pages/RegisterFarm/RegisterFarm';
import SelectManagementArea from './pages/SelectManagementArea/SelectManagementArea';
import * as routes from './routing/routes';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { ToasterContextProvider } from './components/Toaster/ToasterContext';
import { IdentityContextProvider } from './components/Identity/IdentityContext';
import { FarmContextProvider } from './components/FarmContext/FarmContext';
import { MessagingContextProvider } from './components/MessagingContext/MessagingContext';
import { InAppNotificationContextProvider } from './components/InAppNotificationContext/InAppNotificationContext';
import ViewMedicalPrescriptionPage from './pages/MedicalPrescription/ViewMedicalPrescriptionPage';
import FarmTargets from './pages/FarmTargets/FarmTargets';
import PamAuthProvider from './components/Identity/PamAuthContext';
import Dashboard from './pages/Dashboard/Dashboard';
import FarmConfiguration from './pages/FarmConfiguration/FarmConfiguration';
import AnimalGroupsWizard from './pages/AnimalGroups/AnimalGroupsWizard';
import FarmerOnBoarding from './pages/Welcome/FarmerOnBoarding';
import VetOnBoarding from './pages/Welcome/VetOnBoarding';
import CreateMedicalPrescriptionPage from './pages/MedicalPrescription/CreateMedicalPrescriptionPage';
import UpdateFarm from './pages/UpdateFarm/UpdateFarm';
import ViewBotanicalFeed from './pages/BotanicalFeed/ViewBotanicalFeed';

setupIonicReact();

function App(): JSX.Element {
  return (
    <IonApp>
      <IonReactRouter>
        <Route
          path={routes.BackgroundNotifications}
          exact
          render={({ match }) => <BackgroundNotifications params={match.params} />}
        />
        <PamAuthProvider>
          <IdentityContextProvider>
            <ToasterContextProvider>
              <FarmContextProvider>
                <InAppNotificationContextProvider>
                  <MessagingContextProvider>
                    <Route path={routes.Root} exact>
                      <Redirect to={routes.Welcome} />
                    </Route>
                    <Route
                      path={routes.Welcome}
                      exact
                      render={() => <Welcome />}
                    />
                    <PrivateRoute
                      path={routes.LoginMode}
                      exact
                      render={() => <Login />}
                    />
                    <Route
                      path={routes.Login}
                      exact
                      render={() => <Login />}
                    />
                    <PrivateRoute
                      path={routes.App}
                      render={() => (
                        <>
                          <Menu />
                          <IonRouterOutlet id="main">
                            <PrivateRoute path={routes.FarmerOnBoarding}>
                              <FarmerOnBoarding />
                            </PrivateRoute>
                            <PrivateRoute path={routes.VetOnBoarding}>
                              <VetOnBoarding />
                            </PrivateRoute>
                            <PrivateRoute path={routes.App} exact>
                              <Dashboard />
                            </PrivateRoute>
                            <PrivateRoute path={routes.AnimalGroups}>
                              <AnimalGroups />
                            </PrivateRoute>
                            <PrivateRoute path={routes.AnimalGroupsWizard}>
                              <AnimalGroupsWizard />
                            </PrivateRoute>
                            <PrivateRoute path={routes.Targets}>
                              <FarmTargets />
                            </PrivateRoute>
                            <PrivateRoute path={routes.BotanicalFeed} exact>
                              <BotanicalFeed />
                            </PrivateRoute>
                            <PrivateRoute path={routes.ViewBotanicalFeed} exact>
                              <ViewBotanicalFeed />
                            </PrivateRoute>
                            <PrivateRoute path={routes.MedicalPrescription} exact>
                              <ViewMedicalPrescriptionPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.CreateMedicalPrescription}>
                              <CreateMedicalPrescriptionPage />
                            </PrivateRoute>
                            <PrivateRoute path={routes.NotificationsType} exact>
                              <Notifications />
                            </PrivateRoute>
                            <PrivateRoute path={routes.Settings}>
                              <Settings />
                            </PrivateRoute>
                            <PrivateRoute path={routes.RegisterFarm}>
                              <RegisterFarm registerMode />
                            </PrivateRoute>
                            <PrivateRoute path={routes.RegisterSelectManagementArea}>
                              <SelectManagementArea registerMode />
                            </PrivateRoute>
                            <PrivateRoute path={routes.ConfigurationSelectManagementArea}>
                              <SelectManagementArea />
                            </PrivateRoute>
                            <PrivateRoute path={routes.FarmConfiguration}>
                              <FarmConfiguration />
                            </PrivateRoute>
                            <PrivateRoute path={routes.UpdateFarm}>
                              <UpdateFarm />
                            </PrivateRoute>
                          </IonRouterOutlet>
                        </>
                      )}
                    />
                  </MessagingContextProvider>
                </InAppNotificationContextProvider>
              </FarmContextProvider>
            </ToasterContextProvider>
          </IdentityContextProvider>
        </PamAuthProvider>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
