import {
  defaultKPI, kpiConfiguration, MilkQuality, MilkQualityCounters, MilkQualityKPI, milkQualityKPIs, QUALITY_MIN_DIGITS,
} from '../types/milkQuality';

const generateBaseLayout = (): MilkQualityCounters => {
  const output = milkQualityKPIs.reduce((acc, kpi) => {
    const kpiConfig = kpiConfiguration[kpi];
    acc[kpi] = {
      ...defaultKPI,
      ...kpiConfig,
      truncate: true,
      digits: QUALITY_MIN_DIGITS,
      label: `milkKPIs.${kpi}`,
    };
    return acc;
  }, {} as MilkQualityCounters);

  return output;
};

export const milkQualityCounters: MilkQualityCounters = generateBaseLayout();

export const parseQualityData = (source: MilkQuality = {} as MilkQuality): MilkQualityCounters => {
  const output = Object.entries(source).reduce((acc, [key, value]) => {
    const kpiConfig = kpiConfiguration[key as MilkQualityKPI];
    acc[key as MilkQualityKPI] = {
      ...milkQualityCounters[key as MilkQualityKPI],
      min: value,
      max: value,
      truncate: value === 0 || !!kpiConfig.truncate,
      digits: value === 0 ? QUALITY_MIN_DIGITS : kpiConfig.digits,
    };
    return acc;
  }, {} as MilkQualityCounters);

  return output;
};
