import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';
import { useToaster } from '../components/Toaster/ToasterContext';

export interface PamFriendlyErrorHandlers {
  handleError: (key: string) => void;
  handleFarmRegistrationFailedError: () => void;
  handleFetchTargetsError: () => void;
  handleGenericFetchError: () => void;
  handleGetMilkProductionError: () => void;
  handleGetNotificationsError: () => void;
  handleGetPrescriptionError: () => void;
  handleMessagingPermissionDeniedError: () => void;
  handleMessagingTokenUnavailableError: () => void;
  handleMessagingUnableToRegisterTokenError: () => void;
  handleMessagingUnexpectedError: () => void;
  handlePostPrescriptionError: () => void;
  handleSendMilkProductionError: () => void;
  handleUnknownError: () => void;
  handleUpdateMedicalPrescriptionError: () => void;
  handleUserRegistrationFailedError: () => void;
  handleNoPrescriptionError: () => ReactElement;
  handleAssignFarmError: () => void;
  handleUpdateFailedError: () => void;
}

function useFriendlyErrors(): PamFriendlyErrorHandlers {
  const { t } = useTranslation();
  const { openToaster } = useToaster();

  const errorToaster = (i18nKey: string): void => openToaster('error', t(i18nKey, `${t('errors.UnknownError')} [${i18nKey}]`));

  return ({
    handleError: (key: string) => errorToaster(`errors.${key}`),
    handleFarmRegistrationFailedError: () => errorToaster('errors.CannotRegisterFarm'),
    handleFetchTargetsError: () => errorToaster('errors.CannotFetchTargets'),
    handleGenericFetchError: () => errorToaster('errors.GenericFetchError'),
    handleGetMilkProductionError: () => errorToaster('errors.CannotGetMilkProduction'),
    handleGetNotificationsError: () => errorToaster('errors.CannotGetNotifications'),
    handleGetPrescriptionError: () => errorToaster('errors.CannotFetchMedicalPrescription'),
    handleMessagingPermissionDeniedError: () => errorToaster('errors.messaging.PermissionDenied'),
    handleMessagingTokenUnavailableError: () => errorToaster('errors.messaging.TokenUnavailable'),
    handleMessagingUnableToRegisterTokenError: () => errorToaster('errors.messaging.UnableToRegisterToken'),
    handleMessagingUnexpectedError: () => errorToaster('errors.messaging.UnexpectedError'),
    handleNoPrescriptionError: () => (
      <ErrorMessage>
        {t('errors.NoPrescriptionForFarmer')}
      </ErrorMessage>
    ),
    handlePostPrescriptionError: () => errorToaster('errors.CannotCreateMedicalPrescription'),
    handleSendMilkProductionError: () => errorToaster('errors.CannotSendMilkProduction'),
    handleUnknownError: () => errorToaster('errors.UnknownError'),
    handleUpdateMedicalPrescriptionError: () => errorToaster('errors.CannotUpdateMedicalPrescription'),
    handleUserRegistrationFailedError: () => errorToaster('errors.CannotRegisterUser'),
    handleAssignFarmError: () => errorToaster('errors.CannotAssignFarm'),
    handleUpdateFailedError: () => errorToaster('errors.CannotUpdate'),
  });
}

export default useFriendlyErrors;
