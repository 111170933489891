import { Redirect, Route, RouteProps } from 'react-router-dom';
import * as routes from '../../routing/routes';
import { useIdentity } from '../Identity/IdentityContext';

function PrivateRoute({ children, ...rest }: RouteProps): JSX.Element {
  const { isLoggedIn, wasChecked, isRegistered } = useIdentity();

  if (!wasChecked) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (!isLoggedIn) {
    return <Redirect to={routes.Login} />;
  }

  if (!isRegistered && rest.path !== routes.LoginMode) {
    return <Redirect to={routes.LoginRegister} />;
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      {children}
    </Route>
  );
}

export default PrivateRoute;
