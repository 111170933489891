import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useFarm } from '../../components/FarmContext/FarmContext';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import Page from '../../layout/Page/Page';
import BatchSelector from './BatchSelector';
import CreateMedicalPrescription from './CreateMedicalPrescription';
import AnimalGroup from './types/AnimalGroup';
import * as routes from '../../routing/routes';

export type MedicalPrescriptionRouteOptions = {
  blendId: number;
  blendName: string;
  ts: number;
}

function CreateMedicalPrescriptionPage(): JSX.Element {
  const { t } = useTranslation();
  const { selectedFarmId } = useFarm();
  const [selectedBatch, setSelectedBatch] = useState<AnimalGroup | null>(null);
  const identity = useIdentity();
  const friendlyErrors = useFriendlyErrors();
  const location = useLocation<MedicalPrescriptionRouteOptions>();
  const history = useHistory();

  const onPrescriptionCreatedOrUpdated = (): void => {
    history.replace(routes.BotanicalFeed, { ts: +new Date() });
  };

  useEffect(() => {
    if (identity.user?.type === UserType.Farmer) {
      history.push(routes.BotanicalFeed);
    }
  }, [identity.user?.type]);

  return (
    <Page title={t('medicalPrescription.Title')} showBackButton disableFarmSelector>
      <h2>{t('medicalPrescription.Title')}</h2>

      <BatchSelector
        onError={friendlyErrors.handleGetPrescriptionError}
        onBatchSelected={setSelectedBatch}
        selectedFarmId={selectedFarmId}
        className="medicalPrescription__input"
        noIcon
      />

      {
        selectedBatch
        && identity.user?.type === UserType.Veterinary
        && (
          <CreateMedicalPrescription
            animalGroup={selectedBatch}
            selectedFarmId={selectedFarmId}
            blend={location.state}
            onPrescriptionCreated={onPrescriptionCreatedOrUpdated}
          />
        )
      }
    </Page>
  );
}

export default CreateMedicalPrescriptionPage;
