import {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import { Identity, useIdentity, UserType } from '../Identity/IdentityContext';
import * as routes from '../../routing/routes';
import { storage } from '../../utils/storage';
import { BACKGROUND_NOTIFICATIONS_STORAGE_KEY } from '../../types/notifications';
import { Farm, StoredFarmData } from '../../types/farms';

const ACTIVE_FARM_STORAGE_KEY = 'pam.active';
const DEFAULT_FARM_ID = 0;

export const EmptyFarm: Farm = {
  id: DEFAULT_FARM_ID,
  name: '',
};

type FarmContextProviderProps = {
  children: ReactNode
};

type FarmState = {
  farms: Farm[],
  selectedFarmId: number;
  selectedFarm: Farm;
  selectFarm: (id: number, farmList?: Farm[]) => void;
  reload: () => void;
  loading: boolean;
  reloadAndSelect: (id: number) => void;
};

const EmptyState: FarmState = {
  farms: Array<Farm>(),
  selectedFarmId: 0,
  selectedFarm: EmptyFarm,
  selectFarm: () => null,
  reload: () => null,
  loading: false,
  reloadAndSelect: () => null,
};

const FarmContext = createContext<FarmState>(EmptyState);

const routesWhitelist = [
  routes.ConfigurationSelectManagementArea,
  routes.RegisterSelectManagementArea,
  routes.RegisterFarm,
  routes.Welcome,
  routes.Login,
  routes.LoginRegister,
  routes.VetOnBoarding,
  routes.FarmerOnBoarding,
];

const findFarm = (farms: Farm[], id: number): Farm | undefined => farms?.find((f) => f.id === id);

const storeActiveFarm = (farmId: number, removeIfDefault = true): void => {
  if (farmId === DEFAULT_FARM_ID && removeIfDefault) {
    storage.remove(ACTIVE_FARM_STORAGE_KEY);
    return;
  }

  const activeFarm: StoredFarmData = {
    farmId,
    ts: +new Date(),
  };

  storage.set(ACTIVE_FARM_STORAGE_KEY, activeFarm);
};

const getActiveFarmId = (farms: Farm[] = []): number => {
  let targetFarmId = DEFAULT_FARM_ID;
  const backgroundFarm = storage.get<StoredFarmData>(BACKGROUND_NOTIFICATIONS_STORAGE_KEY, true);

  if (backgroundFarm?.farmId && findFarm(farms, backgroundFarm?.farmId)) {
    targetFarmId = backgroundFarm?.farmId;
  }

  if (!targetFarmId) {
    const activeFarm = storage.get<StoredFarmData>(ACTIVE_FARM_STORAGE_KEY);

    if (activeFarm?.farmId && findFarm(farms, activeFarm?.farmId)) {
      targetFarmId = activeFarm?.farmId;
    }
  }

  return targetFarmId || farms[0]?.id || DEFAULT_FARM_ID;
};

function FarmContextProvider({ children }: FarmContextProviderProps): JSX.Element {
  const { user } = useIdentity();
  const [farms, setFarms] = useState<Farm[]>([]);
  const [selectedFarmId, setSelectedFarmId] = useState<number>(0);
  const [selectedFarm, setSelectedFarm] = useState<Farm>(EmptyFarm);
  const pamApi = usePamApi('FarmContextProvider');
  const { handleGenericFetchError } = useFriendlyErrors();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const [reload, setReload] = useState(false);

  const selectFarm = (id: number, farmList?: Farm[]): void => {
    if (id === selectedFarmId) {
      return;
    }

    const farm = id ? findFarm(farmList || farms, id) || EmptyFarm : EmptyFarm;

    storeActiveFarm(farm.id);
    setSelectedFarmId(farm.id);
    setSelectedFarm(farm);
  };

  const checkUser = (currentUser: Identity | undefined): void => {
    if (currentUser && 'type' in currentUser) {
      const shouldRedirect = !routesWhitelist.includes(location.pathname);

      if (shouldRedirect) {
        const isFarmer = currentUser?.type === UserType.Farmer;
        const route = isFarmer ? routes.FarmerOnBoarding : routes.RegisterSelectManagementArea;

        history.push(route);
      }
    }
  };

  const refreshData = async (userId: number): Promise<void> => {
    if (!userId) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setReload(false);

    try {
      const response = await pamApi.get(`/appusers/${userId}/farms`);
      const responseFarms: Farm[] = response.ok ? await response.json() : [];

      setFarms(responseFarms);

      if (responseFarms.length) {
        const activeFarmId = getActiveFarmId(responseFarms);

        selectFarm(activeFarmId, responseFarms);
      } else if (!responseFarms.length) {
        selectFarm(DEFAULT_FARM_ID);
        checkUser(user);
      }
    } catch {
      setFarms([]);
      selectFarm(DEFAULT_FARM_ID);
      handleGenericFetchError();
    }
    setLoading(false);
  };

  const farmState = useMemo<FarmState>(() => ({
    loading,
    farms,
    selectedFarmId,
    selectedFarm,
    reload: () => setReload(true),
    selectFarm,
    reloadAndSelect: (farmId: number) => {
      if (farmId) {
        storeActiveFarm(farmId);
      }
      setReload(true);
    },
  }), [selectedFarmId, selectedFarm, farms]);

  useEffect(() => {
    refreshData(user?.id ?? 0);
  }, [user]);

  useEffect(() => {
    if (reload) {
      refreshData(user?.id ?? 0);
    }
  }, [reload]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!farms.length) {
      checkUser(user);
    }
  }, [location, history, farms]);

  return (
    <FarmContext.Provider value={farmState}>
      {children}
    </FarmContext.Provider>
  );
}

function useFarm(): FarmState {
  return useContext(FarmContext);
}

export { FarmContextProvider, useFarm };
