import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  IonButton, IonIcon, IonItem, IonLabel,
} from '@ionic/react';
import { documentTextOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';
import MedicalPrescription from '../MedicalPrescription/types/MedicalPrescription';
import { formatDate } from '../../utils/formatters';
import * as routes from '../../routing/routes';
import './MedicalPrescriptionCard.css';
import PrescriptionStatusBadge from './PrescriptionStatusBadge';
import { MedicalPrescriptionStatus, REMOVE_BLEND_ID } from '../../types/MedicalPrescription';

export interface MedicalPrescriptionCardProps {
  prescription: MedicalPrescription
}

const dateFormatted = (date: Date | string): string => formatDate(new Date(date), '2-digit', '2-digit', 'numeric');

function MedicalPrescriptionCard({ prescription }: MedicalPrescriptionCardProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const isProposed = prescription.status === MedicalPrescriptionStatus[MedicalPrescriptionStatus.Created];

  const navigateToViewMedicalPrescription = (): void => {
    if (!prescription?.id) {
      return;
    }
    const url = routes.buildMedicalPrescriptionUrl(prescription.id);
    history.push(url, { ts: +new Date() });
  };

  return (
    <div className={cn('medicalPrescriptionCard', `medicalPrescriptionCard__${prescription.status.toLowerCase()}`)}>
      <IonItem className="medicalPrescriptionCard__title">
        <IonLabel>
          {
            isProposed ? t('medicalPrescriptionCard.TitleProposed') : t('medicalPrescriptionCard.TitleLast')
          }
        </IonLabel>
        <PrescriptionStatusBadge slot="end" prescription={prescription} />
      </IonItem>

      <IonItem>
        <IonLabel>{t('medicalPrescriptionCard.PrescriptionNumber')}</IonLabel>
        <IonLabel slot="end" className="medicalPrescriptionCard__data">{prescription.prescriptionId}</IonLabel>
      </IonItem>

      <IonItem>
        <IonLabel>{t('medicalPrescriptionCard.MedicinalBlend')}</IonLabel>
        <IonLabel slot="end" className="medicalPrescriptionCard__data">
          {
            (prescription?.medicinalBlendId === REMOVE_BLEND_ID) ? t('botanicalFeed.RemoveActualBlend') : prescription.medicinalBlendName
          }
        </IonLabel>
      </IonItem>

      {prescription.acceptedDate && (
        <IonItem>
          <IonLabel>{t('medicalPrescriptionCard.AcceptedAt')}</IonLabel>
          <IonLabel slot="end" className="medicalPrescriptionCard__data">{dateFormatted(prescription.acceptedDate)}</IonLabel>
        </IonItem>
      )}

      {prescription.rejectedDate && (
        <IonItem>
          <IonLabel>{t('medicalPrescriptionCard.RejectedAt')}</IonLabel>
          <IonLabel slot="end" className="medicalPrescriptionCard__data">{dateFormatted(prescription.rejectedDate)}</IonLabel>
        </IonItem>
      )}

      <IonButton
        size="large"
        expand={isProposed ? 'full' : 'block'}
        fill={isProposed ? 'solid' : 'clear'}
        onClick={navigateToViewMedicalPrescription}
      >
        {!isProposed && <IonIcon slot="start" icon={documentTextOutline} />}
        {isProposed ? t('medicalPrescriptionCard.ButtonProposed') : t('medicalPrescriptionCard.ButtonLast')}
      </IonButton>
    </div>
  );
}

export default MedicalPrescriptionCard;
