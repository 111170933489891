import { IonImg } from '@ionic/react';
import './WizardImage.css';

export type WizardImages = 'bienvenido-nueva-era'
| 'control-bienestar'
| 'control-calidad-leche'
| 'granja-crece'
| 'optimiza-produccion'
| 'apoyo-prescripcion'
| 'monitorizacion-granjas'
| 'respuesta-tiempo-real'
| 'toma-decision';

type WizardImageProps = {
  img: WizardImages;
};

function WizardImage({ img }: WizardImageProps): JSX.Element {
  return (
    <div className="wizardImage">
      <div className="wizardImage__border">
        <IonImg src={`/assets/wizards/${img}.png`} alt="" />
      </div>
    </div>
  );
}

export default WizardImage;
