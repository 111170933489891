import {
  IonAccordion, IonAccordionGroup, IonToggle,
} from '@ionic/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './DataTable.css';

export interface DataTableProps {
  headers: string[];
  data: string[][];
}

function DataTable({ headers, data }: DataTableProps): JSX.Element {
  const { t } = useTranslation();
  const [showData, setShowData] = useState(false);
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  return (
    <IonAccordionGroup
      multiple={false}
      animated
      onIonChange={
        ({ detail }) => {
          if (detail?.value === 'on') {
            return;
          }
          setShowData(detail?.value === 'data');
        }
      }
      ref={accordionGroup}
      className="dataTable"
    >
      <IonAccordion value="data">
        <div slot="header" className="dataTable__header">
          <div>{t('dataTable.ShowData')}</div>
          <IonToggle checked={showData} color="primary" mode="ios" />
        </div>
        <table slot="content" className="dataTable__table">
          <thead>
            <tr>
              { headers.map((header) => <th key={`table-th-${header}`}>{ header }</th>) }
            </tr>
          </thead>
          <tbody>
            { data.map((row, rowIdx) => {
              const rowKey = `table-tr-${rowIdx}`;
              return (
                <tr key={rowKey}>
                  { row.map((col, colIdx) => {
                    const key = `table-td-${rowIdx}-${colIdx}`;
                    return <td key={key}>{col}</td>;
                  })}
                </tr>
              );
            }) }
          </tbody>
        </table>
      </IonAccordion>
    </IonAccordionGroup>
  );
}

export default DataTable;
