import { t } from 'i18next';
import { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { DashboardWidgetProps } from '..';
import AddDailyProductionForm from './AddDailyProductionForm';

// eslint-disable-next-line max-len
const addProductionIcon = "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 32 32'><title>Add Production</title><path clip-rule='evenodd' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' d='M32 7C32 10.866 28.866 14 25 14C21.134 14 18 10.866 18 7C18 3.13401 21.134 0 25 0C28.866 0 32 3.13401 32 7ZM26 3C26 2.44772 25.5523 2 25 2C24.4477 2 24 2.44772 24 3V6H21C20.4477 6 20 6.44772 20 7C20 7.55228 20.4477 8 21 8H24V11C24 11.5523 24.4477 12 25 12C25.5523 12 26 11.5523 26 11V8H29C29.5523 8 30 7.55228 30 7C30 6.44772 29.5523 6 29 6H26V3ZM11.0857 7H16C16 7.68739 16.0771 8.35678 16.223 9H11.0857C10.8096 9 10.5857 9.22386 10.5857 9.5C10.5857 9.77614 10.8096 10 11.0857 10H16.5121C16.7638 10.7121 17.1025 11.383 17.5155 12H12.0857V13.0858C12.0857 13.6162 11.875 14.1249 11.4999 14.5L9.58569 16.4142V28C9.58569 28.5523 10.0334 29 10.5857 29H20.5857C21.138 29 21.5857 28.5523 21.5857 28V16.4142L19.6715 14.5C19.5102 14.3387 19.3793 14.1527 19.2825 13.9508C20.4743 14.9323 21.9229 15.6133 23.5124 15.8776C23.5606 16.0508 23.5857 16.2312 23.5857 16.4142V28C23.5857 29.6569 22.2425 31 20.5857 31H10.5857C8.92884 31 7.58569 29.6569 7.58569 28V16.4142C7.58569 15.8838 7.79641 15.3751 8.17148 15L10.0857 13.0858V11.792C9.20274 11.4062 8.58569 10.5252 8.58569 9.5C8.58569 8.11929 9.70498 7 11.0857 7ZM20.5858 20H10.5858V27C10.5858 27.5523 11.0335 28 11.5858 28H19.5858C20.1381 28 20.5858 27.5523 20.5858 27V20Z'/></svg>";

export interface AddProductionWidgetProps extends DashboardWidgetProps {
  onProductionUpdated: () => void
}

function AddProductionWidget({ timestamp, onProductionUpdated }: AddProductionWidgetProps): JSX.Element {
  const [showAddProduction, setShowAddProduction] = useState(false);

  const onProductionSaved = (): void => {
    setShowAddProduction(false);
    onProductionUpdated();
  };

  return (
    <div className="addProductionWidget dashboard-widget" key={timestamp}>
      <IonButton size="large" expand="full" className="text--uppercase" onClick={() => setShowAddProduction(true)}>
        {t('productionReport.AddProduction')}
        <IonIcon slot="start" icon={addProductionIcon} />
      </IonButton>

      <IonModal
        isOpen={showAddProduction}
        initialBreakpoint={0.8}
        breakpoints={[0, 0.8, 1]}
        canDismiss
        onDidDismiss={() => setShowAddProduction(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('insertDailyMilkProduction.Title')}</IonTitle>
            <IonButtons slot="end">
              <IonButton color="medium" onClick={() => setShowAddProduction(false)}><IonIcon icon={closeOutline} /></IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <AddDailyProductionForm onProductionSaved={onProductionSaved} />
        </IonContent>
      </IonModal>

    </div>
  );
}

export default AddProductionWidget;
