import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as routes from '../../routing/routes';
import WizardWithSlides, { WizardSlide } from '../../layout/Page/WizardWithSlidesPage';

function FarmerOnBoarding(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const slides: WizardSlide[] = [
    {
      image: 'control-calidad-leche',
      title: t('farmerOnBoarding.slide_1.Title'),
      body: t('farmerOnBoarding.slide_1.Body'),
    },
    {
      image: 'granja-crece',
      title: t('farmerOnBoarding.slide_2.Title'),
      body: t('farmerOnBoarding.slide_2.Body'),
    },
    {
      image: 'optimiza-produccion',
      title: t('farmerOnBoarding.slide_3.Title'),
      body: t('farmerOnBoarding.slide_3.Body'),
    },
    {
      image: 'control-bienestar',
      title: t('farmerOnBoarding.slide_4.Title'),
      body: t('farmerOnBoarding.slide_4.Body'),
    },
  ];

  return (
    <WizardWithSlides slides={slides} onFinish={() => history.push(routes.RegisterFarm)} />
  );
}

export default FarmerOnBoarding;
