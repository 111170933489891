import { forwardRef, Ref } from 'react';
import InputForReactHookForm, { BaseInputProps } from './InputForReactHookForm';

type InputDecimalNumberProps = BaseInputProps & {
  step?: string
}

function InputDecimalNumber(props: InputDecimalNumberProps, ref: Ref<HTMLIonInputElement>):JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InputForReactHookForm {...props} type="number" ref={ref} />;
}

export default forwardRef(InputDecimalNumber);
