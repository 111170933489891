import { useIonToast } from '@ionic/react';
import {
  createContext, ReactNode, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import './ToasterContext.css';

export type ToastType= 'default' | 'error' | 'success'

const TOAST_DEFAULT_DURATION = 10000;

const NotImplementedProvider: UseToasterReturn = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openToaster: (_type: ToastType, _content: string, _duration?: number) => { throw new Error('Not implemented'); },
};

const ToasterContext = createContext<UseToasterReturn>(NotImplementedProvider);

type UseToasterReturn = {
  openToaster: (type: ToastType, content: string, duration?: number) => void;
};

type ToasterContextProviderProps = {
  children: ReactNode;
};

function ToasterContextProvider({ children }: ToasterContextProviderProps): JSX.Element {
  const [presentToast] = useIonToast();
  const { t } = useTranslation();

  const openToaster = (type: ToastType, content: string, duration: number = TOAST_DEFAULT_DURATION): void => {
    presentToast({
      message: content,
      duration,
      position: 'top',
      cssClass: `toast__${type}`,
      buttons: [
        {
          text: t('actions.Accept'),
          role: 'cancel',
        },
      ],
    });
  };

  const toasterMemoized: UseToasterReturn = useMemo<UseToasterReturn>(
    (() => ({
      openToaster,
    })),
    [],
  );

  return (
    <ToasterContext.Provider value={toasterMemoized}>
      {children}
    </ToasterContext.Provider>
  );
}

const useToaster = (): UseToasterReturn => {
  const toastHelpers = useContext(ToasterContext);

  return toastHelpers;
};

export { ToasterContextProvider, useToaster };
