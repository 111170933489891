import { t } from 'i18next';
import { useEffect, useState } from 'react';
import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow, IonSkeletonText,
} from '@ionic/react';
import { useFarm } from '../../../../components/FarmContext/FarmContext';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import usePamApi from '../../../../hooks/usePamApi';
import { getDateSinceDays } from '../../../../utils/dates';
import './DailyQualityReportWidget.css';
import QualityReportDataCard from './QualityReportDataCard';
import { DashboardWidgetProps } from '..';
import { formatNumber, formatYearMonthDay } from '../../../../utils/formatters';
import {
  MilkQualityCounters, milkQualityKPIs,
} from '../../../../types/milkQuality';
import { milkQualityCounters, parseQualityData } from '../../../../utils/milkQuality';
import DateRangeSelector from '../../../../components/DateRangeSelector/DateRangeSelector';

function DailyQualityReportWidget({ timestamp }: DashboardWidgetProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const [startDate, setStartDate] = useState<Date>(getDateSinceDays(new Date(), 7));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [milkProduction, setMilkProduction] = useState(0);
  const [milkQuality, setMilkQuality] = useState<MilkQualityCounters>(milkQualityCounters);
  const { selectedFarmId } = useFarm();
  const pamApi = usePamApi('DailyQualityReport');
  const { handleGenericFetchError } = useFriendlyErrors();

  const getQualityReportData = async (farmId: number, targetStartDate: Date, targetEndDate: Date): Promise<void> => {
    setLoaded(false);
    if (!farmId) {
      return;
    }

    let qualityCounters = milkQualityCounters;
    let productionCounter = 0;

    try {
      const apiStartDate = formatYearMonthDay(targetStartDate);
      const apiEndDate = formatYearMonthDay(targetEndDate);
      const response = await pamApi.get(`/farms/${farmId}/daily-quality-report?startDate=${apiStartDate}&endDate=${apiEndDate}`);

      if (response.ok) {
        const { production, quality } = await response.json();

        productionCounter = production;

        if (quality) {
          qualityCounters = parseQualityData(quality);
        }

        setLoaded(true);
      } else {
        handleGenericFetchError();
      }
    } catch (error) {
      handleGenericFetchError();
    }

    setMilkProduction(productionCounter);
    setMilkQuality(qualityCounters);
  };

  useEffect(() => {
    getQualityReportData(selectedFarmId, startDate, endDate);
  }, [selectedFarmId, startDate, endDate, timestamp]);

  const onDatesSelected = (start: Date, end: Date): void => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="dailyQualityReport dashboard-widget" key={timestamp}>
      <h2>{t('dailyQualityReport.Title')}</h2>
      <IonCard className="dailyQualityReport__total">
        <IonCardHeader>
          <IonCardTitle>{t('productionReport.MilkProduction')}</IonCardTitle>
          <DateRangeSelector
            name="dailyQualityReport_dates"
            maxDate={new Date()}
            initialStartDate={startDate}
            initialEndDate={endDate}
            onDatesSelected={onDatesSelected}
          />
        </IonCardHeader>

        <IonCardContent>
          {loaded && (
            <>
              <span className="data-l">{formatNumber(milkProduction, true)}</span>
              {' '}
              <span className="data-s">{t('units.L')}</span>
            </>
          )}
          {!loaded && <IonSkeletonText animated style={{ width: '40%' }} />}
        </IonCardContent>
      </IonCard>

      <IonGrid className="dashboard__grid">
        <IonRow>
          {milkQualityKPIs.map((kpi) => (
            <IonCol key={`milk_kpi_${timestamp}_${kpi}`} size="4">
              <QualityReportDataCard kpi={milkQuality[kpi]} loaded={loaded} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  );
}

export default DailyQualityReportWidget;
