import {
  IonButton, IonIcon,
} from '@ionic/react';
import { t } from 'i18next';
import { callOutline } from 'ionicons/icons';
import { Notification } from '../../types/notifications';

type NotificationAlertActionsProps = {
  notification: Notification;
  dismiss?: () => void;
}

function NotificationAlertActionsActions({
  notification,
  dismiss,
}: NotificationAlertActionsProps): JSX.Element | null {
  const onClick = (): void => {
    if (dismiss) {
      dismiss();
    }
  };

  const renderCTA = (disabled = false): JSX.Element => (
    <IonButton fill="clear" size="default" className="notification__button ion-text-end" disabled={disabled}>
      <IonIcon icon={callOutline} slot="start" />
      {t('notifications.actions.CallVeterinary')}
    </IonButton>
  );

  return (notification.metadata.contact
    ? (
      <a className="button-native" href={`tel:${notification.metadata.contact}`} onClick={onClick}>
        {renderCTA()}
      </a>
    ) : renderCTA(true)
  );
}

export default NotificationAlertActionsActions;
