import { Farm } from '../types/farms';

const APP = '/app';
const LOGIN = '/login';
const NOTIFICATIONS = `${APP}/notifications`;

export const Root = '/';
export const App = APP;
export const Welcome = '/welcome';
export const FarmerOnBoarding = `${APP}/welcome-farmer`;
export const VetOnBoarding = `${APP}/welcome-vet`;
export const Login = LOGIN;
export const LoginRegister = `${LOGIN}/register`;
export const LoginMode = `${LOGIN}/:mode?`;
export const AnimalGroups = `${APP}/animal-groups`;
export const AnimalGroupsWizard = `${APP}/animal-groups-wizard`;
export const MonthlyFarmKPIs = `${APP}/monthly-farm-kpis`;
export const Historical = `${APP}/historical-data`;
export const Targets = `${APP}/targets`;
export const BotanicalFeed = `${APP}/botanical-feed`;
export const ViewBotanicalFeed = `${APP}/botanical-feed/:activeMedicinalBlendId`;
export const Notifications = NOTIFICATIONS;
export const NotificationsType = `${NOTIFICATIONS}/:type?`;
export const BackgroundNotifications = '/bgn/:farmId?/:boxType?/:notificationId?';
export const Settings = `${APP}/settings`;
export const RegisterFarm = `${APP}/register/farm`;
export const RegisterSelectManagementArea = `${APP}/register/select-management-area`;
export const ConfigurationSelectManagementArea = `${APP}/configuration/select-management-area`;
export const MedicalPrescription = `${APP}/medical-prescription/:prescriptionId?`;
export const CreateMedicalPrescription = `${APP}/create-medical-prescription`;
export const FarmConfiguration = `${APP}/farm-configuration`;
export const UpdateFarm = `${APP}/farms/:farmId?/update`;

export const buildMedicalPrescriptionUrl = (prescriptionId: string | number | undefined): string => MedicalPrescription.replace(':prescriptionId?', `${prescriptionId}`);
export const buildUpdateFarmUrl = (farm: Farm): string => UpdateFarm.replace(':farmId?', `${farm.id}`);
export const buildViewBotanicalFeedUrl = (activeMedicinalBlendId: number): string => ViewBotanicalFeed.replace(':activeMedicinalBlendId', `${activeMedicinalBlendId}`);

export default {};
