import { IonButton, IonIcon } from '@ionic/react';
import cn from 'classnames';
import { ReactNode } from 'react';
import './Button.css';

type ButtonProps = {
  id?: string,
  ghost?: boolean;
  className?: string;
  icon?: string;
  block?: boolean;
  disabled?: boolean;
  higher?: boolean;
  onClick?: () => void;
  children: ReactNode;
  submit?: boolean;
};

function Button({
  id,
  ghost,
  className,
  icon,
  block,
  disabled,
  higher,
  onClick,
  children,
  submit,
}: ButtonProps): JSX.Element {
  return (
    <IonButton
      id={id}
      className={cn('button text--uppercase', className, {
        'button--ghost': ghost,
        'button--higher': higher,
      })}
      expand={block ? 'block' : undefined}
      fill={ghost ? 'outline' : 'solid'}
      disabled={disabled}
      onClick={onClick}
      type={submit ? 'submit' : 'button'}
      size="large"
    >
      {icon && <IonIcon slot="start" icon={icon} />}
      {children}
    </IonButton>
  );
}

export default Button;
