import { useTranslation } from 'react-i18next';
import { personSharp, lockClosedSharp, eyedropSharp } from 'ionicons/icons';
import { useMemo, useState } from 'react';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import Note from '../../../../components/Note/Note';
import '../login-steps-shared.css';

interface Credentials {
  lab?: string;
  username?: string;
  password?: string;
}

type LoginCredentialsProps = {
  onNext: () => void;
};

function LoginCredentials({ onNext }: LoginCredentialsProps): JSX.Element {
  const { t } = useTranslation();
  const [credentials, setCredentials] = useState<Credentials>({});

  const canClickNext = useMemo(
    () => credentials.lab && credentials.username && credentials.password,
    [credentials],
  );

  const setLab = (lab: string): void => {
    setCredentials((c) => ({ ...c, lab }));
  };

  const setUsername = (username: string): void => {
    setCredentials((c) => ({ ...c, username }));
  };

  const setPassword = (password: string): void => {
    setCredentials((c) => ({ ...c, password }));
  };

  return (
    <div className="login-step">
      <h1 className="login-step__title">{t('login.credentials.Title')}</h1>
      <Input
        className="login-step__input"
        placeholder={t('login.credentials.Lab')}
        icon={eyedropSharp}
        block
        onChange={setLab}
      />
      <Input
        className="login-step__input"
        placeholder={t('login.credentials.User')}
        icon={personSharp}
        block
        onChange={setUsername}
      />
      <Input
        className="login-step__input"
        placeholder={t('login.credentials.Pass')}
        type="password"
        icon={lockClosedSharp}
        block
        onChange={setPassword}
      />

      <Note>{t('login.credentials.RecoverPass')}</Note>
      <Button
        className="login-step__next"
        disabled={!canClickNext}
        onClick={onNext}
      >
        {t('actions.Next')}
      </Button>
    </div>
  );
}

export default LoginCredentials;
