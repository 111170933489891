import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { cloudDownloadOutline } from 'ionicons/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function InstallPWA(): JSX.Element | null {
  const [supportsPWA, setSupportsPWA] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [promptInstall, setPromptInstall] = useState<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (e: any): void => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const onClick = (): void => {
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <IonItem
      lines="full"
      button
      onClick={onClick}
    >
      <IonIcon slot="start" icon={cloudDownloadOutline} />
      <IonLabel>{t('settings.items.InstallPWA')}</IonLabel>
    </IonItem>
  );
}

export default InstallPWA;
