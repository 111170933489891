import cn from 'classnames';
import { ReactNode } from 'react';

import './Note.css';

type NoteProps = {
  highlight?: boolean;
  center?: boolean;
  uppercase?: boolean;
  className?: string;
  children: ReactNode;
};

function Note({
  highlight,
  center,
  uppercase,
  className,
  children,
}: NoteProps): JSX.Element {
  return (
    <div
      className={cn('note', className, {
        'note--highlight': highlight,
        'note--center': center,
        'note--uppercase': uppercase,
      })}
    >
      {children}
    </div>
  );
}

export default Note;
