import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import './MedicalPrescription.css';
import { useFarm } from '../../components/FarmContext/FarmContext';
import MedicalPrescription from './types/MedicalPrescription';
import ViewMedicalPrescription from './ViewMedicalPrescription';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import Page from '../../layout/Page/Page';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';

export type MedicalPrescriptionRouteOptions = {
  blendId: number,
  blendName: string,
  ts: number,
}

function ViewMedicalPrescriptionPage(): JSX.Element {
  const { prescriptionId } = useParams<{prescriptionId: string}>();
  const { t } = useTranslation();
  const { selectedFarmId } = useFarm();
  const location = useLocation<MedicalPrescriptionRouteOptions>();
  const [prescription, setPrescription] = useState<MedicalPrescription>();
  const [prescriptionReload, setPrescriptionReload] = useState(+new Date());
  const pamApi = usePamApi('MedicalPrescriptionPage');
  const friendlyErrors = useFriendlyErrors();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!selectedFarmId || loading) {
      return;
    }

    const getPrescription = async (farmId: number, id: string): Promise<void> => {
      if (!farmId || !id) {
        return;
      }

      setLoading(true);
      setNotFound(false);

      try {
        setPrescription(undefined);
        const response = await pamApi.get(`/farms/${farmId}/medical-prescriptions/${id}`);

        if (response.ok) {
          const prescriptionResponse = await response.json();
          setPrescription(prescriptionResponse);
        } else if (response.status === 404) {
          setNotFound(true);
        } else {
          friendlyErrors.handleGetPrescriptionError();
        }
      } catch (error) {
        friendlyErrors.handleGetPrescriptionError();
      }
      setLoading(false);
    };

    getPrescription(selectedFarmId, prescriptionId);
  }, [selectedFarmId, prescriptionId, prescriptionReload]);

  const onPrescriptionCreatedOrUpdated = (): void => {
    setPrescriptionReload(+new Date());
  };

  useEffect(() => {
    setPrescriptionReload(location.state?.ts);
  }, [location.state?.ts, selectedFarmId]);

  return (
    <Page title={t('medicalPrescription.Title')} showBackButton disableFarmSelector>
      <h2 className="medicalPrescription__title">{t('medicalPrescription.Title')}</h2>

      {notFound && <ErrorMessage>{t('medicalPrescription.errors.NotFound')}</ErrorMessage>}

      {notFound !== true && (
        <ViewMedicalPrescription
          prescription={prescription}
          selectedFarmId={selectedFarmId}
          onPrescriptionUpdated={onPrescriptionCreatedOrUpdated}
        />
      )}

    </Page>
  );
}

export default ViewMedicalPrescriptionPage;
