import {
  IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRow,
} from '@ionic/react';
import { ReactNode } from 'react';
import WizardImage, { WizardImages } from '../../components/WizardImage/WizardImage';
import './WizardPage.css';

type WizardPageProps = {
  img: WizardImages;
  children: ReactNode;
  footer: ReactNode;
};

function WizardPage({ img, children, footer }: WizardPageProps): JSX.Element {
  return (
    <IonPage className="wizardPage">
      <IonContent>
        <IonGrid>
          <IonRow className="ion-justify-content-center wizardPage__img">
            <IonCol size="12" sizeMd="8" sizeLg="6">
              <WizardImage img={img} />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="8" sizeLg="6" className="wizardPage__content">
              {children}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter mode="md">
        {footer}
      </IonFooter>
    </IonPage>
  );
}

export default WizardPage;
