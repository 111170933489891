import { TextFieldTypes } from '@ionic/core';
import {
  IonInput, IonItem, IonLabel, IonText,
} from '@ionic/react';
import { forwardRef, Ref } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import './Input.css';

export type BaseInputProps = UseFormRegisterReturn & {
  label: string
  placeholder: string
  error?: FieldError
  hidden?: boolean
}

type InputForReactHookFormsProps = BaseInputProps & {
  type?: TextFieldTypes
  step?: string
}

function InputForReactHookForm({
  hidden, label, error, step, onBlur, onChange, ...props
}:InputForReactHookFormsProps, ref: Ref<HTMLIonInputElement>): JSX.Element {
  return (
    <IonItem className="pam-input" hidden={hidden}>
      <IonLabel color="primary" position="stacked">{label}</IonLabel>
      <IonInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        step={step}
        aria-invalid={error ? 'true' : 'false'}
        onIonChange={onChange}
        onIonBlur={onBlur}
      />
      {error && (
        <IonText color="danger" className="text-s">{error.message}</IonText>
      )}
    </IonItem>
  );
}

export default forwardRef(InputForReactHookForm);
