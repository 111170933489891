import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './LoginOptions.css';

type LoginOptionsProps = {
  onLogin: () => void;
};

function LoginOptions({ onLogin }: LoginOptionsProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="login-options">
      <IonButton expand="block" size="large" color="primary" onClick={onLogin} className="login-options__button">
        {t('login.Enter')}
      </IonButton>
    </div>
  );
}

export default LoginOptions;
