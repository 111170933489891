/* eslint-disable no-console */
export interface Logger {
  log: (message?: unknown, ...optionalParams: unknown[]) => void
  error: (message?: unknown, ...optionalParams: unknown[]) => void,
  warn: (message?: unknown, ...optionalParams: unknown[]) => void,
  debug: (message?: unknown, ...optionalParams: unknown[]) => void,
}

function useLogger():Logger {
  return ({
    log: (...props) => {
      if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === 'false') {
        return;
      }
      console.log(...props);
    },
    error: (...props) => console.error(...props),
    warn: (...props) => console.warn(...props),
    debug: (...props) => {
      if (!process.env.REACT_APP_DEBUG || process.env.REACT_APP_DEBUG === 'false') {
        return;
      }
      console.debug(...props);
    },
  });
}

export default useLogger;
