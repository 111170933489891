import { IonBadge } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import MedicalPrescription from '../MedicalPrescription/types/MedicalPrescription';
import './PrescriptionStatusBadge.css';

export interface PrescriptionStatusBadgeProps {
  prescription: MedicalPrescription
  slot?: string
}

function PrescriptionStatusBadge({ prescription, slot }: PrescriptionStatusBadgeProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <IonBadge slot={slot} className={`prescriptionStatus prescriptionStatus__${prescription.status?.toLowerCase()}`}>
      {t(`medicalPrescriptionCard.status.${prescription.status}`)}
    </IonBadge>
  );
}

export default PrescriptionStatusBadge;
