import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  InputText, InputPassword, InputSelect, InputSelectOption,
} from '../../components/Input';
import { Laboratory } from '../../types/Laboratories';
import { Farm } from '../../types/farms';
import usePamApi from '../../hooks/usePamApi';
import Button from '../../components/Button/Button';
import { useFarm } from '../../components/FarmContext/FarmContext';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import { useToaster } from '../../components/Toaster/ToasterContext';
import { FarmConfiguration } from '../../routing/routes';

export interface UpdateLaboratoryFormProps {
  farm: Farm
}

const TOAST_DURATION = 1000;

function UpdateLaboratoryForm({ farm }: UpdateLaboratoryFormProps): JSX.Element {
  const { t } = useTranslation();
  const [laboratories, setLaboratories] = useState<Laboratory[]>([]);
  const [hasFarmerCode, setHasFarmerCode] = useState(false);
  const pamApi = usePamApi('UpdateFarmLab');
  const { reload: reloadFarmContext } = useFarm();
  const [isBusy, setIsBusy] = useState(false);
  const friendlyErrors = useFriendlyErrors();
  const { openToaster } = useToaster();
  const history = useHistory();

  const schema = yup.object({
    labName: yup.string()
      .required(t('forms.errors.Required')),
    labUsername: yup.string()
      .required(t('forms.errors.Required')),
    labPassword: yup.string()
      .required(t('forms.errors.Required')),
    farmerCode: yup.string()
      .test('farmerCode', t('forms.errors.Required'), (value) => {
        if (!hasFarmerCode) return true;
        return !!value;
      }),
  });

  const {
    register, formState: { errors, isDirty, isValid }, handleSubmit, getValues, reset, trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      labName: farm.labName,
      labUsername: farm.labUsername,
      labPassword: '',
      farmerCode: farm.farmerCode,
    },
  });

  const updateForm = ():void => {
    const selectedLabId = getValues('labName');
    const requiresFarmerCode = laboratories?.find((lab) => `${lab.acronym}` === `${selectedLabId}`)?.requiresFarmerCode || false;
    setHasFarmerCode(requiresFarmerCode);
  };

  useEffect(() => {
    const getLaboratories = async (): Promise<void> => {
      const response = await pamApi.get('/laboratories');
      if (response.ok) {
        const data = await response.json();
        setLaboratories(data.laboratories);
      }
    };

    getLaboratories();
  }, []);

  const labNameRegister = register('labName');

  useEffect(() => {
    reset({
      labName: farm.labName, labUsername: farm.labUsername, labPassword: '', farmerCode: farm.farmerCode,
    });
    trigger();
  }, [farm]);

  useEffect(() => {
    updateForm();
  }, [laboratories]);

  const onUpdateFarm = handleSubmit((values) => {
    const laboratory = laboratories.find((lab) => lab.acronym === values.labName);
    if (!laboratory) {
      return;
    }
    const payload = {
      labInfo: {
        labId: laboratory.id,
        labName: values.labName,
        labUsername: values.labUsername,
        labPassword: values.labPassword,
        labFarmerCode: laboratory.requiresFarmerCode ? values.farmerCode : undefined,
      },
    };

    setIsBusy(true);
    pamApi.put(`/farms/${farm.id}`, payload)
      .then((response) => {
        if (response.ok) {
          openToaster('success', t('forms.messages.UpdatedOk'), TOAST_DURATION);
          reloadFarmContext();
          history.replace(FarmConfiguration);
        } else {
          friendlyErrors.handleUpdateFailedError();
        }
      })
      .catch(() => {
        friendlyErrors.handleUpdateFailedError();
      })
      .finally(() => setIsBusy(false));
  });

  return (
    <div className="ion-padding-top">
      <h2>{t('register.farm.SubtitleLab')}</h2>
      {!!laboratories?.length && (
        <form onSubmit={onUpdateFarm}>
          <InputSelect
            // eslint-disable-next-line react/jsx-props-no-spreading
            label={t('register.farm.field.LabName')}
            placeholder={t('register.farm.field.LabName')}
            error={errors.labName}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...labNameRegister}
            onChange={async (ev) => {
              labNameRegister.onChange(ev);
              updateForm();
            }}
          >
            {laboratories.map((lab) => <InputSelectOption label={lab.acronym} value={lab.acronym} key={`lab-${lab.acronym}`} />)}
          </InputSelect>
          <InputText
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('labUsername')}
            label={t('register.farm.field.LabUsername')}
            placeholder={t('register.farm.field.LabUsername')}
            error={errors.labUsername}
          />
          <InputPassword
            label={t('register.farm.field.LabPassword')}
            placeholder={t('register.farm.field.LabPassword')}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('labPassword')}
            error={isDirty ? errors.labPassword : undefined}
          />
          {hasFarmerCode && (
            <InputText
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('farmerCode')}
              label={t('register.farm.field.FarmerCode')}
              placeholder={t('register.farm.field.FarmerCode')}
              error={errors.farmerCode}
            />
          )}
          <Button
            higher
            block
            submit
            disabled={!isDirty || !isValid || isBusy}
            className="mt--2"
          >
            {t('register.farm.actions.Update')}
          </Button>
        </form>
      )}
    </div>
  );
}

export default UpdateLaboratoryForm;
