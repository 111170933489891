import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useIonViewWillEnter } from '@ionic/react';
import {
  AddProductionWidget,
  DailyQualityReportWidget, EarningsHistoryWidget, ProductionReportWidget, ProductionTargetsWidget,
} from './widgets';
import Page from '../../layout/Page/Page';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';
import './Dashboard.css';

function Dashboard(): JSX.Element {
  const { t } = useTranslation();
  const identity = useIdentity();
  const [isVeterinary, setIsVeterinary] = useState(false);
  const [ts, setTs] = useState(1);

  useEffect(() => {
    setIsVeterinary(identity.user?.type === UserType.Veterinary);
  }, [identity.user?.type]);

  const forceRefresh = (): void => setTs(+new Date());

  useIonViewWillEnter(forceRefresh);

  return (
    <Page title={t('dashboard.Title')} refresher={forceRefresh}>
      <div className="dashboard-container">
        <AddProductionWidget isVeterinary={isVeterinary} timestamp={ts} onProductionUpdated={forceRefresh} />
        <DailyQualityReportWidget isVeterinary={isVeterinary} timestamp={ts} />
        {!isVeterinary && <EarningsHistoryWidget isVeterinary={isVeterinary} timestamp={ts} />}
        {isVeterinary && <ProductionReportWidget timestamp={ts} />}
        <ProductionTargetsWidget isVeterinary={isVeterinary} timestamp={ts} />
      </div>
    </Page>
  );
}
export default Dashboard;
