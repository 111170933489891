const setItem = (key: string, value: unknown): void => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const getItem = <T>(key: string, deleteAfter?: boolean): T | null => {
  if (localStorage) {
    const value = localStorage.getItem(key);
    const returnValue: T = value ? JSON.parse(value) : value;

    if (deleteAfter) {
      localStorage.removeItem(key);
    }

    return returnValue;
  }

  return null;
};

const removeItem = (key: string): void => {
  if (localStorage) {
    localStorage.removeItem(key);
  }
};

export const storage = {
  set: setItem,
  get: getItem,
  remove: removeItem,
};
