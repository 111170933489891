import './DateSelector.css';
import {
  IonButton,
  IonButtons,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonItem,
  IonModal,
} from '@ionic/react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { caretDownOutline } from 'ionicons/icons';

interface DateSelectorOptions {
  onDateSelected: (date: Date) => void
  minDate?: Date
  maxDate: Date
  name?: string
  title?: string
  value: Date
  type?: 'select' | 'badge'
}

function DateSelector({
  onDateSelected, minDate, maxDate, name, value, title, type = 'select',
}: DateSelectorOptions): JSX.Element {
  const { t, i18n } = useTranslation();
  const datetimeButtonRef = useRef<HTMLIonDatetimeButtonElement>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const selector = useRef<HTMLIonDatetimeElement>(null);
  const [currentDate, setCurrentDate] = useState<Date>(value);

  const confirmDateHandler = useCallback(async () => {
    modal.current?.dismiss();
    await selector.current?.confirm();
    const selectedDate = new Date(selector.current?.value as string);
    setCurrentDate(selectedDate);
    if (selectedDate) {
      onDateSelected(selectedDate);
    }
  }, [modal, selector, onDateSelected]);

  return (
    <>
      <IonDatetimeButton
        datetime={`datetime-${name}`}
        color="default"
        ref={datetimeButtonRef}
        mode="md"
        className={`dateSelectorButton-dateselector dateSelectorButton-dateselector__${type}`}
      >
        {type === 'select' && (
          <IonItem slot="date-target">
            <div className="dateSelectorButton-dateselector__date">
              {
                currentDate.toLocaleDateString(i18n.language, {
                  day: 'numeric',
                  month: 'short',
                })
              }
            </div>
            <IonIcon icon={caretDownOutline} slot="end" />
          </IonItem>
        )}

        {type === 'badge' && (
          <div slot="date-target" className="dateSelectorButton-dateselector__badge">
            {
              currentDate.toLocaleDateString(i18n.language, {
                day: 'numeric',
                month: 'short',
              })
            }
          </div>
        )}
      </IonDatetimeButton>

      <IonModal keepContentsMounted ref={modal} className="dateSelector__modal">
        <IonDatetime
          id={`datetime-${name}`}
          firstDayOfWeek={1}
          presentation="date"
          size="cover"
          ref={selector}
          max={maxDate.toISOString()}
          min={minDate?.toISOString() || undefined}
          locale={i18n.language}
          value={value.toISOString()}
          showDefaultTitle
          showDefaultButtons
        >
          <span slot="title">{title}</span>
          <IonButtons slot="buttons">
            <IonButton
              color="primary"
              onClick={confirmDateHandler}
            >
              {t('actions.Accept')}
            </IonButton>
          </IonButtons>
        </IonDatetime>
      </IonModal>
    </>
  );
}

export default DateSelector;
