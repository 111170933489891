const pushNotificationsAreSupported = 'Notification' in window && !!navigator.serviceWorker;

interface PushNotification {
  type: 'warning' | 'alert' | 'info';
  title: string;
  body: string;
}

export const showPushNotification = ({ title, body, type }: PushNotification): void => {
  if (!pushNotificationsAreSupported) {
    return;
  }
  if (Notification.permission === 'granted') {
    navigator.serviceWorker.getRegistration().then((reg) => {
      if (!reg) {
        return;
      }
      const options: NotificationOptions = {
        body,
        icon: `assets/notifications/${type}.png`,
        vibrate: [10],
        data: {
          dateOfArrival: Date.now(),
          primaryKey: 1,
        },
      };
      reg.showNotification(title, options);
    });
  }
};

export const requestPushNotificationPermissionAsync = (): Promise<NotificationPermission> => {
  if (!pushNotificationsAreSupported) {
    return Promise.resolve('default');
  }

  return Notification.requestPermission();
};
