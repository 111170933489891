import cn from 'classnames';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import './BarChart.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { mergeDeep } from '../../../utils/objects';

export type BarChartOptions = ChartOptions<'bar'>;

export type BarChartDataset = { id?: string, label?: string; color?: string; data: number[], yAxisID?: string }[]

export type BarChartProps = {
  className?: string;
  labels: string[];
  dataset: BarChartDataset;
  options?: BarChartOptions;
};

export const defaultOptions: ChartOptions<'bar'> = {
  locale: 'es',
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      position: 'left',
      grid: {
        color: '#CED9D4',
      },
    },
    x: {
      grid: {
        color: 'transparent',
      },
      ticks: {
        color: '#919995',
      },
    },
  },
};

function BarChart({
  labels,
  dataset,
  options = {},
  className,
}: BarChartProps): JSX.Element {
  const { i18n } = useTranslation();
  const [chartData, setChartData] = useState<ChartData<'bar'>>({ datasets: [] });
  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({});

  useEffect(() => {
    const newData = {
      labels,
      datasets: dataset.map((d) => ({
        yAxisID: d.yAxisID,
        label: d.label || undefined,
        data: d.data,
        borderColor: d.color || '#1089B0',
        backgroundColor: d.color || '#1089B0',
        pointBackgroundColor: 'transparent',
        pointBorderWidth: 0,
        barPercentage: 1,
        categoryPercentage: 0.75,
      })),
    };
    setChartData(newData);
  }, [dataset]);

  useEffect(() => {
    const newOptions = {
      ...mergeDeep<ChartOptions<'bar'>>(defaultOptions, options),
      locale: i18n.language,
    };
    setChartOptions(newOptions);
  }, [options]);

  return (
    <div className={cn('line-chart', className)}>
      {(dataset.length && dataset[0]?.data?.length && (
        <Chart
          type="bar"
          data={chartData}
          options={chartOptions}
        />
      ))}
    </div>
  );
}

export default BarChart;
