import { useCallback, useState } from 'react';
import { NumberStringDictionary } from '../../types/common';
import FarmTargetCardDisplay from './FarmTargetCardDisplay';
import FarmTargetCardEdit from './FarmTargetCardEdit';

export interface FarmTarget {
  id: number,
  paramName: string,
  value: number,
  range: number,
  measurementTypeId?: number;
}

type FarmTargetCardProps = {
  target: FarmTarget
  alterAllowed?: boolean,
  farmId: number,
  onTargetUpdated?: () => void,
  onTargetUpdateCancelled?: () => void,
  measurementTypes: NumberStringDictionary
};

function FarmTargetCard({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  target, alterAllowed = false, farmId, onTargetUpdated = () => {}, onTargetUpdateCancelled = () => {}, measurementTypes,
}: FarmTargetCardProps): JSX.Element {
  const [editing, setEditing] = useState(target.id === 0);

  const editTarget = useCallback(() => setEditing(true), []);
  const deleteTarget = useCallback(() => {
    setEditing(false);
    onTargetUpdated();
  }, [onTargetUpdated]);
  const cancel = useCallback(() => {
    setEditing(false);
    onTargetUpdateCancelled();
  }, [onTargetUpdateCancelled]);
  const accept = useCallback(() => {
    setEditing(false);
    onTargetUpdated();
  }, [onTargetUpdated]);

  return editing
    ? <FarmTargetCardEdit target={target} farmId={farmId} onAccepted={accept} onCancelled={cancel} measurementTypes={measurementTypes} />
    : <FarmTargetCardDisplay target={target} alterAllowed={alterAllowed} farmId={farmId} onEdit={editTarget} onDelete={deleteTarget} />;
}

export default FarmTargetCard;
