import {
  createContext, useReducer, useContext, useMemo, Dispatch, ReactNode,
} from 'react';
import { createPortal } from 'react-dom';
import { Notification } from '../../types/notifications';
import { useIdentity, UserType } from '../Identity/IdentityContext';
import InAppNotifications from '../InAppNotifications/InAppNotifications';

const MODE = 'multiple'; // multiple | modal

export const inAppNotificationType = {
  ALERT: 'Alert',
  RECOMMENDATION: 'Recommendation',
  PRESCRIPTION: 'Prescription',
};

export type InAppNotificationType = typeof inAppNotificationType[keyof typeof inAppNotificationType];

export enum InAppNotificationActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  REMOVE_ALL = 'REMOVE_ALL',
}

type InAppNotificationState = Notification[];

type InAppNotificationContextType = {
  inAppNotifications: Notification[];
  inAppNotificationDispatch: Dispatch<InAppNotificationReducerAction> | (() => void);
}

export const InAppNotificationContext = createContext<InAppNotificationContextType>({
  inAppNotifications: [],
  inAppNotificationDispatch: () => null,
});

type InAppNotificationReducerAction =
  | {
    type: InAppNotificationActionType.ADD;
    payload: Notification;
  }
  | {
    type: InAppNotificationActionType.REMOVE;
    payload: number;
  }
  | {
    type: InAppNotificationActionType.REMOVE_ALL;
  };

const initialState: InAppNotificationState = [];

export const InAppNotificationReducer = (state: InAppNotificationState, action: InAppNotificationReducerAction): InAppNotificationState => {
  switch (action.type) {
    case InAppNotificationActionType.ADD:
      return [
        ...state,
        action.payload,
      ];
    case InAppNotificationActionType.REMOVE:
      return state.filter((item) => item.id !== action.payload);
    case InAppNotificationActionType.REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};

type InAppNotificationContextProviderProps = {
  children: ReactNode;
}

export function InAppNotificationContextProvider({ children }: InAppNotificationContextProviderProps): JSX.Element {
  const identity = useIdentity();
  const [inAppNotifications, inAppNotificationDispatch] = useReducer(InAppNotificationReducer, initialState);
  const notificationsState = useMemo(() => ({ inAppNotifications, inAppNotificationDispatch }), [inAppNotifications, inAppNotificationDispatch]);
  const allowActions = identity.user?.type === UserType.Farmer;

  return (
    <InAppNotificationContext.Provider value={notificationsState}>
      {createPortal(<InAppNotifications mode={MODE} notifications={inAppNotifications} allowActions={allowActions} />, document.body)}
      {children}
    </InAppNotificationContext.Provider>
  );
}

export const useInAppNotification = (): InAppNotificationContextType => useContext(InAppNotificationContext);
