import { IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import {
  IonCard,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonToggle,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from '../../../components/FarmContext/FarmContext';
import FeedbackModal from '../../../components/FeedbackModal/FeedbackModal';
import { useIdentity } from '../../../components/Identity/IdentityContext';
import { useToaster } from '../../../components/Toaster/ToasterContext';
import useFriendlyErrors from '../../../hooks/useFriendlyErrors';
import usePamApi from '../../../hooks/usePamApi';

import './AssignManagementAreas.css';

type AssignableFarm = {
  id: number;
  name: string;
  isAvailable: boolean;
  labName: string;
  farmCode: string;
  disabled?: boolean;
}

const TOAST_DURATION = 1000;

function AssignManagementAreas(): JSX.Element | null {
  const { t } = useTranslation();
  const { openToaster } = useToaster();
  const { reloadAndSelect } = useFarm();
  const [availableFarms, setAvailableFarms] = useState<AssignableFarm[]>([]);
  const { user } = useIdentity();
  const pamApi = usePamApi('AssignManagementAreas');
  const friendlyErrors = useFriendlyErrors();
  const [requireConfirmationForFarmId, setRequireConfirmationForFarmId] = useState(0);

  const requestAvailableFarms = async (): Promise<void> => {
    const userId = user?.id ?? 0;

    if (!userId) {
      return;
    }

    try {
      const response = await pamApi.get(`/appusers/${user?.id}/available-farms`);
      const responseFarms: AssignableFarm[] = response.ok ? await response.json() : [];
      setAvailableFarms(responseFarms);
    } catch (error) {
      setAvailableFarms([]);
    }
  };

  useEffect(() => {
    requestAvailableFarms();
  }, [user]);

  const updateAvailableFarms = (farmId: number, isAvailable: boolean, disabled = false): void => {
    setAvailableFarms((farms) => farms.map((farm) => (farm.id === +farmId
      ? { ...farm, isAvailable, disabled }
      : farm)));
  };

  const manageAssignment = async (farmId: number, assign: boolean): Promise<void> => {
    const response = await pamApi.patch(`/farms/${farmId}/assign/${assign}`, {});

    updateAvailableFarms(farmId, response.ok && !assign, !response.ok);

    if (response.ok) {
      const message = t(`assignManagementAreas.${assign ? 'FarmSuccessfullyAssigned' : 'FarmSuccessfullyDisassigned'}`);

      reloadAndSelect(assign ? +farmId : 0);
      openToaster('success', message, TOAST_DURATION);
    } else {
      friendlyErrors.handleAssignFarmError();
    }
  };

  const onSelectManagementArea = async (event: IonToggleCustomEvent<ToggleChangeEventDetail<undefined>>): Promise<void> => {
    const { value: farmId = 0, checked } = event.detail;

    if (checked) {
      if (requireConfirmationForFarmId) {
        return;
      }

      await manageAssignment(farmId, checked);
    } else {
      updateAvailableFarms(farmId, true);
      setRequireConfirmationForFarmId(farmId);
    }
  };

  const onContinue = (): Promise<void> => manageAssignment(requireConfirmationForFarmId, false);

  const onCancel = (): void => updateAvailableFarms(requireConfirmationForFarmId, false);

  const onDismiss = (): void => setRequireConfirmationForFarmId(0);

  return (
    <div>
      {!!availableFarms?.length && (
        <IonList>
          {availableFarms?.map((farm) => (
            <IonCard key={farm.id} className="management-area__card">
              <IonItem lines="full" className="card__header">
                <IonLabel class="ion-text-wrap">{farm.name}</IonLabel>
                <IonToggle
                  slot="end"
                  color="primary"
                  mode="ios"
                  disabled={farm.disabled}
                  checked={!farm.isAvailable}
                  value={farm.id.toString()}
                  onIonChange={onSelectManagementArea}
                />
              </IonItem>

              <IonList>
                <IonItem lines="none" className="card__row">
                  <IonLabel slot="start" class="ion-text-wrap">{t('assignManagementAreas.fields.LabName')}</IonLabel>
                  <IonText slot="end" class="ion-text-end ion-text-wrap">
                    {farm.labName}
                  </IonText>
                </IonItem>

                {farm.farmCode && (
                  <IonItem lines="none" className="card__row">
                    <IonLabel slot="start" class="ion-text-wrap">{t('assignManagementAreas.fields.FarmCode')}</IonLabel>
                    <IonText slot="end" class="ion-text-end ion-text-wrap">
                      {farm.farmCode}
                    </IonText>
                  </IonItem>
                )}
              </IonList>
            </IonCard>
          ))}
        </IonList>
      )}

      <FeedbackModal
        isOpen={!!requireConfirmationForFarmId}
        feedbackType="warning"
        cancel
        title={t('assignManagementAreas.confirmationModal.Title')}
        text={t('assignManagementAreas.confirmationModal.Text')}
        mainCTACaption={t('actions.Accept')}
        onDismiss={onDismiss}
        onSecondaryCTAClick={onCancel}
        onMainCTAClick={onContinue}
      />
    </div>
  );
}

export default AssignManagementAreas;
