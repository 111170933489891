import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonSkeletonText,
} from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../../components/Charts/Bar/BarChart';
import DataTable from '../../../../components/DataTable/DataTable';
import { useFarm } from '../../../../components/FarmContext/FarmContext';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import usePamApi from '../../../../hooks/usePamApi';
import { daysInMonth } from '../../../../utils/dates';
import { formatDate, formatNumber, formatYearMonthDay } from '../../../../utils/formatters';

interface DailyProductionMeasurement {
  date: Date;
  value: number;
}

interface MonthlyFarmReportResponse {
  values: DailyProductionMeasurement[];
}

type MonthlyFarmReportMilkProductionChartProps = {
  selectedDate: Date;
};

const productionsUnit = 'L';

function MonthlyFarmReportMilkProductionChart({ selectedDate }: MonthlyFarmReportMilkProductionChartProps): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const pamApi = usePamApi('MonthlyFarmReportMilkProductionChart');
  const { selectedFarmId } = useFarm();
  const { t } = useTranslation();
  const [productionData, setProductionData] = useState<number[]>([]);
  const [milkProduction, setMilkProduction] = useState(0);
  const [isErrorState, setIsErrorState] = useState(false);
  const { handleGenericFetchError } = useFriendlyErrors();

  useEffect(
    () => {
      (async (farmId: number): Promise<void> => {
        setLoaded(false);
        if (!farmId || !selectedDate) {
          return;
        }

        try {
          const startDate = formatYearMonthDay(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1));
          const endDate = formatYearMonthDay(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0));
          const response = await pamApi.get(
            `/farms/${farmId}/milk-production?startDate=${startDate}&endDate=${endDate}`,
          );

          if (response.ok) {
            setIsErrorState(false);
            const { values }: MonthlyFarmReportResponse = await response.json();

            const sum = values.reduce((partialSum, a) => partialSum + a.value, 0);
            setMilkProduction(sum);

            setProductionData(values.map((value) => value.value));
            setLoaded(true);
          } else {
            setIsErrorState(true);
            handleGenericFetchError();
          }
        } catch (error) {
          setIsErrorState(true);
          handleGenericFetchError();
        }
      })(selectedFarmId);
    },
    [selectedFarmId, selectedDate],
  );

  const labels = useMemo(() => {
    if (!selectedDate) {
      return [];
    }
    const days = daysInMonth(selectedDate);
    return Array(days)
      .fill(0)
      .map((_x, y) => formatDate(
        new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, -y),
        '2-digit',
        'short',
        undefined,
      ))
      .reverse();
  }, [selectedDate]);

  return (
    <>
      <IonCard>
        <IonCardContent>
          <BarChart
            labels={labels}
            dataset={[
              {
                data: isErrorState ? [] : productionData,
              },
            ]}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  position: 'left',
                  ticks: {
                    color: '#1089B0',
                    callback: (value) => (`${value} ${productionsUnit}`),
                  },
                  grid: {
                    color: '#CED9D4',
                  },
                },
              },
            }}
          />
          <DataTable
            headers={[
              t('productionReportWidget.tableLabels.Date'),
              t('productionReportWidget.tableLabels.Amount'),
            ]}
            data={
              labels.map((label, idx) => ([
                label,
                productionData[idx] !== null ? `${formatNumber(productionData[idx], false, 2)} ${productionsUnit}` : '-',
              ]))
            }
          />
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{t('productionReport.MilkProduction')}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          { loaded && (
            <>
              <span className="data-l">{formatNumber(milkProduction, true)}</span>
              <span className="data-s">
                {` ${t('units.L')}`}
              </span>
            </>
          )}
          {!loaded && <IonSkeletonText animated style={{ width: '40%' }} /> }
        </IonCardContent>
      </IonCard>
    </>
  );
}

export default MonthlyFarmReportMilkProductionChart;
