import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { informationCircleOutline } from 'ionicons/icons';
import { IonSkeletonText } from '@ionic/react';
import Button from '../../components/Button/Button';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import usePamApi from '../../hooks/usePamApi';
import { formatDate } from '../../utils/formatters';
import MedicalPrescription from './types/MedicalPrescription';
import './ViewMedicalPrescription.css';
import FeedTotal from './FeedTotal';
import { MedicalPrescriptionStatus, REMOVE_BLEND_ID } from '../../types/MedicalPrescription';
import PrescriptionStatusBadge from '../BotanicalFeed/PrescriptionStatusBadge';
import useAnimalGroup from '../../hooks/useAnimalGroup';
import InfoBox from '../../components/InfoBox/InfoBox';

interface ViewMedicalPrescriptionProps {
  prescription: MedicalPrescription | undefined,
  selectedFarmId: number,
  onPrescriptionUpdated: () => void
}

function ViewMedicalPrescription({
  prescription, selectedFarmId, onPrescriptionUpdated,
}: ViewMedicalPrescriptionProps): JSX.Element {
  const { t } = useTranslation();
  const identity = useIdentity();
  const [isFarmer, setIsFarmer] = useState(false);
  const pamApi = usePamApi('ViewMedicalPrescription');
  const friendlyErrors = useFriendlyErrors();
  const [prescriptionCurrentStatus, setPrescriptionCurrentStatus] = useState<MedicalPrescriptionStatus>();
  const { animalGroupName } = useAnimalGroup();
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const updateMedicalPrescriptionState = async (status: MedicalPrescriptionStatus): Promise<void> => {
    if (!prescription) {
      return;
    }

    try {
      setPrescriptionCurrentStatus(undefined);
      const response = await pamApi.patch(
        `/farms/${selectedFarmId}/medical-prescription/${prescription.id}`,
        {
          status: Number(status),
        },
      );
      if (response.ok) {
        onPrescriptionUpdated();
        setPrescriptionCurrentStatus(status);
      } else {
        friendlyErrors.handleUpdateMedicalPrescriptionError();
      }
    } catch (error) {
      friendlyErrors.handleUpdateMedicalPrescriptionError();
    }
  };

  const acceptPrescription = async (): Promise<void> => {
    await updateMedicalPrescriptionState(MedicalPrescriptionStatus.Accepted);
  };

  const cancelPrescription = async (): Promise<void> => {
    await updateMedicalPrescriptionState(MedicalPrescriptionStatus.Rejected);
  };

  const updateConfirmed = (): void => {
    setPrescriptionCurrentStatus(undefined);
  };

  useEffect(() => {
    setIsFarmer(identity.user?.type === UserType.Farmer);
  }, [identity.user?.type]);

  return (
    <div>
      <table className="medicalPrescription__data">
        <tbody>
          <tr>
            <td className="label">
              {`${t('medicalPrescription.Status')}:`}
            </td>
            <td className="value">
              {prescription && <PrescriptionStatusBadge prescription={prescription} />}
              {!prescription && <IonSkeletonText animated />}
            </td>
          </tr>
          <tr>
            <td className="label">
              {`${t('medicalPrescription.PrescriptionNumber')}:`}
            </td>
            <td className="value">
              {prescription && prescription.prescriptionId}
              {!prescription && <IonSkeletonText animated />}
            </td>
          </tr>
          <tr>
            <td className="label">
              {`${t('medicalPrescription.Batch')}:`}
            </td>
            <td className="value">
              {prescription && animalGroupName(prescription.loteId)}
              {!prescription && <IonSkeletonText animated />}
            </td>
          </tr>
          <tr>
            <td className="label">
              {`${t('medicalPrescription.CreationDate')}:`}
            </td>
            <td className="value">
              {prescription && formatDate(new Date(prescription.creationDate), '2-digit', '2-digit', 'numeric')}
              {!prescription && <IonSkeletonText animated />}
            </td>
          </tr>
          { prescription && prescription?.medicinalBlendId === REMOVE_BLEND_ID && (
            <tr>
              <td className="label">
                {t('medicalPrescription.field.Feed')}
              </td>
              <td className="value">
                {prescription && t('botanicalFeed.RemoveActualBlend')}
                {!prescription && <IonSkeletonText animated />}
              </td>
            </tr>
          )}
          { prescription?.medicinalBlendId !== REMOVE_BLEND_ID && (
            <>
              <tr>
                <td className="label">
                  {t('medicalPrescription.field.Feed')}
                </td>
                <td className="value">
                  {prescription && prescription?.medicinalBlendName}
                  {!prescription && <IonSkeletonText animated />}
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('medicalPrescription.field.Dose')}
                </td>
                <td className="value">
                  {prescription && `${prescription.dose} ${t('medicalPrescription.field.DoseUnit')}`}
                  {!prescription && <IonSkeletonText animated />}
                </td>
              </tr>
              <tr>
                <td className="label">
                  {t('medicalPrescription.field.Duration')}
                </td>
                <td className="value">
                  {prescription && `${prescription.duration} ${t('medicalPrescription.field.DurationUnit')}`}
                  {!prescription && <IonSkeletonText animated />}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      {prescription && <FeedTotal amount={prescription.amount} />}
      {isFarmer && prescription && (
        <>
          <InfoBox icon={informationCircleOutline}>{t('medicalPrescription.acceptModal.Text')}</InfoBox>
          <Button
            id="accept-button"
            className="medicalPrescription__next"
            onClick={() => setShowAcceptModal(true)}
            disabled={prescription.status !== MedicalPrescriptionStatus[MedicalPrescriptionStatus.Created]}
            block
          >
            {t('medicalPrescription.actions.AcceptPrescription')}
          </Button>
          <Button
            id="reject-button"
            className="medicalPrescription__next"
            onClick={cancelPrescription}
            disabled={prescription.status !== MedicalPrescriptionStatus[MedicalPrescriptionStatus.Created]}
            block
            ghost
          >
            {t('medicalPrescription.actions.RejectPrescription')}
          </Button>

          <FeedbackModal
            isOpen={showAcceptModal}
            feedbackType="warning"
            cancel
            title={t('medicalPrescription.acceptModal.Title')}
            text={t('medicalPrescription.acceptModal.Text')}
            mainCTACaption={t('actions.Accept')}
            onDismiss={() => setShowAcceptModal(false)}
            onMainCTAClick={acceptPrescription}
          />

          <FeedbackModal
            isOpen={prescriptionCurrentStatus === MedicalPrescriptionStatus.Accepted}
            feedbackType="positive"
            title={t('medicalPrescription.actions.accepted.Title')}
            text={t('medicalPrescription.actions.accepted.Text')}
            onDismiss={updateConfirmed}
          />

          <FeedbackModal
            isOpen={prescriptionCurrentStatus === MedicalPrescriptionStatus.Rejected}
            feedbackType="negative"
            title={t('medicalPrescription.actions.rejected.Title')}
            text={t('medicalPrescription.actions.rejected.Text')}
            onDismiss={updateConfirmed}
          />
        </>
      )}
    </div>
  );
}

export default ViewMedicalPrescription;
