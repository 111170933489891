import { t } from 'i18next';
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonText,
} from '@ionic/react';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import { FarmTarget } from './FarmTargetCard';

import './FarmTargetCardDisplay.css';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';

type FarmTargetCardDisplayProps = {
  target: FarmTarget,
  alterAllowed: boolean,
  farmId: number,
  onEdit: () => void,
  onDelete: () => void
}

function FarmTargetCardDisplay({
  target, alterAllowed, farmId, onEdit, onDelete,
}: FarmTargetCardDisplayProps): JSX.Element {
  const pamApi = usePamApi('FarmTargetCardDisplay');
  const { handleGenericFetchError, handleUnknownError } = useFriendlyErrors();

  const deleteTarget = (): void => {
    pamApi.delete(`/farms/${farmId}/targets/${target.id}`)
      .then((response) => {
        if (!response.ok) {
          handleGenericFetchError();
        }
        onDelete();
      })
      .catch(handleUnknownError);
  };

  return (
    <IonCard className="farmTargetCardDisplay">
      <IonCardHeader>
        <div className="farmTargetCardDisplay__header">
          <IonCardTitle>{target.paramName}</IonCardTitle>
          {alterAllowed && (
            <IonButton fill="clear" color="primary" onClick={onEdit}>
              <IonIcon icon={pencilOutline} />
            </IonButton>
          )}
          {alterAllowed && (
            <IonButton fill="clear" color="primary" onClick={deleteTarget}>
              <IonIcon icon={trashOutline} />
            </IonButton>
          )}
        </div>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <IonLabel>{t('farmTargets.inputs.Value')}</IonLabel>
            </IonCol>
            <IonCol size="6">
              <IonLabel>{t('farmTargets.inputs.Range')}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonText className="data-s">{target.value}</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="data-s">{target.range}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
}

export default FarmTargetCardDisplay;
