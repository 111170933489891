import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent, IonFabButton, IonIcon, IonModal, useIonViewWillEnter,
} from '@ionic/react';
import {
  add, pencilOutline, trashOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarm } from '../../components/FarmContext/FarmContext';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import usePamApi from '../../hooks/usePamApi';
import AnimalGroup from './types/AnimalGroup';
import AnimalGroupForm from './AnimalGroupForm';
import Page from '../../layout/Page/Page';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import { BaseCallback } from '../../types/common';
import './AnimalGroups.css';

const ALLOW_MULTIPLE_ANIMAL_GROUPS = false;
const ALLOW_DELETE_LAST_ANIMAL_GROUP = false;

function AnimalGroups(): JSX.Element {
  const { t } = useTranslation();
  const { selectedFarmId } = useFarm();
  const { handleGenericFetchError } = useFriendlyErrors();
  const pamApi = usePamApi('AnimalGroups');
  const [animalGroups, setAnimalGroups] = useState<AnimalGroup[]>([]);
  const [allowAdd, setAllowAdd] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<AnimalGroup | null>();
  const [editMode, setEditMode] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCallback, setDeleteCallback] = useState<BaseCallback>();
  const [ts, setTs] = useState(0);

  const getAnimalGroups = async (farmId: number): Promise<void> => {
    if (!farmId) {
      return;
    }

    setAllowAdd(false);

    try {
      const response = await pamApi.get(
        `/animal-groups/${farmId}`,
      );

      if (response.ok) {
        const data = await response.json();
        setAnimalGroups(data.animalGroups);
        setAllowAdd(ALLOW_MULTIPLE_ANIMAL_GROUPS || !data.animalGroups.length);
      } else {
        handleGenericFetchError();
      }
    } catch (error) {
      handleGenericFetchError();
    }
  };

  const removeAnimalGroup = async (farmId: number, id: number): Promise<void> => {
    if (!farmId) {
      return;
    }

    try {
      const response = await pamApi.delete(
        `animal-groups/farm/${farmId}/group/${id}`,
      );

      if (!response.ok) {
        handleGenericFetchError();
      }
    } catch (error) {
      handleGenericFetchError();
    }
  };

  const reloadAnimalGroups = async (): Promise<void> => {
    await getAnimalGroups(selectedFarmId);
    setIsOpen(false);
  };

  useIonViewWillEnter(() => setTs(+new Date()));

  useEffect(() => {
    if (!ts) {
      return;
    }
    getAnimalGroups(selectedFarmId);
  }, [selectedFarmId, ts]);

  const onDismiss = (): void => {
    setShowDeleteModal(false);
    setSelectedGroup(null);
    setDeleteCallback(null);
  };

  const removeGroup = async (id: number): Promise<void> => {
    onDismiss();
    await removeAnimalGroup(selectedFarmId, id);
    await reloadAnimalGroups();
  };

  const editGroup = (id: number): void => {
    setSelectedGroup(animalGroups.find((group) => group.id === id));
    setEditMode(true);
    setIsOpen(true);
  };

  const createGroup = (): void => {
    setSelectedGroup(null);
    setEditMode(false);
    setIsOpen(true);
  };

  const tryRemoveGroup = async (id: number): Promise<void> => {
    setShowDeleteModal(true);
    setSelectedGroup(animalGroups.find((group) => group.id === id));
    setDeleteCallback(() => () => removeGroup(id));
  };

  return (
    <Page title={t('animalGroups.Title')}>
      <div className="animal-groups">
        <div className="animal-groups__title">
          <h2>
            {t('animalGroups.Title')}
          </h2>
          <IonFabButton
            className="edit-button"
            onClick={createGroup}
            disabled={!selectedFarmId}
            hidden={!allowAdd}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </div>

        <div>
          {animalGroups.map((animalGroup) => (
            <IonCard key={`animal-group-${selectedFarmId}-${animalGroup.id}`}>
              <IonCardHeader>
                <div className="animal-group__header">
                  <IonCardTitle>{animalGroup.name}</IonCardTitle>
                  <IonButton
                    fill="clear"
                    color="primary"
                    onClick={() => editGroup(animalGroup.id)}
                  >
                    <IonIcon icon={pencilOutline} />
                  </IonButton>
                  {(ALLOW_DELETE_LAST_ANIMAL_GROUP || animalGroups.length > 1) && (
                    <IonButton
                      fill="clear"
                      color="primary"
                      onClick={() => tryRemoveGroup(animalGroup.id)}
                    >
                      <IonIcon icon={trashOutline} />
                    </IonButton>
                  )}
                </div>
              </IonCardHeader>
              <IonCardContent>
                {`${animalGroup.count} ${t('animalGroups.Animals')}`}
              </IonCardContent>
            </IonCard>
          ))}
        </div>

        <IonModal isOpen={isOpen} initialBreakpoint={0.75} breakpoints={[0, 0.75, 1]} onDidDismiss={() => setIsOpen(false)}>
          <IonContent className="ion-padding">
            <AnimalGroupForm onSubmitted={reloadAnimalGroups} editMode={editMode} animalGroup={selectedGroup} />
          </IonContent>
        </IonModal>
      </div>

      <FeedbackModal
        isOpen={showDeleteModal}
        feedbackType="warning"
        cancel
        title={t('animalGroups.actions.delete.Title')}
        text={selectedGroup?.name || ''}
        mainCTACaption={t('actions.Delete')}
        onDismiss={onDismiss}
        onMainCTAClick={deleteCallback}
      />
    </Page>
  );
}

export default AnimalGroups;
