import {
  IonBadge, IonItem, IonLabel, IonList, IonSkeletonText, useIonViewDidEnter,
} from '@ionic/react';
import { t } from 'i18next';
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFarm } from '../../components/FarmContext/FarmContext';
import {
  notificationBoxType, NotificationBoxType, Notification,
} from '../../types/notifications';
import { useInAppNotification } from '../../components/InAppNotificationContext/InAppNotificationContext';
import usePamNotificationsApi from '../../hooks/usePamNotificationsApi';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';
import './Notifications.css';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import Page from '../../layout/Page/Page';
import * as routes from '../../routing/routes';
import NotificationBase from '../../components/NotificationBase/NotificationBase';
import usePreviousValue from '../../hooks/usePreviousValue';

type NotificationsState = {
  [K in NotificationBoxType]: Notification[];
};

const notificationState: NotificationsState = {
  [notificationBoxType.PRESCRIPTION]: [],
  [notificationBoxType.RECOMMENDATION]: [],
  [notificationBoxType.ALERT]: [],
  [notificationBoxType.PENDING]: [],
};

function Notifications(): JSX.Element {
  const { getNotificationsByFarm } = usePamNotificationsApi();
  const { selectedFarmId } = useFarm();
  const [notifications, setNotifications] = useState<NotificationsState>(notificationState);
  const { inAppNotifications } = useInAppNotification();
  const [loading, setLoading] = useState(true);
  const identity = useIdentity();
  const isMounted = useRef(true);
  const friendlyErrors = useFriendlyErrors();
  const history = useHistory();
  const { type: subpageType } = useParams<{ type: NotificationBoxType }>();
  const previousNotificationsCount = usePreviousValue(inAppNotifications.length);

  const getNotifications = async (farmId: number): Promise<void> => {
    if (!farmId || !isMounted.current) {
      return;
    }

    setLoading(true);

    try {
      const response = await getNotificationsByFarm(farmId);

      if (response.ok) {
        const data = await response.json();

        setNotifications(data);
      } else {
        friendlyErrors.handleGetNotificationsError();
      }
    } catch (error) {
      friendlyErrors.handleGetNotificationsError();
    }

    setLoading(false);
  };

  useIonViewDidEnter(() => {
    if (!loading && isMounted.current && selectedFarmId && !subpageType) {
      getNotifications(selectedFarmId);
    }
  }, [loading, subpageType]);

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {
    if (isMounted.current && selectedFarmId) {
      getNotifications(selectedFarmId);
    }
  }, [selectedFarmId]);

  useEffect(() => {
    const newNotificationsAvailable = (previousNotificationsCount || 0) < inAppNotifications.length;

    if (isMounted.current && !loading && newNotificationsAvailable) {
      getNotifications(selectedFarmId);
    }
  }, [inAppNotifications]);

  const updateNotifications = (): void => {
    getNotifications(selectedFarmId);
  };

  const refreshNotifications = async (): Promise<void> => {
    await getNotifications(selectedFarmId);
  };

  const getNotificationsCount = (type: NotificationBoxType): number => {
    const group = notifications?.[type];

    return group?.length || 0;
  };

  const renderNotifications = (type: NotificationBoxType): ReactNode => {
    const group = notifications?.[type];

    if (!notifications || !notifications[type]) {
      return <h3>{t('notifications.EmptyNotifications')}</h3>;
    }

    if (group) {
      const allowActions = identity.user?.type === UserType.Farmer;

      return group.map((notification: Notification) => (
        <NotificationBase key={notification.id} notification={notification} refresh={updateNotifications} allowActions={allowActions} />
      ));
    }

    return null;
  };

  const navigate = (type: NotificationBoxType): void => {
    history.push(`${routes.Notifications}/${type}`, { type, updated: +new Date() });
  };

  const renderNotificationType = (type: NotificationBoxType, disabled = false, badgeColor = 'primary'): ReactNode => (
    <IonItem button detail onClick={() => navigate(type)} disabled={disabled}>
      <IonLabel>
        {t(`notifications.${type}`)}
      </IonLabel>
      <IonBadge color={badgeColor} mode="ios" slot="end">{disabled ? 0 : getNotificationsCount(type)}</IonBadge>
    </IonItem>
  );

  const renderSkeleton = (): JSX.Element => (
    <IonList>
      {[0, 1, 2, 3].map((index) => <IonSkeletonText animated key={index} />)}
    </IonList>
  );

  return (
    subpageType
      ? (
        <Page title={t(`notifications.${subpageType}`)} refresher={refreshNotifications} showBackButton>
          {renderNotifications(subpageType)}
        </Page>
      )
      : (
        <Page title={t('notifications.Title')} refresher={refreshNotifications}>
          <div className="notifications-container">
            {loading && renderSkeleton()}
            {!loading && (
              <IonList>
                {renderNotificationType(notificationBoxType.PENDING, false, 'danger')}
                {renderNotificationType(notificationBoxType.PRESCRIPTION)}
                {renderNotificationType(notificationBoxType.RECOMMENDATION)}
                {renderNotificationType(notificationBoxType.ALERT)}
              </IonList>
            )}
          </div>
        </Page>
      )
  );
}

export default Notifications;
