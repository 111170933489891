import cn from 'classnames';
import {
  IonInput, IonItem, IonLabel, IonText,
} from '@ionic/react';

import './Input.css';

export type InputType = 'text' | 'number' | 'password' | 'month' | 'date' | 'email' | 'tel';
export type InputModes = 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;

type InputProps = {
  label?: string;
  type?: InputType;
  value?: string | number;
  placeholder?: string;
  pattern?: string;
  maxlength?: number;
  max?: string | number;
  icon?: string; // import { personSharp } from "ionicons/icons";
  block?: boolean;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  step?: string;
  onChange?: (value: string) => void;
  error?: string;
  inputMode?: InputModes;
  hidden?: boolean;
};

function Input({
  label,
  placeholder,
  value,
  type,
  pattern,
  maxlength,
  max,
  className,
  disabled,
  required,
  step,
  onChange,
  error,
  inputMode,
  hidden,
}: InputProps): JSX.Element {
  return (
    <IonItem className={cn('pam-input', className)} hidden={hidden}>
      <IonLabel color="primary" position="stacked">{label}</IonLabel>
      <IonInput
        hidden={hidden}
        disabled={disabled}
        required={required}
        type={type}
        step={step}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(value ? { value } : {})}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(max ? { max } : {})}
        pattern={pattern}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(inputMode ? { inputMode } : {})}
        maxlength={maxlength}
        placeholder={placeholder}
        onIonChange={(e) => onChange && onChange(((e.target as unknown) as HTMLInputElement).value)}
      />
      {error && (
        <IonText color="danger" className="text-s">{error}</IonText>
      )}
    </IonItem>
  );
}

export default Input;
