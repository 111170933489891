import {
  createContext, useContext, useEffect, useMemo, useState, ReactNode,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory, useLocation } from 'react-router';
import * as routes from '../../routing/routes';
import usePamApi from '../../hooks/usePamApi';
import useLogger from '../../hooks/useLogger';

export const enum UserType {
  Farmer = 0,
  Veterinary = 1
}

interface User {
  id: number;
  type: UserType;
  name: string;
  familyName: string;
  email: string;
  phoneNumber: string;
}

export type Identity = {
  id?: number;
  username: string;
  fullName?: string;
  email?: string;
  type?: UserType;
  roles?: string[];
}

type IdentityState = {
  user?: Identity,
  wasChecked: boolean,
  isLoggedIn: boolean,
  isRegistered: boolean,
  refresh: () => void,
}

const EmptyState = {
  user: undefined,
  wasChecked: false,
  isLoggedIn: false,
  isRegistered: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refresh: () => { },
};

const IdentityContext = createContext<IdentityState>(EmptyState);

type IdentityContextProviderProps = {
  children: ReactNode;
};

const tryGetRoles = (source: string | string[] | unknown): string[] => {
  if (!source) {
    return [];
  }

  if (typeof source === 'string') {
    return [source];
  }

  return source as string[];
};

function IdentityContextProvider({ children }: IdentityContextProviderProps): JSX.Element {
  const auth = useAuth();
  const pamApi = usePamApi('IdentityContextProvider');
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState<Identity>();
  const [wasChecked, setWasChecked] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [mustRefresh, setMustRefresh] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const { error } = useLogger();

  const refresh = (): void => (setMustRefresh(true));

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !location.pathname.match(routes.Login)) {
      history.replace(routes.Login);
    }
    const getAppUser = async (loggedInUser: Identity): Promise<Response> => pamApi.get(`/appusers/${loggedInUser.username}`);
    const checkUserIsLoggedIn = async (): Promise<void> => {
      if (isChecking || (wasChecked && !mustRefresh)) {
        return;
      }
      setIsChecking(true);

      try {
        if (!auth.isAuthenticated) {
          throw new Error('401 - !auth.isAuthenticated');
        }

        const identity: Identity = {
          username: auth.user?.profile.name || 'unknown username',
          roles: tryGetRoles(auth.user?.profile.role),
        };

        try {
          const appUserResponse = await getAppUser(identity);
          if (appUserResponse.ok) {
            const {
              id, type, familyName, name, email,
            }: User = await appUserResponse.json();

            identity.id = id;
            identity.type = type;
            identity.email = email;
            identity.fullName = `${name} ${familyName}`;
            setIsRegistered(true);
          } else {
            setIsRegistered(false);
          }
          setUser(identity);
          setIsLoggedIn(true);
        } catch (e) {
          error('checkUserIsLoggedIn', e);
          setIsRegistered(false);
          setIsLoggedIn(false);
        }
      } catch (e) {
        error('login error', e);
        setUser(undefined);
        setIsLoggedIn(false);
        setIsRegistered(false);
      }

      setWasChecked(true);
      setIsChecking(false);
      setMustRefresh(false);
    };

    if (!auth.isLoading && auth.isAuthenticated) {
      (async () => (checkUserIsLoggedIn()))();
    }
  }, [auth.isAuthenticated, auth.isLoading, auth.user?.profile.name, isChecking, mustRefresh, wasChecked]);

  const contextState = useMemo<IdentityState>(
    () => ({
      user,
      wasChecked,
      isLoggedIn,
      isRegistered,
      refresh,
    }),
    [user, wasChecked, isLoggedIn, isRegistered],
  );

  return (
    <IdentityContext.Provider value={contextState}>
      {children}
    </IdentityContext.Provider>
  );
}

function useIdentity(): IdentityState {
  return useContext(IdentityContext);
}

export { IdentityContextProvider, useIdentity };
