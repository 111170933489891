import {
  IonButton, IonIcon,
} from '@ionic/react';
import { t } from 'i18next';
import { readerOutline } from 'ionicons/icons';
import { Notification } from '../../types/notifications';
import * as routes from '../../routing/routes';

type NotificationRecommendationActionsProps = {
  notification: Notification;
  dismiss?: () => void;
  switchTarget?: () => void;
}

function NotificationRecommendationActions({
  notification,
  dismiss,
  switchTarget,
}: NotificationRecommendationActionsProps): JSX.Element | null {
  const link = notification.metadata.activeMedicinalBlendId ? routes.buildViewBotanicalFeedUrl(notification.metadata.activeMedicinalBlendId) : routes.BotanicalFeed;

  const onClick = (): void => {
    if (switchTarget) {
      switchTarget();
    }

    if (dismiss) {
      dismiss();
    }
  };

  return (
    <IonButton
      fill="clear"
      size="default"
      className="notification__button ion-text-wrap"
      routerLink={link}
      onClick={onClick}
    >
      <IonIcon icon={readerOutline} slot="start" />
      {t('notifications.actions.ViewRecommendation')}
    </IonButton>
  );
}

export default NotificationRecommendationActions;
