import { IonButton } from '@ionic/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePamApi from '../../hooks/usePamApi';
import { Farm } from '../../types/farms';
import { useFarm } from '../FarmContext/FarmContext';
import FeedbackModal from '../FeedbackModal/FeedbackModal';

export interface DeleteFarmProps {
  children: React.ReactElement
  farm: Farm
}

function DeleteFarmButton({ farm, children }: DeleteFarmProps): JSX.Element {
  const { t } = useTranslation();
  const pamApi = usePamApi('deleteFarmButton');
  const { reload: reloadFarmContext } = useFarm();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteFarm = useCallback(
    async () => {
      await pamApi.delete(`/farms/${farm.id}`);
      reloadFarmContext();
    },
    [farm],
  );

  return (
    <>
      <IonButton
        fill="clear"
        color="primary"
        onClick={() => setShowDeleteModal(true)}
      >
        {children}
      </IonButton>
      <FeedbackModal
        isOpen={showDeleteModal}
        feedbackType="warning"
        cancel
        title={t('farmConfiguration.DeleteWarning')}
        text={farm?.name || ''}
        mainCTACaption={t('actions.Delete')}
        onDismiss={() => setShowDeleteModal(false)}
        onMainCTAClick={onDeleteFarm}
      />
    </>
  );
}

export default DeleteFarmButton;
