import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as routes from '../../routing/routes';
import WizardWithSlides, { WizardSlide } from '../../layout/Page/WizardWithSlidesPage';

function VetOnBoarding(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const slides: WizardSlide[] = [
    {
      image: 'toma-decision',
      title: t('vetOnBoarding.slide_1.Title'),
      body: t('vetOnBoarding.slide_1.Body'),
    },
    {
      image: 'monitorizacion-granjas',
      title: t('vetOnBoarding.slide_2.Title'),
      body: t('vetOnBoarding.slide_2.Body'),
    },
    {
      image: 'apoyo-prescripcion',
      title: t('vetOnBoarding.slide_3.Title'),
      body: t('vetOnBoarding.slide_3.Body'),
    },
    {
      image: 'respuesta-tiempo-real',
      title: t('vetOnBoarding.slide_4.Title'),
      body: t('vetOnBoarding.slide_4.Body'),
    },
  ];

  return (
    <WizardWithSlides slides={slides} onFinish={() => history.push(routes.RegisterSelectManagementArea)} />
  );
}

export default VetOnBoarding;
