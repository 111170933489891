import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Page from '../../layout/Page/Page';
import RegisterFarmComponent from './RegisterFarmComponent';
import * as routes from '../../routing/routes';

type RegisterFarmProps = {
  registerMode? : boolean;
}

function RegisterFarm({ registerMode }: RegisterFarmProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const onCreate = (): void => {
    history.push(routes.AnimalGroupsWizard);
  };

  return (
    <Page title={t('register.farm.Title')} hideFarmSelector showBackButton={!registerMode} hideNotifications hideMenu={registerMode}>
      <RegisterFarmComponent onCreate={onCreate} />
    </Page>
  );
}

export default RegisterFarm;
