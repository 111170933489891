import { ReactNode } from 'react';
import { t } from 'i18next';
import cn from 'classnames';
import {
  IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel,
} from '@ionic/react';
import { Notification, NotificationStatus, notificationType } from '../../types/notifications';
import { formatDateTime } from '../../utils/formatters';

type StaticNotificationProps = {
  notification: Notification;
  children?: ReactNode;
};

function StaticNotification({ notification, children }: StaticNotificationProps): JSX.Element {
  const renderStaticCardHeader = (item: Notification): JSX.Element | null => {
    let badge = null;
    let titleClassName = '';

    if (item.type === notificationType.PRESCRIPTION) {
      let color: string;
      let caption: string;

      if (item.status === NotificationStatus[NotificationStatus.Rejected]) {
        color = 'danger';
        caption = 'notifications.PrescriptionRejected';
      } else if (item.status === NotificationStatus[NotificationStatus.Accepted]) {
        color = 'success';
        caption = 'notifications.PrescriptionAccepted';
      } else {
        color = 'primary';
        caption = 'notifications.Prescription';
      }

      badge = <IonBadge slot="end" mode="ios" color={color}>{t(caption)}</IonBadge>;
    } else if (item.type === notificationType.ALERT) {
      titleClassName = item.type.toLowerCase();
    }

    return (
      <IonCardHeader>
        <IonCardSubtitle>
          <IonItem lines="none" className="text-m">
            <IonLabel slot="start">{formatDateTime(item.created)}</IonLabel>
            {badge}
          </IonItem>
        </IonCardSubtitle>
        <IonCardTitle className={cn(titleClassName)}>
          <IonLabel className="text-xl notification-item__title-line">{item.metadata.farmName}</IonLabel>
          <IonLabel className="text-xl notification-item__title-line">{item.title}</IonLabel>
        </IonCardTitle>
      </IonCardHeader>
    );
  };

  return (
    <IonCard className={cn('notification-item', 'ion-no-padding')}>
      {renderStaticCardHeader(notification)}
      <IonCardContent>
        <span className="text-m">{notification.body}</span>
      </IonCardContent>
      {children}
    </IonCard>
  );
}

export default StaticNotification;
