import cn from 'classnames';

import './Box.css';

type BoxProps = {
  title?: string;
  className?: string;
  children: React.ReactNode;
};

function Box({ title, className, children }: BoxProps): JSX.Element {
  return (
    <div className={cn('box', className)}>
      {title && <div className="box__title">{title}</div>}
      <div className="box__body">{children}</div>
    </div>
  );
}

export default Box;
