import {
  IonFabButton, IonIcon,
} from '@ionic/react';
import { t } from 'i18next';
import { add } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import { useFarm, EmptyFarm } from '../../components/FarmContext/FarmContext';
import FarmTargetCard, { FarmTarget } from '../../components/FarmTargetCard/FarmTargetCard';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import usePamApi from '../../hooks/usePamApi';
import Page from '../../layout/Page/Page';
import { NumberStringDictionary } from '../../types/common';
import './FarmTargets.css';

type TargetListCallback = {
  targets: FarmTarget[];
  measurementTypes: NumberStringDictionary;
};

function FarmTargets(): JSX.Element {
  const { selectedFarm: farm } = useFarm();
  const [targets, setTargets] = useState<FarmTarget[]>([]);
  const [measurementTypes, setMeasurementTypes] = useState<NumberStringDictionary>({});
  const { user } = useIdentity();
  const isAlterAllowed = useCallback(() => user?.type === UserType.Veterinary, [user]);
  const [creating, setCreating] = useState(false);
  const pamApi = usePamApi('FarmTargets');
  const friendlyErrors = useFriendlyErrors();

  const fetchTargets = useCallback(
    (handleTargets: (data: TargetListCallback) => void): Promise<void> => pamApi.get(
      `/farms/${farm.id}/targets`,
    ).then((response) => {
      if (!response.ok) {
        friendlyErrors.handleFetchTargetsError();
        return;
      }

      response.json()
        .then(handleTargets)
        .catch(friendlyErrors.handleUnknownError);
    })
      .catch(friendlyErrors.handleFetchTargetsError),
    [farm.id],
  );

  const refresh = useCallback(
    () => {
      fetchTargets((data) => setTargets(data.targets));
      setCreating(false);
    },
    [targets],
  );
  const create = useCallback(() => {
    setTargets([{
      id: 0,
      paramName: '',
      value: 0,
      range: 0,
      measurementTypeId: 0,
    }, ...targets]);

    setCreating(true);
  }, [targets]);
  const cancel = useCallback((id: number) => {
    if (id > 0) {
      return;
    }

    setTargets(targets.slice(1, targets.length));
    setCreating(false);
  }, [targets]);

  useEffect(() => {
    if (farm === EmptyFarm) {
      return;
    }

    fetchTargets((data) => {
      setTargets(data.targets);
      setMeasurementTypes(data.measurementTypes);
      setCreating(false);
    });
  }, [farm]);

  return (
    <Page title={t('farmTargets.Title')} showBackButton>
      <div className="farmTargets__title">
        <h2>{t('farmTargets.Title')}</h2>
        <IonFabButton
          onClick={create}
          disabled={creating}
          hidden={!isAlterAllowed()}
        >
          <IonIcon icon={add} />
        </IonFabButton>
      </div>
      {targets.map((target) => (
        <FarmTargetCard
          farmId={farm.id}
          key={target.measurementTypeId}
          alterAllowed={isAlterAllowed()}
          target={target}
          onTargetUpdated={refresh}
          onTargetUpdateCancelled={() => cancel(target.id)}
          measurementTypes={measurementTypes}
        />
      ))}
    </Page>
  );
}

export default FarmTargets;
