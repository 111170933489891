import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useFarm } from '../../components/FarmContext/FarmContext';
import Page from '../../layout/Page/Page';
import { Farm } from '../../types/farms';
import UpdateFarmInfoForm from './UpdateFarmInfoForm';
import UpdateLaboratoryForm from './UpdateLaboratoryForm';

function UpdateFarm(): JSX.Element {
  const { farmId } = useParams<{farmId: string}>();
  const [farmToEdit, setFarmToEdit] = useState<Farm|undefined>(undefined);
  const { t } = useTranslation();
  const { farms } = useFarm();

  useEffect(() => {
    const theFarm = farms.find((farm) => `${farm.id}` === farmId);
    setFarmToEdit(theFarm);
  }, [farmId, farms]);

  return (
    <Page title={t('register.farm.TitleEdit')} showBackButton hideFarmSelector>
      <h1>{farmToEdit?.name}</h1>
      {farmToEdit && <UpdateFarmInfoForm farm={farmToEdit} />}
      {farmToEdit && <UpdateLaboratoryForm farm={farmToEdit} />}
    </Page>
  );
}

export default UpdateFarm;
