import { ReactNode, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router';
import useLogger from '../../hooks/useLogger';
import * as routes from '../../routing/routes';

type PamAuthEventsProps = {
  children: ReactNode;
};

function PamAuthEvents({ children }: PamAuthEventsProps): JSX.Element {
  const auth = useAuth();
  const history = useHistory();
  const { warn } = useLogger();

  useEffect(() => {
    auth.events.addSilentRenewError((e) => {
      warn('****** silent renew error', e);
      if (e.message === 'login_required') {
        history.replace(routes.Login);
      }
    });
  }, [auth, history]);

  return (children as JSX.Element);
}

export default PamAuthEvents;
