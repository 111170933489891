import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useIdentity } from '../../components/Identity/IdentityContext';
import Button from '../../components/Button/Button';
import Field from '../../components/Field/Field';
import Form from '../../components/Form/Form';
import '../../utils/yup';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import FieldSelector from '../../components/Field/FieldSelector';
import { Laboratory } from '../../types/Laboratories';
import { useFarm } from '../../components/FarmContext/FarmContext';
import InfoBox from '../../components/InfoBox/InfoBox';

export const MAX_MILK_PRICE_NUMBER = 1_000;

interface RegisterFarmComponentProps {
  onCreate: () => void;
}

function RegisterFarmComponent({ onCreate }: RegisterFarmComponentProps): JSX.Element {
  const { t } = useTranslation();
  const { user } = useIdentity();
  const pamApi = usePamApi('RegisterFarmComponent');
  const friendlyErrors = useFriendlyErrors();
  const [hasFarmerCode, setHasFarmerCode] = useState(false);
  const [laboratories, setLaboratories] = useState<Laboratory[]>([]);
  const [busy, setBusy] = useState(false);
  const { reload: reloadFarmContext, selectFarm } = useFarm();

  useEffect(() => {
    const getLaboratories = async (): Promise<void> => {
      const response = await pamApi.get('/laboratories');
      if (response.ok) {
        const data = await response.json();
        setLaboratories(data.laboratories);
      }
    };

    getLaboratories();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const register = (formData: Record<string, any>): void => {
    setBusy(true);
    const selectedLab = laboratories.find((lab) => lab.id === +formData.labName);

    pamApi.post('/farms', {
      userId: user?.id ?? 0,
      ...formData,
      farmerCode: hasFarmerCode ? formData.farmerCode : '',
      labName: selectedLab ? selectedLab.acronym : '',
      labId: selectedLab ? selectedLab.id : 0,
    })
      .then((response) => {
        if (!response.ok) {
          friendlyErrors.handleFarmRegistrationFailedError();
          setBusy(false);
          return;
        }
        selectFarm(0);
        reloadFarmContext();
        onCreate();
        setBusy(false);
      })
      .catch(() => {
        friendlyErrors.handleFarmRegistrationFailedError();
        setBusy(false);
      });
  };

  const schema = yup.object({
    name: yup.string()
      .required(t('forms.errors.Required')),
    rega: yup.string()
      .required(t('forms.errors.Required'))
      .matches(/^ES\d{2}\d{3}\d{7}$/gi, t('forms.errors.Rega')),
    labName: yup.string()
      .required(t('forms.errors.Required')),
    labUsername: yup.string()
      .required(t('forms.errors.Required')),
    labPassword: yup.string()
      .required(t('forms.errors.Required')),
    farmerCode: yup.string()
      .test('farmer-code', t('forms.errors.Required'), (value) => {
        if (!hasFarmerCode) return true;
        return !!value;
      }),
    milkPrice: yup.number()
      .positive(t('forms.errors.GreaterThanZero'))
      .min(0.001, t('forms.errors.GreaterThanZero'))
      .lessThan(MAX_MILK_PRICE_NUMBER, `${t('forms.errors.LessThan')}${MAX_MILK_PRICE_NUMBER}`)
      .typeError(t('forms.errors.RequireNumber')),
  });

  return (
    <Form onSubmit={register} schema={schema} className="register-farm">
      <Field
        name="name"
        label={t('register.farm.field.Name')}
        placeholder={t('register.farm.field.NamePlaceholder')}
      />
      <Field
        name="rega"
        type="rega"
        label={t('register.farm.field.Rega')}
      />
      <Field
        name="milkPrice"
        type="number"
        label={t('register.farm.field.MilkPrice')}
        step="any"
      />

      <h5>{t('register.farm.LaboratorySection')}</h5>

      <FieldSelector
        name="labName"
        label={t('register.farm.field.LabName')}
        placeholder={t('register.farm.field.LabNamePlaceholder')}
        options={Object.values(laboratories).map((laboratory) => ({
          id: laboratory.id,
          name: laboratory.acronym,
        }))}
        onChangeCustom={(value: string) => {
          const laboratorySelected = laboratories.find((lab) => lab.id === +value);
          setHasFarmerCode(laboratorySelected ? laboratorySelected.requiresFarmerCode : false);
        }}
      />

      {(hasFarmerCode
        && (
          <InfoBox className="ion-no-margin ion-margin-top">{t('register.farm.field.FarmerCodeTooltip')}</InfoBox>
        )
      )}

      {(hasFarmerCode
        && (
          <Field
            name="farmerCode"
            type="text"
            label={t('register.farm.field.FarmerCode')}
            className="farmer-code"
          />
        )
      )}

      <Field
        name="labUsername"
        type="personal"
        label={t('register.farm.field.LabUsername')}
      />
      <Field
        name="labPassword"
        type="password"
        label={t('register.farm.field.LabPassword')}
      />
      <Button
        higher
        block
        submit
        disabled={busy}
        className="mt--2"
      >
        {t('register.farm.actions.Create')}
      </Button>
    </Form>
  );
}

export default RegisterFarmComponent;
