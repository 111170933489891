import { forwardRef, Ref } from 'react';
import InputForReactHookForm, { BaseInputProps } from './InputForReactHookForm';

type InputPasswordProps = BaseInputProps

function InputPassword(props: InputPasswordProps, ref: Ref<HTMLIonInputElement>):JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <InputForReactHookForm {...props} type="password" ref={ref} />;
}

export default forwardRef(InputPassword);
