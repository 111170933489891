import {
  IonButton,
  IonCol, IonGrid, IonIcon, IonRow,
} from '@ionic/react';
import { pencilOutline } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWidgetProps } from '..';
import { EmptyFarm, useFarm } from '../../../../components/FarmContext/FarmContext';
import { FarmTarget } from '../../../../components/FarmTargetCard/FarmTargetCard';
import useFriendlyErrors from '../../../../hooks/useFriendlyErrors';
import usePamApi from '../../../../hooks/usePamApi';
import { NumberStringDictionary } from '../../../../types/common';
import * as routes from '../../../../routing/routes';
import ProductionTargetCard from './ProductionTargetCard';

type TargetListCallback = {
  targets: FarmTarget[];
  measurementTypes: NumberStringDictionary;
};

function ProductionTargetWidget({ isVeterinary, timestamp }: DashboardWidgetProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedFarm: farm } = useFarm();
  const pamApi = usePamApi('FarmTargets');
  const [targets, setTargets] = useState<FarmTarget[]>([]);
  const [loading, setLoading] = useState(true);
  const friendlyErrors = useFriendlyErrors();

  const fetchTargets = useCallback(
    (handleTargets: (data: TargetListCallback) => void): Promise<void> => pamApi.get(
      `/farms/${farm.id}/targets`,
    ).then((response) => {
      if (!response.ok) {
        friendlyErrors.handleFetchTargetsError();
        return;
      }

      response.json()
        .then(handleTargets)
        .catch(friendlyErrors.handleUnknownError);
      setLoading(false);
    })
      .catch(friendlyErrors.handleFetchTargetsError),
    [pamApi, farm.id],
  );

  useEffect(
    () => {
      if (farm === EmptyFarm) {
        setLoading(false);
        return;
      }

      setLoading(true);
      fetchTargets((data) => {
        setTargets(data.targets);
      });
    },

    [farm, timestamp],
  );

  return (
    <div className="productionTargetsWidget dashboard-widget">
      <div className="dashboard-widget__title">
        <h2>{t('productionTargetsWidget.Title')}</h2>
        {isVeterinary && (
          <IonButton fill="clear" color="primary" size="large" routerLink={routes.Targets}>
            <IonIcon icon={pencilOutline} />
          </IonButton>
        )}
      </div>
      <IonGrid className="dashboard__grid">
        <IonRow>
          {loading && [0, 1, 2, 3].map((value) => <IonCol key={`milk_kpi_loading_${value}`} size="6"><ProductionTargetCard /></IonCol>)}
          {!loading && targets.map((target) => (
            <IonCol key={`milk_kpi_${timestamp}_${target.id}`} size="6">
              <ProductionTargetCard target={target} />
            </IonCol>
          ))}
          {!loading && !targets?.length && (
            <IonGrid className="color-neutral-50">
              <IonRow>
                <IonCol className="ion-text-center">
                  {t('productionTargetsWidget.NoData')}
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
}

export default ProductionTargetWidget;
