export const formatNumber = (
  number: number,
  truncate = true,
  digits = 1,
  format = 'es-ES',
): string => new Intl.NumberFormat(format, {
  minimumFractionDigits: truncate ? 0 : digits,
  maximumFractionDigits: truncate ? 0 : digits,
}).format(number);

export const formatDate = (
  date: Date | string,
  day: '2-digit' | undefined,
  month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined,
  year: 'numeric' | '2-digit' | undefined,
  format = 'es-ES',
): string => new Intl.DateTimeFormat(format, {
  day,
  month,
  year,
}).format(typeof date === 'string' ? new Date(date) : date);

export const formatYearFromDate = (date: Date): string => `${formatDate(date, undefined, undefined, 'numeric')}`;
const formatMonthFromDate = (date: Date): string => `${formatDate(date, undefined, '2-digit', undefined)}`;
const formatDayFromDate = (date: Date): string => `${formatDate(date, '2-digit', undefined, undefined)}`;

export const formatYearMonth = (date: Date): string => `${formatYearFromDate(date)}-${formatMonthFromDate(date)}`;
export const formatYearMonthDay = (date: Date): string => `${formatYearFromDate(date)}-${formatMonthFromDate(date)}-${formatDayFromDate(date)}`;

export const formatDateTime = (input: Date | string, format = 'default', options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}): string => {
  const date = typeof input === 'string' ? new Date(input) : input;

  return new Intl.DateTimeFormat(format, options).format(date);
};
