import { IonContent, IonPage } from '@ionic/react';
import { useReducer } from 'react';
import { useParams } from 'react-router';
import { useAuth } from 'react-oidc-context';
import LoginOptions from './steps/LoginOptions/LoginOptions';
import LoginCredentials from './steps/LoginCredentials/LoginCredentials';
import './Login.css';
import LoginRegisterPage from './steps/LoginRegister/LoginRegisterPage';

type Action =
  | { type: 'enter' }
  | { type: 'credentials' }
  | { type: 'register' }
  | { type: 'registered' }
  | { type: 'verify' };

type State = {
  step: 'options' | 'credentials' | 'register' | 'verify' | 'validate';
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'enter':
      return { ...state, step: 'credentials' };
    case 'register':
      return { ...state, step: 'register' };
    case 'credentials':
      return { ...state, step: 'verify' };
    case 'registered':
      return { ...state, step: 'validate' };
    case 'verify':
      return { ...state, step: 'validate' };
    default:
      throw new Error();
  }
}

function Login(): JSX.Element {
  const { mode } = useParams<{mode: string}>();
  const auth = useAuth();

  const [state, dispatch] = useReducer(reducer, {
    step: 'options',
  });

  const login = (): void => {
    auth.signinRedirect();
  };

  const loginStep = mode ?? state.step;

  switch (loginStep) {
    case 'options':
      return (
        <IonPage className="login">
          <IonContent>
            <div className="login__content">
              <img src="/assets/logo-full.svg" className="login__logo" alt="" />
              <img src="/assets/img_cover1.png" alt="" className="login__cover" />
              <LoginOptions onLogin={login} />
            </div>
          </IonContent>
        </IonPage>
      );
    case 'register':
      return (
        <LoginRegisterPage />
      );
    case 'credentials':
      return (
        <IonPage className="login">
          <IonContent>
            <div className="login__content">
              <LoginCredentials
                onNext={() => dispatch({ type: 'credentials' })}
              />
            </div>
          </IonContent>
        </IonPage>
      );
    default:
      return <div />;
  }
}

export default Login;
