import cn from 'classnames';
import {
  IonButton, IonCol, IonFooter, IonGrid, IonIcon, IonModal, IonRow,
} from '@ionic/react';
import { checkmarkCircleOutline, alertCircleOutline, warningOutline } from 'ionicons/icons';
import { t } from 'i18next';
import { useRef } from 'react';
import './FeedbackModal.css';
import { BaseCallback } from '../../types/common';

type FeedbackModalProps = {
  isOpen?: boolean;
  trigger?: string;
  feedbackType: 'positive' | 'negative' | 'warning';
  title: string;
  text: string;
  initialBreakpoint?: number;
  backdropDismiss?: boolean;
  cancel?: boolean;
  mainCTACaption?: string;
  secondaryCTACaption?: string;
  onDismiss?: BaseCallback;
  onMainCTAClick?: BaseCallback;
  onSecondaryCTAClick?: BaseCallback;
}

function FeedbackModal({
  isOpen,
  trigger,
  feedbackType,
  title,
  text,
  initialBreakpoint = 0.95,
  backdropDismiss = false,
  cancel,
  mainCTACaption,
  secondaryCTACaption,
  onDismiss,
  onMainCTAClick,
  onSecondaryCTAClick,
}: FeedbackModalProps): JSX.Element {
  const modal = useRef<HTMLIonModalElement>(null);

  const dismiss = (): Promise<boolean> | undefined => modal.current?.dismiss();

  const onSecondaryClick = (): Promise<boolean> | undefined => {
    if (onSecondaryCTAClick) {
      onSecondaryCTAClick();
    }

    if (onDismiss) {
      onDismiss();
    }

    return dismiss();
  };

  const onMainClick = (): Promise<boolean> | undefined => {
    if (onMainCTAClick) {
      onMainCTAClick();
    }

    if (onDismiss) {
      onDismiss();
    }

    return dismiss();
  };

  const getIcon = (): string => {
    switch (feedbackType) {
      case 'positive':
        return checkmarkCircleOutline;
      case 'negative':
        return alertCircleOutline;
      case 'warning':
        return warningOutline;
      default:
        return '';
    }
  };

  const getIconModifier = (): string => {
    switch (feedbackType) {
      case 'positive':
        return 'feedbackModal__icon--positive';
      case 'negative':
        return 'feedbackModal__icon--negative';
      case 'warning':
        return 'feedbackModal__icon--warning';
      default:
        return '';
    }
  };

  return (
    <IonModal
      initialBreakpoint={initialBreakpoint}
      className="feedbackModal"
      ref={modal}
      isOpen={isOpen}
      trigger={trigger}
      canDismiss
      backdropDismiss={backdropDismiss}
    >
      <div className="feedbackModal__content">
        <div className={cn(
          'feedbackModal__icon',
          getIconModifier(),
        )}
        >
          <IonIcon icon={getIcon()} />
        </div>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <IonFooter>
        <IonGrid>
          <IonRow>
            {cancel && (
              <IonCol size="6">
                <IonButton
                  expand="block"
                  fill="clear"
                  size="default"
                  className="feedbackModal__button--secondary ion-text-wrap"
                  color="warning"
                  onClick={onSecondaryClick}
                >
                  {secondaryCTACaption || t('feedbackModal.actions.Cancel')}
                </IonButton>
              </IonCol>
            )}
            <IonCol size="6">
              <IonButton
                expand="block"
                fill="clear"
                size="default"
                className="feedbackModal__button--main ion-text-wrap"
                onClick={onMainClick}
              >
                {mainCTACaption || t('feedbackModal.actions.Continue')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </IonModal>
  );
}

export default FeedbackModal;
