import {
  notificationType, notificationBoxType, Notification, notificationTag,
} from '../../types/notifications';
import NotificationPrescriptionActions from './NotificationPrescriptionActions';
import NotificationAlertActionsActions from './NotificationAlertActions';
import NotificationRecommendationActions from './NotificationRecommendationActions';
import InAppNotification from '../Notifications/InAppNotification';
import StaticNotification from '../Notifications/StaticNotification';
import './NotificationBase.css';

type NotificationBaseProps = {
  inAppMode?: boolean;
  notification: Notification;
  allowActions: boolean;
  refresh?: () => void;
  dismiss?: () => void;
  switchTarget?: () => void;
}

function NotificationBase({
  inAppMode,
  notification,
  allowActions,
  refresh,
  dismiss,
  switchTarget,
}: NotificationBaseProps): JSX.Element {
  const renderActions = (item: Notification): JSX.Element | null => {
    let actions;

    const switchHandler = inAppMode && (allowActions || item.type === notificationType.RECOMMENDATION) && item.targetFarmIsNotCurrent
      ? switchTarget
      : undefined;

    if (item.type === notificationType.RECOMMENDATION) {
      actions = <NotificationRecommendationActions notification={item} dismiss={dismiss} switchTarget={switchHandler} />;
    } else if (allowActions && item.type === notificationType.ALERT) {
      actions = <NotificationAlertActionsActions notification={item} dismiss={dismiss} />;
    } else if (allowActions && ((inAppMode && item.tag === notificationTag.NewPrescriptionAvailable) || item.boxType === notificationBoxType.PENDING)) {
      actions = <NotificationPrescriptionActions notification={item} refresh={refresh} dismiss={dismiss} linkOnly switchTarget={switchHandler} />;
    }

    return actions
      ? (<div className="notification-item__actions">{actions}</div>)
      : null;
  };

  const actions = renderActions(notification);

  return inAppMode
    ? <InAppNotification notification={notification} dismiss={dismiss}>{actions}</InAppNotification>
    : <StaticNotification notification={notification}>{actions}</StaticNotification>;
}

export default NotificationBase;
