import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IonIcon, IonSkeletonText, useIonViewWillEnter } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons';
import cn from 'classnames';
import Box from '../../components/Box/Box';
import Button from '../../components/Button/Button';
import { useFarm } from '../../components/FarmContext/FarmContext';
import * as routes from '../../routing/routes';
import { MedicalPrescriptionRouteOptions } from '../MedicalPrescription/CreateMedicalPrescriptionPage';
import usePamApi from '../../hooks/usePamApi';
import useFriendlyErrors from '../../hooks/useFriendlyErrors';
import Page from '../../layout/Page/Page';
import Badge from '../../components/Badge/Badge';
import { useIdentity, UserType } from '../../components/Identity/IdentityContext';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import MedicalPrescription from '../MedicalPrescription/types/MedicalPrescription';
import useAnimalGroup from '../../hooks/useAnimalGroup';
import MedicalPrescriptionCard from './MedicalPrescriptionCard';
import { MedicalPrescriptionStatus, REMOVE_BLEND_ID } from '../../types/MedicalPrescription';
import NoRecommendations from './NoRecommnedations';
import './BotanicalFeed.css';

interface BotanicalFeedReasons {
  type: string;
  value: string;
}

interface BotanicalFeedBlend {
  date: Date;
  blendId: number;
  blendName?: string;
  reasons?: BotanicalFeedReasons[];
}

interface BotanicalFeedData {
  active?: BotanicalFeedBlend;
  proposed?: BotanicalFeedBlend;
}

export interface FarmPrescriptions {
  last?: MedicalPrescription;
  proposed?: MedicalPrescription;
}

function BotanicalFeed(): JSX.Element {
  const { t } = useTranslation();
  const { selectedFarmId } = useFarm();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [botanicalFeedData, setBotanicalFeedData] = useState<BotanicalFeedData>();
  const history = useHistory();
  const pamApi = usePamApi('BotanicalFeed');
  const friendlyErrors = useFriendlyErrors();
  const [ts, setTs] = useState(0);
  const [reasonTypes, setReasonTypes] = useState<string[]>([]);
  const identity = useIdentity();
  const isFarmer = identity.user?.type === UserType.Farmer;
  const [medicalPrescriptions, setMedicalPrescriptions] = useState<FarmPrescriptions>();
  const { defaultAnimalGroup } = useAnimalGroup();

  const forceRefresh = (): void => setTs(+new Date());

  useIonViewWillEnter(forceRefresh);

  useEffect(
    () => {
      (async (farmId: number): Promise<void> => {
        if (!farmId || loading) {
          return;
        }

        setBotanicalFeedData(undefined);
        setReasonTypes([]);
        setLoading(true);
        setNotFound(false);

        try {
          const response = await pamApi.get(`/farms/${farmId}/botanical-feed`);

          if (response.ok) {
            const data: BotanicalFeedData = await response.json();
            if (data.proposed?.blendId === REMOVE_BLEND_ID) {
              data.proposed.blendName = t('botanicalFeed.RemoveActualBlend');
            }
            if (data.active?.blendId === REMOVE_BLEND_ID) {
              data.active.blendName = t('botanicalFeed.RemoveActualBlend');
            }
            setBotanicalFeedData(data);
            const reasonKeys = data.active?.reasons?.map((r) => r.type) || [];
            data.proposed?.reasons?.forEach(({ type }) => (!reasonKeys.includes(type)) && reasonKeys.push(type));
            setReasonTypes(reasonKeys);
          } else if (response.status === 404) {
            setNotFound(true);
          } else {
            friendlyErrors.handleGenericFetchError();
          }
        } catch (error) {
          friendlyErrors.handleGenericFetchError();
        }

        setLoading(false);
      })(selectedFarmId);
    },
    [botanicalFeedData, selectedFarmId, ts],
  );

  useEffect(() => {
    (async (): Promise<void> => {
      setMedicalPrescriptions({});
      if (!selectedFarmId || !defaultAnimalGroup || !defaultAnimalGroup?.id) {
        return;
      }

      try {
        const response = await pamApi.get(`/farms/${selectedFarmId}/medical-prescriptions?batchId=${defaultAnimalGroup?.id}`);

        if (response.ok) {
          const prescriptionResponse = await response.json();
          setMedicalPrescriptions(prescriptionResponse);
        }
      } catch (error) {
        friendlyErrors.handleGenericFetchError();
      }
    })();
  }, [selectedFarmId, defaultAnimalGroup, ts]);

  const navigateToCreateMedicalPrescription = (): void => {
    if (isFarmer && !botanicalFeedData) {
      return;
    }

    const routeParams: MedicalPrescriptionRouteOptions = {
      blendId: botanicalFeedData?.proposed?.blendId ?? 0,
      blendName: botanicalFeedData?.proposed?.blendName ?? '',
      ts: +new Date(),
    };
    history.push(routes.CreateMedicalPrescription, routeParams);
  };

  const canCreatePrescription = !loading && !isFarmer && medicalPrescriptions?.proposed?.status !== MedicalPrescriptionStatus[MedicalPrescriptionStatus.Created];
  const showNoRecommendationsMessage = !loading && isFarmer && !medicalPrescriptions?.proposed && !medicalPrescriptions?.last;

  return (
    <Page title={t('botanicalFeed.Title')} refresher={forceRefresh}>
      {!isFarmer && (
        <div className="botanicalFeed_title">
          <div className="botanicalFeed_title-text">
            <h2>{t('botanicalFeed.Subtitle')}</h2>
          </div>
        </div>
      )}
      {notFound && <ErrorMessage>{t('botanicalFeed.NotFound')}</ErrorMessage>}
      {!notFound && !isFarmer && (
        <div className="botanicalFeed__container">
          <div className="botanicalFeed__section botanicalFeed__recommendations">
            <Box title={t('botanicalFeed.Current')}>
              {!loading && <span>{botanicalFeedData?.active?.blendName || '-'}</span>}
              {loading && <IonSkeletonText animated style={{ width: '100%' }} />}
            </Box>
            <IonIcon
              className="botanicalFeed__recommendation-arrow"
              icon={arrowForwardCircleOutline}
            />
            <Box
              title={t('botanicalFeed.Proposed')}
              className="botanicalFeed__recommendation--new"
            >
              {!loading && <span>{botanicalFeedData?.proposed?.blendName || '-'}</span>}
              {loading && <IonSkeletonText animated style={{ width: '100%' }} />}
            </Box>
          </div>
          {!loading && !!reasonTypes.length && (
            <div className="botanicalFeed__section botanicalFeed__row">
              <Box title={t('botanicalFeed.Reasons')} className="botanicalFeed__reasons">
                <table>
                  <thead>
                    <tr>
                      <th>{t('botanicalFeed.tableHeaders.Reason')}</th>
                      <th>{t('botanicalFeed.tableHeaders.Actual')}</th>
                      <th>{t('botanicalFeed.tableHeaders.Proposed')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reasonTypes.map((reason) => {
                      const actual = botanicalFeedData?.active?.reasons?.find((r) => r.type === reason)?.value;
                      const proposed = botanicalFeedData?.proposed?.reasons?.find((r) => r.type === reason)?.value;
                      return (
                        <tr key={`reasons-${reason}`}>
                          <th>{reason}</th>
                          <td>
                            {(actual && <Badge>{t(`botanicalFeed.values.${actual}`)}</Badge>) || '-'}
                          </td>
                          <td className={cn('botanicalFeed__proposed', { changed: actual !== proposed })}>
                            {(proposed && <Badge>{t(`botanicalFeed.values.${proposed}`)}</Badge>) || '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </div>
          )}
        </div>
      )}

      {loading && (
        <div className="botanicalFeed__section botanicalFeed__row">
          <Box title={t('botanicalFeed.Reasons')} className="botanicalFeed__reasons">
            {loading && <IonSkeletonText animated style={{ width: '100%' }} />}
          </Box>
        </div>
      )}

      {(canCreatePrescription || showNoRecommendationsMessage || medicalPrescriptions?.proposed || medicalPrescriptions?.last) && (
        <h2>{t('botanicalFeed.MedicalPrescriptionSubtitle')}</h2>
      )}
      {canCreatePrescription && (
        <div className="botanicalFeed__actions">
          <Button block onClick={navigateToCreateMedicalPrescription}>
            {t('botanicalFeed.CreateMedicalPrescription')}
          </Button>
        </div>
      )}
      {medicalPrescriptions?.proposed && <MedicalPrescriptionCard prescription={medicalPrescriptions.proposed} />}
      {medicalPrescriptions?.last && <MedicalPrescriptionCard prescription={medicalPrescriptions.last} />}

      {showNoRecommendationsMessage && <NoRecommendations />}
    </Page>
  );
}

export default BotanicalFeed;
