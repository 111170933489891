const QUALITY_UNIT_PERCENTAGE = '%';
const QUALITY_UNIT_EMPTY = '';
export const QUALITY_MIN_DIGITS = 0;
const QUALITY_MAX_DIGITS = 2;

export const milkQualityKPIs = ['eq', 'urea', 'fat', 'protein', 'bacteria', 'somaticCellCount'] as const;

export type MilkQualityKPI = typeof milkQualityKPIs[number];

export type MilkQuality = {
  [key in MilkQualityKPI]: number;
};

export type MilkQualityCounterData = {
  min: number;
  max: number;
  label: string;
  truncate?: boolean;
  digits?: number;
  unit?: string;
};

export type MilkQualityCounters = Record<MilkQualityKPI, MilkQualityCounterData>;

export const defaultKPI = {
  min: 0,
  max: 0,
  truncate: true,
  unit: QUALITY_UNIT_EMPTY,
  digits: QUALITY_MIN_DIGITS,
};

type MilkQualityKPIConfiguration = Record<MilkQualityKPI, Partial<MilkQualityCounterData>>;

export const kpiConfiguration: MilkQualityKPIConfiguration = {
  eq: {
    digits: QUALITY_MAX_DIGITS,
    unit: QUALITY_UNIT_PERCENTAGE,
    truncate: false,
  },
  fat: {
    digits: QUALITY_MAX_DIGITS,
    unit: QUALITY_UNIT_PERCENTAGE,
    truncate: false,
  },
  protein: {
    digits: QUALITY_MAX_DIGITS,
    unit: QUALITY_UNIT_PERCENTAGE,
    truncate: false,
  },
  urea: {
    digits: QUALITY_MAX_DIGITS,
    truncate: false,
    unit: 'mg/L',
  },
  bacteria: {
    digits: QUALITY_MIN_DIGITS,
    unit: 'ufc/ml*1000',
  },
  somaticCellCount: {
    digits: QUALITY_MIN_DIGITS,
    unit: 'cél/ml*1000',
  },
};
