import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { Control, Controller } from 'react-hook-form';
import './FieldSelector.css';

type FieldProps = {
  name: string,
  control?: Control,
  options: FieldSelectorOption[],
  label?: string,
  placeholder: string;
  defaultOption?: string | number;
  disabled?: boolean;
  onChangeCustom?: (value: string) => void;
  defaultValue?: string | number;
}

export type FieldSelectorOption = {
  id: string | number,
  name: string;
}

function FieldSelector({
  name, control, options, label, placeholder, defaultOption, disabled, onChangeCustom, defaultValue = '',
}: FieldProps): JSX.Element {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value: currentValue }, fieldState: { error, isDirty } }) => (
        <IonItem className="pam-input fieldSelector">
          {label && <IonLabel color="primary" position="stacked">{label}</IonLabel>}

          <IonSelect
            disabled={disabled}
            interface="action-sheet"
            placeholder={placeholder}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(options?.length ? { value: isDirty ? currentValue : (defaultOption || defaultValue) } : { value: defaultValue })}
            onIonChange={(event) => {
              if (onChangeCustom) {
                onChangeCustom(event.detail.value);
              }
              onChange(event.detail.value);
            }}
          >
            {options?.map((option) => (
              <IonSelectOption
                key={option.id}
                value={option.id}
              >
                {option.name}
              </IonSelectOption>
            ))}
          </IonSelect>
          {error && <IonText color="danger" className="text-s">{error?.message}</IonText>}
        </IonItem>
      )}
      control={control}
    />
  );
}

export default FieldSelector;
