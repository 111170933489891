import {
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow, IonSkeletonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { FarmTarget } from '../../../../components/FarmTargetCard/FarmTargetCard';
import './ProductionTargetCard.css';

export interface ProductionTargetCardProps {
  target?: FarmTarget,
}

function ProductionTargetCard({ target }:ProductionTargetCardProps):JSX.Element {
  const { t } = useTranslation();
  return (
    <IonCard className="productionTargetCard">
      <IonCardHeader>
        <IonCardTitle>
          {target?.paramName}
          {!target && <IonSkeletonText animated />}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol size="5" className="text-m color-neutral-50"><strong>{t('productionTargetsWidget.labels.Value')}</strong></IonCol>
            <IonCol size="7" className="text-m color-neutral-50"><strong>{t('productionTargetsWidget.labels.Score')}</strong></IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="data-s color-secondary-dark">
              {target?.value}
              {!target && <IonSkeletonText animated />}
            </IonCol>
            <IonCol size="7" className="data-s color-secondary-dark">
              {target?.range}
              {!target && <IonSkeletonText animated />}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
}

export default ProductionTargetCard;
