import { ReactNode } from 'react';
import cn from 'classnames';
import { IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { Color } from '@ionic/core';
import './InfoBox.css';

type InfoBoxProps = {
  icon?: string;
  color?: Color & string;
  className?: string | undefined;
  children: ReactNode;
}

function InfoBox({
  icon, color = 'info', className, children,
}: InfoBoxProps): JSX.Element {
  return (
    <IonCard color={color} className={cn('infoBox', className)}>
      <IonCardContent>
        {icon && (
          <div className="infoBoxIcon">
            <IonIcon icon={icon} size="normal" />
          </div>
        )}
        <div className="infoBoxContent">
          {children}
        </div>
      </IonCardContent>
    </IonCard>
  );
}

export default InfoBox;
