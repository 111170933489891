import { useEffect, useState } from 'react';
import { useFarm } from '../components/FarmContext/FarmContext';
import AnimalGroup from '../pages/MedicalPrescription/types/AnimalGroup';
import usePamApi from './usePamApi';

interface UseDefaultAnimalGroupResponse {
  defaultAnimalGroup: AnimalGroup|null
  animalGroupName: (id: number) => string
}

function useAnimalGroup(): UseDefaultAnimalGroupResponse {
  const { selectedFarmId } = useFarm();
  const [animalGroup, setAnimalGroup] = useState<AnimalGroup|null>(null);
  const [animalGroups, setAnimalGroups] = useState<AnimalGroup[]>([]);
  const pamApi = usePamApi('useDefaultBatch');

  useEffect(() => {
    const loadGroups = async (): Promise<void> => {
      setAnimalGroups([]);
      setAnimalGroup(null);
      if (!selectedFarmId) {
        return;
      }
      const response = await pamApi.get(`/farms/${selectedFarmId}/animal-group`);
      if (response.ok) {
        const data = await response.json();
        const groups: AnimalGroup[] = data.animalGroups ?? [];
        setAnimalGroup(groups[0] || null);
        setAnimalGroups(groups);
      }
    };
    loadGroups();
  }, [selectedFarmId]);

  return {
    defaultAnimalGroup: animalGroup,
    animalGroupName: (groupId: number):string => animalGroups.find((group) => group.id === groupId)?.groupName || '',
  };
}

export default useAnimalGroup;
